import React, { useEffect } from 'react'
import Layout from './common/Layout'

const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <Layout>
      <div className='policy-outer'>
        <div className='container'>
          <div className='policy-main'>
            <div class='card-body-heading'>
              <h1 className='text-dark mb-3'>Privacy & Policy</h1>
            </div>
            {/* <div> */}
            <div class='card-body'>
              <p class='' style={{ textAlign: 'justify' }}>
                <span style={{ color: 'green' }}>Insurefast.in</span> including
                online platform(s), its mobile application/web applications
                owned by the Company ('Website') recognizes the importance of
                maintaining your privacy. We are committed to maintain the
                confidentiality, integrity and security of all information of
                our users. This Privacy Policy describes how Insurefast.in
                collects and handles certain information it may collect and/or
                receive from you via the use of this Website. Please see below
                for details on what type of information we may collect from you,
                how that information is used in connection with the services
                offered through our Website and relevant information shared with
                our authorized business partners only for the purpose of policy
                solicitation process, pre/post-sale servicing, claim servicing
                and providing you any other services as permissible under
                applicable laws. This Privacy Policy applies to current and
                former visitors to our Website and our customers. By visiting
                and/or using our Website, you agree to this Privacy Policy.
              </p>
              <p class='' style={{ textAlign: 'justify' }}>
                This Privacy Policy is published in compliance of: the
                Information Technology Act, 2000; and the Information Technology
                (Reasonable Security Practices and Procedures and Sensitive
                Personal Information) Rules, 2011 (the "SPDI Rules") as amended
                from time to time.
              </p>
              <p class='' style={{ textAlign: 'justify' }}>
                By using the{' '}
                <span style={{ color: 'green' }}>insurefast.in</span> and/or
                registering yourself at{' '}
                <span style={{ color: 'green' }}>www.Insurefast.in</span>, you
                authorize Insurefast Insurance Web Aggregator Private Limited
                (including its authorized representatives and its business
                partners) (Insurefast/Company) to contact you via email or phone
                call or sms or WhatsApp and offer you our services for the
                product you have opted for, imparting product knowledge, offer
                promotional offers running on the Website and offers by its
                authorized business partners and associated third parties (to
                the extent permissible), for which reasons your information may
                be collected in the manner as detailed under this Policy. You
                hereby agree that you authorize{' '}
                <span style={{ color: 'green' }}>Insurefast.in</span> to contact
                you on your mobile number for the above mentioned purposes even
                if you have registered yourself under DND or DNC or NCPR
                service(s). You also authorize{' '}
                <span style={{ color: 'green' }}>Insurefast.in</span> to contact
                you on the WhatsApp number. Your authorization, in this regard,
                shall be valid as long as your account is not deactivated by
                either you or us.
              </p>
              <p class='' style={{ textAlign: 'justify' }}>
                Controllers of Personal Information
              </p>
              <p class='' style={{ textAlign: 'justify' }}>
                Your personal data will be stored and collected by Insurefast.
              </p>
              <p class='' style={{ textAlign: 'justify', fontWeight: 'bold' }}>
                Purposes of collection of your data:
              </p>
              <p class='' style={{ textAlign: 'justify' }}>
                <span style={{ color: 'green' }}>Insurefast.in</span> collects
                your information when you register for an account, when you use
                its products or services, visit its Website's pages or when an
                employer organization shares its employee information. When you
                register with{' '}
                <span style={{ color: 'green' }}>Insurefast.in</span>, you are
                asked for submitting certain information which may be personal
                to you such as first name, last name, state and city of
                residence, email address, mobile number, date of birth,
                financial, know your customer and medical records etc. Once you
                register at the Website and sign in you are not anonymous to us.
                Also, you are asked for your contact number during registration
                and may be sent SMSs, notifications about our services to your
                wireless device. Hence, by registering you authorize the{' '}
                <span style={{ color: 'green' }}>Insurefast.in</span> to send
                texts and email alerts to you with your login details and any
                other service requirements, including promotional mails and
                SMSs.
              </p>
              <p class='' style={{ textAlign: 'justify' }}>
                We use your information in order to: Respond to queries or
                requests submitted by you.
              </p>

              <p class='' style={{ textAlign: 'justify' }}>
                A privacy policy is a document that explains how an organization
                handles any customer, client or employee information gathered in
                its operations. Most websites make their privacy policies
                available to site visitors. A privacy page should specify any
                Personally identifiable information that is gathered, such as
                name, address and credit card number, as well as other things
                like order history, browsing habits, uploads and downloads. The
                policy should also explain if data may be left on a user’s
                computer, such as cookies. According to , the policy should
                disclose if data may be shared with or sold to third parties and
                if so, what the purpose is.
              </p>
              <p class='' style={{ textAlign: 'justify' }}>
                Process orders or applications submitted by you.
              </p>
              <p class='' style={{ textAlign: 'justify' }}>
                Administer or otherwise carry out our obligations in relation to
                any agreement with our authorized business partners in
                connection with our business operations and functions for the
                purpose of insurance solicitation, providing pre/post sale
                services, claims servicing and providing you any other services
                as permissible under applicable laws or any other insurance
                related purposes.
              </p>
              <p className='' style={{ textAlign: 'justify' }}>
                Anticipate and resolve problems with any services supplied to
                you. Offer group insurance cover and wellness corner offerings /
                health and wellness related information to your employer
                organization.
              </p>

              <p className='' style={{ textAlign: 'justify' }}>
                To send you information about promotions or offers from
                Insurance Companies to the extent permissible under applicable
                laws. We might also tell you about new features or products.
              </p>
              <p className=''>
                To make our website and the services offered better. We may
                combine information we get from you with information about you
                we get from our business partners or third parties as
                permissible under applicable laws.
              </p>
              <p className='' style={{ textAlign: 'justify' }}>
                To send you notices, communications, offer alerts relevant to
                your use of the Services offered on this Website. Third parties
                and outsourced entities for the reasons consistent with the
                purposes for which the information was collected and/or other
                purposes as per applicable law.
              </p>
              <p className='' style={{ textAlign: 'justify' }}>
                Where we need to comply with a legal obligation as per
                applicable legal and regulatory framework.
              </p>
              <p className='' style={{ textAlign: 'justify' }}>
                As otherwise provided in this Privacy Policy. Some features of
                this Website or our Services will require you to furnish your
                personally identifiable information as provided by you under
                your account section on our Website.
              </p>
              <p className='' style={{ textAlign: 'justify' }}>
                Information Sharing and Disclosure We may share your Information
                submitted on the Website to any third party/service
                provider/business partner without obtaining your prior consent
                in the following limited circumstances:
              </p>
              <p className='' style={{ textAlign: 'justify' }}>
                1. When it is requested or required by law or by any court or
                governmental agency or authority to disclose, for the purpose of
                verification of identity, or for the prevention, detection,
                investigation including cyber incidents, or for prosecution and
                punishment of offences. These disclosures are made in good faith
                and belief that such disclosure is reasonably necessary for
                enforcing these Terms and Conditions; for complying with the
                applicable laws and regulations.
              </p>
              <p className='' style={{ textAlign: 'justify' }}>
                2. Insurefast shall transfer information about you in case
                Insurefast is acquired by or merged with another company.
              </p>
              <p className='' style={{ textAlign: 'justify' }}>
                3. Where we need to comply with a legal obligation as per
                applicable legal and regulatory framework.
              </p>
              <p className='' style={{ textAlign: 'justify' }}>
                We Collect Cookies
                <br />A cookie is a piece of data stored on the user's computer
                tied to information about the user. We may use both session ID
                cookies and persistent cookies. For session ID cookies, once you
                close your browser or log out, the cookie terminates and is
                erased. A persistent cookie is a small text file stored on your
                computer’s hard drive for an extended period of time. Session ID
                cookies may be used by PRP to track user preferences while the
                user is visiting the website. They also help to minimize load
                times and save on server processing. Persistent cookies may be
                used by PRP to store whether, for example, you want your
                password remembered or not, and other information. Cookies used
                on the PRP website do not contain personally identifiable
                information.
              </p>
              <p className='' style={{ textAlign: 'justify' }}>
                Log Files
                <br />
                Like most standard websites, we use log files. This information
                may include internet protocol (IP) addresses, browser type,
                internet service provider (ISP), referring/exit pages, platform
                type, date/time stamp, and number of clicks to analyze trends,
                administer the site, track user's movement in the aggregate, and
                gather broad demographic information for aggregate use. We may
                combine this automatically collected log information with other
                information we collect about you. We do this to improve services
                we offer to you, to improve marketing, analytics or site
                functionality.
              </p>
              <p className='' style={{ textAlign: 'justify' }}>
                Email- Opt out
                <br />
                If you are no longer interested in receiving e-mail
                announcements and other marketing information from us, please
                e-mail your request at:{' '}
                <a
                  href='mailto:support@insurefast.in'
                  style={{ color: 'green' }}
                >
                  support@Insurefast.in
                </a>{' '}
                Please note that it may take about 10 days to process your
                request.
              </p>
              <p className='' style={{ textAlign: 'justify' }}>
                Security
                <br />
                We employ appropriate technical and organizational security
                measures at all times to protect the information we collect from
                you. We use multiple electronic, procedural, and physical
                security measures to protect against unauthorized or unlawful
                use or alteration of information, and against any accidental
                loss, destruction, or damage to information. However, no method
                of transmission over the Internet, or method of electronic
                storage, is 100% secure. Therefore, we cannot guarantee its
                absolute security. Further, you are responsible for maintaining
                the confidentiality and security of your login id and password,
                and may not provide these credentials to any third party.
              </p>
              <p className='' style={{ textAlign: 'justify' }}>
                <span style={{ color: 'green' }}>Insurefast.in</span>{' '}
                understands that the confidentiality, integrity, and
                availability of your information are vital to our business
                operations and our own success.
              </p>
              <p className='' style={{ textAlign: 'justify' }}>
                Links to Other Websites There might be other sites linked to
                Insurefast.in. Personal information that you provide to those
                sites are not our property. These affiliated sites may have
                different privacy practices and we encourage you to read their
                privacy policies of these website when you visit them.
              </p>
              <p className='' style={{ textAlign: 'justify' }}>
                Changes in this Privacy Policy
                <span style={{ color: 'green' }}>Insurefast.in</span> reserves
                the right to change this policy from time to time, at its sole
                discretion. We may update this privacy policy to reflect changes
                to our information practices. We encourage you to periodically
                review it.
              </p>
              <p className='' style={{ textAlign: 'justify' }}>
                If you have questions, concerns, or suggestions regarding our
                Privacy Policy, we can be reached using the contact information
                on our Contact Us page or at{' '}
                <a
                  href='mailto:support@insurefast.in'
                  style={{ color: 'green' }}
                >
                  support@Insurefast.in
                </a>
              </p>
              <p className='' style={{ textAlign: 'justify' }}>
                In case your grievance is in relation to Information Technology
                (Intermediary Guidelines and Digital Media Ethics Code) Rules,
                2021, we shall acknowledge your complaint within twenty-four
                hours and dispose off such the complaint within a period of
                fifteen days from the date of its receipt. Disposal of complaint
                shall include all actions as considered necessary by Insurefast.
              </p>
            </div>
            {/* </div> */}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default PrivacyPolicy
