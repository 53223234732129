import React, { useEffect, useState } from "react";
import { Modal, ModalBody } from "reactstrap";
import "./Quotelist.css";
import { Link, useNavigate } from "react-router-dom";
import { PostData, PostDataWithToken } from "../../api/apiHelper";
import { dispatchUserDetails } from "../../store/action/userActions";
import OtpInput from "react-otp-input";
import { useSelector } from "react-redux";
import { size } from "lodash";
import { useForm } from "react-hook-form";
import { sendSaveUserMessage } from "../services/masterServices";
import { toastFailure } from "../utility/constants";
import DatePicker from "react-datepicker";
import ReactInputMask from "react-input-mask";
import moment from "moment";
function SignUpModal({ show, setModel }) {
  const toggle = () => setModel(!show);
  const [select, setSelect] = useState("");
  const [bonus, setBonus] = useState(0);
  const [mobile, setMobile] = useState();
  const [loginOtpModal, setLoginOtpModal] = useState(false);
  const [customerExist, setCustomerExist] = useState(false);
  const [otp, setOtp] = useState();
  const [dob, setDob] = useState(null);
  const userDetailsObj = useSelector((state) => state?.root?.userDetails);

  const navigate = useNavigate();

  // ----------------------------------\\

  const [avatar, setAvatar] = useState();
  const userDetails = useSelector((state) => state?.root?.userDetails);
  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
    reset,
    watch,
    clearErrors,
  } = useForm({ mode: "onBlur" });

  // useEffect(() => {
  //   setValue("phone", userDetails?.Phone);
  //   setValue("email", userDetails?.Email);
  //   setValue("name", userDetails?.FullName);
  //   setValue("gender", userDetails?.Gender);
  // }, []);

  const updateProfile = async (data) => {
    const dateOfBirth = new Date(data?.Dob).toLocaleDateString("en-GB");
    const min = 1000000000000;
    const max = 9999999999999;
    const UniqueId = Math.floor(Math.random() * (max - min + 1) + min);

    let postData = {
      name: data?.fullName,
      email: data?.email,
      mobileNumber: data?.mobileNumber,
      gender: data?.gender,
      dob: dateOfBirth,
      maritalStatus: data?.MaritalStatus,
      city: data?.city,
      customerUniqueId: UniqueId,
    };

    dispatchUserDetails("FullName", data?.name);
    dispatchUserDetails("Gender", data?.gender);
    dispatchUserDetails("Email", data?.email);
    dispatchUserDetails("Phone", data?.phone);
    try {
      const response = await PostDataWithToken(
        "auth/sign_up_customer",
        postData
      );

      if (response?.success === true) {
        dispatchUserDetails("FullName", response?.data?.customer_name);
        dispatchUserDetails("Gender", response?.data?.customer_gender);
        dispatchUserDetails("Email", response?.data?.customer_email);
        dispatchUserDetails("Phone", response?.data?.customer_phone);
        dispatchUserDetails("DateofBirth", response?.data?.dateOfBirth);
        dispatchUserDetails("MaritalStatus", response?.data?.marital_status);
        dispatchUserDetails("City", response?.data?.city);
        dispatchUserDetails(
          "CustomerUniqueNumber",
          response?.data?.customer_unique_number
        );
        navigate("/my-profile");
        sendSaveUserMessage("Your profile has been created successfully");
      } else if (
        response?.data?.success === false &&
        response?.data?.message == "Customer Already Exist"
      ) {
        setCustomerExist(true);
      } else {
        toastFailure(response?.data?.message);
      }
    } catch (err) {
      console.log(err);
    }
  };
  console.log(customerExist);

  const handleInputChange = (event) => {
    const inputValue = event.target.value;

    // Validate the input format (DD/MM/YYYY)
    if (/^\d{2}\/\d{2}\/\d{4}$/.test(inputValue)) {
      const [day, month, year] = inputValue.split("/");
      const date = new Date(`${year}-${month}-${day}`);

      if (!isNaN(date.getTime())) {
        const formattedDate = moment(date).format("YYYY-MM-DD");
        setDobState(date);
        clearErrors("Dob");
        setValue("Dob", formattedDate);
      }
    } else {
      setDobState(null);
      clearErrors("Dob");
      setValue("Dob", "");
    }
  };

  // Function to handle date selection from DatePicker
  const handleDateChange = (date) => {
    if (date) {
      const formattedDate = moment(date).format("YYYY-MM-DD");
      // setDobState(date);
      clearErrors("Dob");
      setValue("Dob", formattedDate);
      setDob(formattedDate); // Triggering setDob from props
    } else {
      // setDobState(null);
      clearErrors("Dob");
      setValue("Dob", "");
      setDob(""); // Clear Dob when no date is selected
    }
  };

  return (
    <>
      <Modal
        isOpen={show}
        toggle={toggle}
        centered
        size="lg"
        backdrop="static"
        keyboard={false}
      >
        <ModalBody>
          {/* <div class="p-3">
            <div class="gsc_modalWrapper">
              {loginOtpModal === false ? (
                <>
                  <div className="modal-logo">
                    <a >
                      <img src="/assets/images/insurefastlogos.svg"></img>
                    </a>
                  </div>
                  <div className="modal_heading w-100 mb-4">
                    <h4 className="text-center">Login with Mobile Number</h4>
                    <div onClick={toggle} class="v2_close false">
                      <a >
                        <img
                          src="./assets/images/delete_icon.svg"
                          title="Close"
                          alt="close"
                        />
                      </a>
                    </div>
                  </div>
                  <div className="did-floating-label-content">
                    <input
                      className="did-floating-input"
                      placeholder=" "
                      onChange={(e) => setMobile(e?.target?.value)}
                    />
                    <label className="did-floating-label">Mobile Number</label>
                  </div>
                  <div className="did-floating-label-content">
                    <input
                      className="did-floating-input"
                      placeholder=" "
                      onChange={(e) => setMobile(e?.target?.value)}
                    />
                    <label className="did-floating-label">Enter Password</label>
                  </div>
                  <div class="v2_btngrp mb-3">
                    <div class="wrapper">
                      <button
                        onClick={handleLogin}
                        name="submitBtn"
                        class="button submitButton gsc_col-xs-12 gsc_col-md-12"
                      >
                        Send OTP
                      </button>
                    </div>
                  </div>
                  <div className="d-flex justify-content-center align-items-center">
                    <span style={{ fontSize: "18px" }}>
                      First time user?{" "}
                      <Link
                        to="/sign-up"
                        style={{
                          color: "green",
                          textDecoration: "none",
                          fontSize: "18px",
                          fontWeight: 600,
                        }}
                      >
                        Sign up
                      </Link>
                    </span>
                  </div>
                </>
              ) : loginOtpModal === true ? (
                <>
                  <div className="modal-logo">
                    <a >
                      <img src="/assets/images/insurefastlogos.svg"></img>
                    </a>
                  </div>
                  <div className="modal_heading w-100 mb-4">
                    <h4 className="text-center">Enter Otp</h4>
                    <div onClick={toggle} class="v2_close false">
                      <a >
                        <img
                          src="./assets/images/delete_icon.svg"
                          title="Close"
                          alt="close"
                        />
                      </a>
                    </div>
                  </div>
                  <div className="mb-3">
                    <h6 class='table bold'>Please enter OTP</h6>
                    <div className="d-flex w-100 justify-content-center align-items-center">
                      <OtpInput
                        value={otp}
                        inputStyle={{
                          borderRadius: "5px",
                          // width: '25%',
                          height: "50px",
                          width: "50px",
                          border: "1px solid black",
                          boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px;",
                          gap: "10px",
                        }}
                        containerStyle={
                          {
                            width: '70%'
                          }
                        }
                        onChange={setOtp}
                        numInputs={4}
                        renderSeparator={<span>-</span>}
                        renderInput={(props) => <input {...props} />}
                      />
                    </div>
                  </div>
                  <div class="v2_btngrp mb-3">
                    <div class="wrapper">
                      <p class="central-login-Receive-OTP">
                        Didn't receive the OTP yet?
                        <a  className="resend">
                          Resend
                        </a>
                      </p>
                      <button
                        onClick={verifyLoginOtp}
                        name="submitBtn"
                        class="button submitButton gsc_col-xs-12 gsc_col-md-12"
                      >
                        Verify OTP
                      </button>
                    </div>
                  </div>
                  <div className="d-flex justify-content-center align-items-center">
                    <span>
                      First time user?{" "}
                      <Link
                        to="/sign-up"
                        style={{ color: "green", textDecoration: "none" }}
                      >
                        Sign up
                      </Link>
                    </span>
                  </div>
                </>
              ) : (
                ""
              )}
            </div>
          </div> */}
          <form
            style={{ marginTop: "-7rem" }}
            className=""
            onSubmit={handleSubmit(updateProfile)}
          >
            <div className="modal_heading w-100 mb-4">
              {/* <h4 className="text-center">Login with Mobile Number</h4> */}
              <div onClick={toggle} class="v2_close false">
                <a>
                  <img
                    src="./assets/images/delete_icon.svg"
                    title="Close"
                    alt="close"
                  />
                </a>
              </div>
            </div>
            <div className="profile_main">
              <div className="profile_avatar">
                <div className="avatar_img">
                  <img
                    src={
                      watch("gender") === "Male"
                        ? "/assets/images/male icon (1).png"
                        : "/assets/images/female icon .png"
                    }
                  />
                </div>
              </div>
              <div className="profile_gender">
                <div className="d-flex yesnoinput">
                  <div>
                    <input
                      type="radio"
                      className="d-none"
                      id="Male"
                      name="gender"
                      value={"Male"}
                      {...register("gender", {
                        required: "Field is required",
                      })}
                    />
                    <label htmlFor="Male">
                      <span className="user_img">
                        <img src="/assets/images/male-user.svg"></img>
                      </span>
                      Male
                    </label>
                  </div>
                  <div>
                    <input
                      type="radio"
                      className="d-none"
                      id="Female"
                      name="gender"
                      value={"Female"}
                      {...register("gender", {
                        required: "Field is required",
                      })}
                    />
                    <label htmlFor="Female">
                      <span className="user_img">
                        <img src="/assets/images/male-user.svg"></img>
                      </span>
                      Female
                    </label>
                  </div>
                </div>
              </div>
              <div className="profile_details">
                <div className="mt-3">
                  <div className="mb-4">
                    <div className="did-floating-label-content">
                      <input
                        type="text"
                        className="did-floating-input"
                        {...register("fullName", {
                          required: "Field is Required",
                          minLength: {
                            value: 3,
                            message: "Please enter atleast 3 letters",
                          },
                        })}
                        // id="name"
                        placeholder="Enter Full Name"
                        // name="name"
                        onInput={(e) => {
                          e.target.value = e.target.value.replace(/[0-9]/g, "");
                        }}
                        maxLength={100}
                      />
                      <label htmlFor="name" className="did-floating-label">
                        Full Name
                      </label>
                      {errors && <span>{errors?.fullName?.message}</span>}
                    </div>
                  </div>
                  <div className="mb-4">
                    <div className="did-floating-label-content">
                      <input
                        type="number"
                        {...register("mobileNumber", {
                          pattern: "^[6-9]d{9}$",
                        })}
                        className="did-floating-input"
                        // id="phone"
                        // name="phone"
                        placeholder="Enter Mobile Number"
                        onInput={(e) =>
                          (e.target.value = e.target.value.slice(0, 10))
                        }
                        onChange={(e) => {
                          let value = e.target.value.trim();
                          if (
                            value.startsWith("0") ||
                            value.startsWith("1") ||
                            value.startsWith("2") ||
                            value.startsWith("3") ||
                            value.startsWith("4") ||
                            value.startsWith("5")
                          ) {
                            value = value.substring("1");
                          }
                          e.target.value = value;
                        }}
                      />
                      <label htmlFor="phone" className="did-floating-label">
                        Mobile No.
                      </label>
                    </div>
                  </div>
                  <div className="mb-4">
                    <div className="did-floating-label-content">
                      <input
                        type="email"
                        className="did-floating-input"
                        {...register("email", {
                          required: "Field is required",
                        })}
                        // id="email"
                        placeholder="Enter Email"
                        // name="email"
                      />
                      <label htmlFor="email" className="did-floating-label">
                        Email
                      </label>
                    </div>
                  </div>
                  <div className="mb-4">
                    <div className="did-floating-label-content">
                      <DatePicker
                        {...register("Dob", {
                          required: "Date of Birth is required",
                        })}
                        selected={dob}
                        onChange={handleDateChange}
                        customInput={
                          <ReactInputMask
                            mask="99/99/9999"
                            placeholder="DD/MM/YYYY"
                            value={dob ? moment(dob).format("DD/MM/YYYY") : ""}
                            onChange={handleInputChange}
                            alwaysShowMask={false} // Avoid forcing the mask
                          />
                        }
                        className="did-floating-input"
                        placeholderText="Select Date of Birth"
                        dateFormat="dd/MM/yyyy"
                      />
                      <label htmlFor="dob" className="did-floating-label">
                        Date of Birth
                      </label>
                      {errors?.Dob?.message && (
                        <p className="f-error text-danger">
                          {errors?.Dob?.message}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="mb-4">
                    <div className="did-floating-label-content">
                      <select
                        className="did-floating-input text-truncate"
                        {...register("MaritalStatus")}
                        placeholder=" "
                        // name="email"
                      >
                        <option className="did-floating-label">
                          Select Marital Status
                        </option>
                        <option className="did-floating-label" value="Single">
                          Single
                        </option>
                        <option className="did-floating-label" value="Married">
                          Married
                        </option>
                      </select>
                      <label htmlFor="email" className="did-floating-label">
                        Marital Status
                      </label>
                    </div>
                  </div>
                  <div className="mb-4">
                    <div className="did-floating-label-content">
                      <input
                        type="text"
                        className="did-floating-input"
                        {...register("city", {
                          required: "Field is required",
                        })}
                        // id="email"
                        placeholder="Enter City"
                        onInput={(e) => {
                          e.target.value = e.target.value.replace(/[0-9]/g, "");
                        }}
                        maxLength={20}
                        // name="email"
                      />
                      <label htmlFor="email" className="did-floating-label">
                        City
                      </label>
                    </div>
                  </div>
                </div>
                {customerExist && (
                  <p style={{ marginTop: "-1rem" }} className="text-danger">
                    Customer Already Exist
                  </p>
                )}
              </div>
              <div className="profile_btn_div">
                <button className="profile_btn" type="submit">
                  Sign Up
                </button>
              </div>
            </div>
          </form>
        </ModalBody>
      </Modal>
      {/* {loginOtpModal && <LoginOtpModel show={loginOtpModal} setModel={setLoginOtpModal} />} */}
    </>
  );
}

export default SignUpModal;
