import React from "react";
import { Link } from "react-router-dom";

const WhyUs = () => {
  return (
    <div className="why-us">
      <div className="container">
        <div className="policy_block">
          <div className="whyus-left">
            <img className="whyus-left-img" src="/assets/images/meeting.png" />
            <Link
              to="https://www.youtube.com/channel/UCjr88kAOD029MvRx_-P7rSA"
              target="_blank"
            >
              <img
                className="youtubeIcon"
                src="/assets/images/youtube_icon.png"
              />
            </Link>
          </div>

          <div className="whyus-right">
            <h4>Why Us</h4>
            <img className="" src="/assets/images/underlline.png" />
            <h2 className="">Save Upto 80% at InsureFast</h2>
            <p className="whyusDesc">
              InsureFast Insurance Web Aggregator stands out due to its
              exceptional array of benefits. Offering a seamless and
              user-friendly interface, it simplifies the complex task of
              comparing and selecting insurance plans. Its extensive network
              provides access to numerous reputable insurers, ensuring a
              comprehensive choice for users. The platform's efficiency lies in
              its swift comparison tools, allowing users to evaluate policies in
              real-time, saving both time and effort. InsureFast's commitment to
              transparency is evident through its clear presentation of policy
              details, aiding users in making informed decisions.
            </p>
          </div>
        </div>
      </div>{" "}
    </div>
  );
};

export default WhyUs;
