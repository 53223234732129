import React from "react";
import OurPartner from "./common/OurPartner";
import Support from "./common/Support";
import WhyUs from "./common/WhyUs";

import FastestInsurance from "./common/FastestInsurance";

function AboutHealth() {
  return (
    <>
      <FastestInsurance />
      <WhyUs />
      <div className="about-health-insurance">
        <div className="about-heading">
          <h1>
            Know More about <b> Health Insurance</b>
          </h1>
          <div className="about-text">
            <img src="/assets/images/about-icon.png" alt="" />{" "}
            <p>
              <span className="fw-bold justify_para">Definition:</span> Health insurance is a
              financial arrangement that provides coverage for medical expenses
              incurred by an individual. It serves as a safeguard against high
              healthcare costs, ensuring access to necessary medical services
              without bearing the full financial burden.
            </p>
          </div>
          <div className="about-text">
            <img src="/assets/images/about-icon.png" alt="" />{" "}
            <p className="justify_para">
              <span className="fw-bold">Coverage:</span> Health insurance
              typically covers a range of healthcare services, including
              hospital stays, surgeries, prescription medications, preventive
              care, and more. The extent of coverage varies based on the
              specific insurance plan and premium paid.
            </p>
          </div>
          <div className="about-text">
            <img src="/assets/images/about-icon.png" alt="" />{" "}
            <p className="justify_para">
              <span className="fw-bold">Importance:</span> Health insurance is
              crucial for individuals to mitigate the financial risks associated
              with medical emergencies. It promotes timely access to healthcare,
              encourages preventive measures, and safeguards against unexpected
              healthcare expenses that could lead to financial strain.
            </p>
          </div>
          <div className="about-text">
            <img src="/assets/images/about-icon.png" alt="" />{" "}
            <p className="justify_para">
              <span className="fw-bold">How it Works:</span> Individuals pay
              regular premiums to the insurance provider, and in return, the
              insurer covers or reimburses eligible medical expenses. This
              system helps distribute the financial risk among a larger pool of
              individuals, making healthcare costs more manageable for everyone
              involved.
            </p>
          </div>
        </div>
        <div className="about-health-img">
          <img src="/assets/images/about-img-1.png" alt="" />
        </div>
      </div>
      <div className="about-health-insurance">
        <div className="about-health-img">
          <img src="/assets/images/about-img-2.png" alt="" />
        </div>
        <div className="about-heading">
          <h1>
            Benifits of <b> Health Insurance</b>
          </h1>
          <div className="about-text">
            <img src="/assets/images/about-icon.png" alt="" />{" "}
            <p className=" justify_para">
              Health insurance is a crucial financial tool that provides
              individuals with a safety net against the high costs of medical
              care. Its benefits extend far beyond just covering hospital bills,
              offering a comprehensive approach to safeguarding both physical
              and financial well-being.
            </p>
          </div>
          <div className="about-text">
            <img src="/assets/images/about-icon.png" alt="" />{" "}
            <p className=" justify_para">
              Health insurance promotes preventive care by covering routine
              check-ups and screenings. Regular health examinations allow for
              the early detection of potential issues, enabling timely
              intervention and reducing the severity of illnesses. This
              proactive approach contributes to better overall health outcomes.
            </p>
          </div>
          <div className="about-text">
            <img src="/assets/images/about-icon.png" alt="" />{" "}
            <p className=" justify_para">
              It provides financial protection against unexpected medical
              expenses. In the event of an illness or injury, the insurance
              coverage helps alleviate the burden of exorbitant medical bills,
              ensuring that individuals can access necessary treatments without
              compromising their financial stability.
            </p>
          </div>
          <div className="about-text">
            <img src="/assets/images/about-icon.png" alt="" />{" "}
            <p className=" justify_para">
              Moreover, having health insurance encourages timely medical
              attention. Individuals are more likely to seek medical help
              promptly when they know that the financial aspect is taken care
              of. This leads to early diagnosis and treatment, improving the
              chances of successful recovery.
            </p>
          </div>
        </div>
      </div>
      <OurPartner />
      <div className="about-health-insurance">
        <div className="about-heading">
          <h1>
            Why Should we take <b> Health Insurance</b>
          </h1>
          <div className="about-text">
            <img src="/assets/images/about-icon.png" alt="" />{" "}
            <p className=" justify_para">
              Health insurance enhances accessibility to a broader network of
              healthcare providers. Insured individuals often have access to a
              range of doctors, specialists, and medical facilities, ensuring
              they receive high-quality care tailored to their specific needs.
              Furthermore, health insurance contributes to the overall
              well-being of communities. By encouraging regular medical
              check-ups and preventive care, it helps in controlling the spread
              of contagious diseases and promoting a healthier population.
            </p>
          </div>
          <div className="about-text">
            <img src="/assets/images/about-icon.png" alt="" />{" "}
            <p className=" justify_para">
              Another significant benefit is the peace of mind that health
              insurance brings. Knowing that one is covered in case of medical
              emergencies or unexpected health issues reduces stress and
              anxiety, fostering mental well-being.
            </p>
          </div>
          <div className="about-text">
            <img src="/assets/images/about-icon.png" alt="" />{" "}
            <p className=" justify_para">
              Health insurance also plays a vital role in supporting long-term
              health goals. It encourages a proactive approach to health
              management, motivating individuals to adopt healthier lifestyles
              and engage in activities that prevent illness.
            </p>
          </div>
          <div className="about-text">
            <img src="/assets/images/about-icon.png" alt="" />{" "}
            <p className=" justify_para">
              The benefits of health insurance are multifaceted, encompassing
              preventive care, financial security, improved access to
              healthcare, community well-being, peace of mind, and support for
              long-term health goals. It serves as a valuable asset in promoting
              a healthier and more secure future for individuals and communities
              alike.
            </p>
          </div>
        </div>
        <div className="about-health-img">
          <img src="/assets/images/about-img-3.png" alt="" />
        </div>
      </div>
      <div className="about-health-insurance">
        <div className="about-health-img">
          <img src="/assets/images/abpout-img-4.png" alt="" />
        </div>
        <div className="about-heading">
          <h1>
            Which <b> Health Insurance</b> plan <b>Should You Buy?</b>
          </h1>
          <div className="about-text">
            <img src="/assets/images/about-icon.png" alt="" />{" "}
            <p className=" justify_para">
              Selecting the right health insurance requires a careful assessment
              of individual needs. If you're generally healthy and prefer lower
              premiums, a high-deductible plan might be suitable. Conversely,
              individuals with chronic conditions should opt for comprehensive
              coverage to minimize out-of-pocket expenses.
            </p>
          </div>
          <div className="about-text">
            <img src="/assets/images/about-icon.png" alt="" />{" "}
            <p className=" justify_para">
              Examine the provider network to ensure your preferred doctors and
              facilities are included, ensuring convenient access to care.
              Evaluate coverage limits and exclusions as well. A robust policy
              should encompass a broad array of services, from prescription
              drugs to preventive care.
            </p>
          </div>
          <div className="about-text">
            <img src="/assets/images/about-icon.png" alt="" />{" "}
            <p className=" justify_para">
              Consider the financial aspects, comparing premiums, deductibles,
              and co-pays. Striking a balance between affordability and
              comprehensive coverage is crucial for financial well-being.
            </p>
          </div>
          <div className="about-text">
            <img src="/assets/images/about-icon.png" alt="" />{" "}
            <p className=" justify_para">
              Lastly, investigate the insurer's reputation for timely claims
              processing and customer service. Opt for a provider known for
              reliability and transparency. In conclusion, the ideal health
              insurance choice aligns with individual health requirements,
              features an extensive provider network, offers comprehensive
              coverage, and is backed by a reputable and financially stable
              insurer.
            </p>
          </div>
        </div>
      </div>
      <Support />
      <div className="about-health-insurance">
        <div className="about-heading">
          <h1>
            Am I Eligible to<b> Health Insurance</b>
          </h1>
          <div className="about-text">
            <img src="/assets/images/about-icon.png" alt="" />{" "}
            <p className=" justify_para">
              In India, eligibility for health insurance is contingent upon
              several factors. Firstly, age plays a crucial role, as many
              policies have specific entry age criteria. Typically, individuals
              between 18 and 65 years are eligible for health insurance
              coverage. Additionally, pre-existing medical conditions may affect
              eligibility, with insurers often subjecting applicants to medical
              screenings.
            </p>
          </div>
          <div className="about-text">
            <img src="/assets/images/about-icon.png" alt="" />{" "}
            <p className=" justify_para">
              Employment status is another determinant, as many Indians access
              health insurance through their employers. Companies frequently
              offer group health insurance plans, making coverage more
              accessible to their employees.
            </p>
          </div>
          <div className="about-text">
            <img src="/assets/images/about-icon.png" alt="" />{" "}
            <p className=" justify_para">
              Furthermore, financial stability is considered, as individuals
              must demonstrate the ability to pay premiums regularly.
              Government-sponsored health insurance schemes, such as Ayushman
              Bharat, extend coverage to economically disadvantaged families.
            </p>
          </div>
          <div className="about-text">
            <img src="/assets/images/about-icon.png" alt="" />{" "}
            <p className=" justify_para">
              It is advisable for individuals to thoroughly review policy
              criteria and consult with insurers to determine the best-suited
              health insurance plan based on their unique circumstances.
            </p>
          </div>
        </div>
        <div className="about-health-img">
          <img src="/assets/images/about-img-7.png" alt="" />
        </div>
      </div>
      <div className="about-health-insurance">
        <div className="about-health-img">
          <img src="/assets/images/about-img-6.png" alt="" />
        </div>
        <div className="about-heading">
          <h1>
            Documents Required for <b> Health Insurance</b>{" "}
          </h1>
          <div className="about-text">
            <img src="/assets/images/about-icon.png" alt="" />{" "}
            <p className=" justify_para">
              Obtaining health insurance requires specific documentation to
              ensure a smooth application process. Firstly, individuals need to
              submit their identity proof, typically in the form of an Aadhar
              card, passport, or voter ID. This helps verify the applicant's
              identity and citizenship status.
            </p>
          </div>
          <div className="about-text">
            <img src="/assets/images/about-icon.png" alt="" />{" "}
            <p className=" justify_para">
              Secondly, proof of address is essential, which can be established
              through documents like utility bills or a rental agreement.
            </p>
          </div>
          <div className="about-text">
            <img src="/assets/images/about-icon.png" alt="" />{" "}
            <p className=" justify_para">
              Medical documents play a pivotal role in the health insurance
              application. A recent medical certificate or a health declaration
              form is mandatory, outlining the individual's current health
              condition. Additionally, insurers may request previous medical
              records or test reports to assess pre-existing conditions.
              Financial documents, such as income proof and tax returns, are
              also crucial to determine the policyholder's ability to pay
              premiums.
            </p>
          </div>
          <div className="about-text">
            <img src="/assets/images/about-icon.png" alt="" />{" "}
            <p className=" justify_para">
              In conclusion, a comprehensive set of documents, including
              identity proof, address proof, medical certificates, and financial
              documents, are essential for health insurance applications in
              India. Providing accurate and complete information facilitates a
              hassle-free approval process and ensures individuals are
              adequately covered for their healthcare needs.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default AboutHealth;
