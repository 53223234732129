import React, { useState } from "react";
import { Modal, ModalBody } from "reactstrap";
import "./Quotelist.css";
import TooltipCommon from "../common/TooltipCommon";
import { useForm } from "react-hook-form";
import {
  dispatchMotorQuote,
  dispatchQuickQuote,
  resetQuickQuoteResults,
} from "../../store/action/userActions";
import { useDispatch, useSelector } from "react-redux";
import {
  KotakAPICall,
  digitAPICall,
  sompoAPICall,
} from "../services/TPApiCall";
import { VEHICLE_TYPE } from "../utility/constants";
function QuotelistDropdown({
  show,
  setModel,
  minMaxIdv,
  handleFilteredResult,
  sessionId,
}) {
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm({ mode: "onBlur" });
  const toggle = () => setModel(!show);
  const [select, setSelect] = useState("");
  const [idvRangeError, setIdvRangeError] = useState(false);
  const dispatch = useDispatch();
  const apiRequestQQ = useSelector((state) => state?.root?.apiRequestQQ);
  const motorRequest = useSelector((state) => state?.root?.motorRequest);

  const updateNCB = (data) => {
    const minValue = Math.round(minMaxIdv?.minimumIdv);
    const maxValue = Math.round(minMaxIdv?.maximumIdv);
    const numericValue = parseInt(data?.idv, 10);

    if (numericValue >= minValue && numericValue <= maxValue) {
      dispatchQuickQuote("Idv", data.idv);
      dispatchMotorQuote("idv", parseInt(data.idv));

      dispatch(resetQuickQuoteResults());

      const newData = { ...apiRequestQQ, Idv: parseInt(data.idv) };
      const newStructureData = {
        ...motorRequest,
        idv: parseInt(data.idv),
        sessionId: sessionId,
      };

      // sompoAPICall(newData);
      // KotakAPICall(newData);
      // digitAPICall(newData);
      handleFilteredResult(newData, newStructureData);
      toggle();
    }
  };

  return (
    <Modal isOpen={show} toggle={toggle} backdrop="static" centered size="md">
      <ModalBody>
        <div class="p-2">
          <div class="gsc_modalWrapper">
            <div className="modal-logo">
              <a href="">
                <img src="/assets/images/insurefastlogos.svg"></img>
              </a>
            </div>
            <div className="modal_heading w-100 mb-3">
              <h4 className="text-center fs-5">
                IDV{" "}
                {motorRequest?.vehicleType === VEHICLE_TYPE.FOUR_WHEELER
                  ? "( Car Value )"
                  : "( Bike Value )"}
                <TooltipCommon
                  data={
                    "Insured Declared Value is the maximum sum assured payable by the insurer, in events of theft or total loss of the vehicle. It refers to the current market value of the vehicle."
                  }
                />
              </h4>

              <div onClick={toggle} class="v2_close false">
                <a>
                  <img
                    src="/assets/images/delete_icon.svg"
                    title="Close"
                    alt="close"
                  />
                </a>
              </div>
            </div>
            <div className="mb-2">
              {/* <div onClick={() => setSelect("idv")} class="filterseries">
                <label>
                  <input
                    checked={select == "idv" ? true : false}
                    name="idv"
                    title="Best Offers"
                    type="radio"
                  />{" "}
                  Lowest IDV
                </label>
              </div> */}
              <div
                // onClick={() => setSelect("ownIdv")}
                class="filterseries"
              >
                <label>
                  {/* <input
                    checked={select == "ownIdv" ? true : false}
                    name="idv"
                    title="Best Offers"
                    type="radio"
                  />{" "} */}
                  Choose your own IDV
                </label>
              </div>
            </div>
            {/* <div class="v2_btngrp mb-3">
              <div class="wrapper">
                <button
                  type="submit"
                  name="submitBtn"
                  class="button submitButton gsc_col-xs-12 gsc_col-md-12"
                >
                  <div>Confirm</div>
                </button>
              </div>
            </div> */}

            {
              <form onSubmit={handleSubmit(updateNCB)}>
                <div class="valuebetween">
                  <span style={{ fontWeight: "400" }}>
                    Enter a value between ₹ {Math.round(minMaxIdv?.minimumIdv)}{" "}
                    to ₹{Math.round(minMaxIdv?.maximumIdv)}
                  </span>
                  <div class="idv-choose-inner mt-2 d-flex align-items-center">
                    <div>
                      <div className="filterbrand-outer">
                        <span class="rupees_space">₹ </span>
                        <input
                          type="number"
                          class="filterbrand-input"
                          placeholder="XXXXXX"
                          {...register("idv", {
                            required: "Field is required",
                          })}
                          onChange={(e) => {
                            if (
                              e.target.value >=
                                Math.round(minMaxIdv?.minimumIdv) &&
                              e.target.value <=
                                Math.round(minMaxIdv?.maximumIdv)
                            ) {
                              setIdvRangeError(false);
                            } else {
                              setIdvRangeError(true);
                            }
                          }}
                        />
                      </div>
                      {idvRangeError && (
                        <p
                          className="text-danger"
                          style={{ fontSize: "0.8rem" }}
                        >
                          Please Enter the IDV within specified range
                        </p>
                      )}
                    </div>
                    <button
                      type="submit"
                      title="Update"
                      class="secondaryButton"
                    >
                      Update
                    </button>
                  </div>
                </div>
              </form>
            }
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
}

export default QuotelistDropdown;
