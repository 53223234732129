import React, { useEffect, useState } from "react";
import { Modal, ModalBody } from "reactstrap";

import "./Quotelist.css";
import TooltipCommon from "../common/TooltipCommon";
import { useDispatch, useSelector } from "react-redux";
import {
  dispatchMotorQuote,
  dispatchQuickQuote,
  resetQuickQuoteResults,
} from "../../store/action/userActions";
import {
  KotakAPICall,
  digitAPICall,
  sompoAPICall,
} from "../services/TPApiCall";
import {
  Addons,
  POLICY_TYPE,
  TPAddons,
  VEHICLE_TYPE,
  odAddons,
} from "../utility/constants";

function QuotelistAddOns({
  show,
  setModel,
  handleFilteredResult,
  filter,
  setFilter,
}) {
  const dispatch = useDispatch();
  const list = [0, 20, 25, 35, 45, 50];
  const toggle = () => setModel(!show);
  const [select, setSelect] = useState(Addons);

  const [NewPolicyType, setNewPolicyType] = useState("");

  const QuickQouteResult = useSelector(
    (state) => state?.root?.QuickQouteResult
  );
  const apiRequestQQ = useSelector((state) => state?.root?.apiRequestQQ);
  const motorRequest = useSelector((state) => state?.root?.motorRequest);

  // const [filter, setFilter] = useState(() => {
  //   const storedFilter = localStorage.getItem("filter");
  //   return storedFilter
  //     ? JSON.parse(storedFilter)
  //     : {
  //         roadSideAssistance: false,
  //         engineProtection: false,
  //         tyreProtection: false,
  //         rimProtection: false,
  //         consumables: false,
  //         isElectricalAccessories: false,
  //         isNonElectricalAccessories: false,
  //         isTppdDiscount: false,
  //         personalAccident: false,
  //         invoiceCover: false,
  //         engineGearBox: false,
  //         ncbProtection: false,
  //         isVoluntaryDeductable: false,
  //         isPassengerCover: false,
  //         lossOfPersonalBelongings: false,
  //         zeroDepriciationCover: false,
  //         keyReplacement: false,
  //         liabilitiesToPaidDriver: false,
  //         electricAmount: 0,
  //         nonElectricAmount: 0,
  //         passengerCoverAmount: 0,
  //         voluntaryDeductableAmount: 0,
  //         noOfPaidDrivers: 0,
  //       };
  // });

  useEffect(() => {
    localStorage.setItem("filter", JSON.stringify(filter));
  }, [filter]);

  // Handle filter options

  const handleFilterOptions = (cover, item) => {
    // debugger;

    setNewPolicyType(cover);
    dispatchQuickQuote("isFiltered", true);
    let newData = { ...apiRequestQQ };
    let newStructureData = { ...motorRequest };

    // for (let key in filter) {
    //   if (key === "Consumables") {
    //     dispatchMotorQuote("consumables", filter[key]);
    //     newStructureData["consumables"] = filter[key];
    //   } else if (key === "ElectricAmount") {
    //     dispatchMotorQuote("electricAmount", filter[key]);
    //     newStructureData["electricAmount"] = filter[key];
    //   } else if (key === "EngineGearBox") {
    //     dispatchMotorQuote("engineGearBox", filter[key]);
    //     newStructureData["engineGearBox"] = filter[key];
    //   } else if (key === "EngineProtection") {
    //     dispatchMotorQuote("engineProtection", filter[key]);
    //     newStructureData["engineProtection"] = filter[key];
    //   } else if (key === "InvoiceCover") {
    //     dispatchMotorQuote("invoiceCover", filter[key]);
    //     newStructureData["invoiceCover"] = filter[key];
    //   } else if (key === "IsElectricalAccessories") {
    //     dispatchMotorQuote("isElectricalAccessories", filter[key]);
    //     newStructureData["isElectricalAccessories"] = filter[key];
    //   } else if (key === "IsNonElectricalAccessories") {
    //     dispatchMotorQuote("isNonElectricalAccessories", filter[key]);
    //     newStructureData["isNonElectricalAccessories"] = filter[key];
    //   } else if (key === "KeyReplacement") {
    //     dispatchMotorQuote("keyReplacement", filter[key]);
    //     newStructureData["keyReplacement"] = filter[key];
    //   } else if (key === "LiabilitiesToPaidDriver") {
    //     dispatchMotorQuote("liabilitiesToPaidDriver", filter[key]);
    //     newStructureData["liabilitiesToPaidDriver"] = filter[key];
    //   } else if (key === "LossOfPersonalBelongings") {
    //     dispatchMotorQuote("lossOfPersonalBelongings", filter[key]);
    //     newStructureData["lossOfPersonalBelongings"] = filter[key];
    //   } else if (key === "NCBProtection") {
    //     dispatchMotorQuote("ncbProtection", filter[key]);
    //     newStructureData["ncbProtection"] = filter[key];
    //   } else if (key === "NoOfLegelDrives") {
    //     if (filter?.LiabilitiesToPaidDriver === false) {
    //       dispatchMotorQuote("noOfPaidDrivers", "");
    //       newStructureData["noOfPaidDrivers"] = "";
    //     } else {
    //       dispatchMotorQuote("noOfPaidDrivers", filter[key]);
    //       newStructureData["noOfPaidDrivers"] = filter[key];
    //     }
    //   } else if (key === "NonElectricAmount") {
    //     dispatchMotorQuote("nonElectricAmount", filter[key]);
    //     newStructureData["nonElectricAmount"] = filter[key];
    //   } else if (key === "PassengerCover") {
    //     dispatchMotorQuote("isPassengerCover", filter[key]);
    //     newStructureData["isPassengerCover"] = filter[key];
    //   } else if (key === "PassengerCoverAmount") {
    //     if (filter?.PassengerCover === false) {
    //       dispatchMotorQuote("passengerCoverAmount", "");
    //       newStructureData["passengerCoverAmount"] = "";
    //     } else {
    //       dispatchMotorQuote("passengerCoverAmount", filter[key]);
    //       newStructureData["passengerCoverAmount"] = filter[key];
    //     }
    //   } else if (key === "PersonalAccident") {
    //     dispatchMotorQuote("personalAccident", filter[key]);
    //     newStructureData["personalAccident"] = filter[key];
    //   } else if (key === "RoadSideAssistance") {
    //     dispatchMotorQuote("roadSideAssistance", filter[key]);
    //     newStructureData["roadSideAssistance"] = filter[key];
    //   } else if (key === "TyreProtection") {
    //     dispatchMotorQuote("tyreProtection", filter[key]);
    //     newStructureData["tyreProtection"] = filter[key];
    //   } else if (key === "VoluntaryDeductive") {
    //     dispatchMotorQuote("isVoluntaryDeductable", filter[key]);
    //     newStructureData["isVoluntaryDeductable"] = filter[key];
    //   } else if (key === "VoluntaryDeductiveAmount") {
    //     dispatchMotorQuote("voluntaryDeductableAmount", filter[key]);
    //     newStructureData["voluntaryDeductableAmount"] = filter[key];
    //   } else if (key === "ZeroDepriciationCover") {
    //     dispatchMotorQuote("zeroDepriciationCover", filter[key]);
    //     newStructureData["zeroDepriciationCover"] = filter[key];
    //   } else if (key === "RimProtection") {
    //     dispatchMotorQuote("rimProtection", filter[key]);
    //     newStructureData["rimProtection"] = filter[key];
    //   } else if (key === "CNGValue") {
    //     dispatchMotorQuote("isCngAccessories", true);
    //     dispatchMotorQuote("cngValue", filter[key]);
    //     newStructureData["cngValue"] = filter[key];
    //   } else if ((key = "isTppdDiscount")) {
    //     dispatchMotorQuote("isTppdDiscount", filter[key]);
    //     newStructureData["isTppdDiscount"] = filter[key];
    //   }
    // }
    for (let key in filter) {
      newData[key] = filter[key];

      dispatchQuickQuote("AddOns." + key, filter[key]);
    }

    for (let key in filter) {
      newStructureData[key] = filter[key];
      dispatchMotorQuote(`${key}`, filter[key]);
    }

    let newItem = 0;

    newData["PreviousNoClaimBonus"] = newItem;

    dispatch(resetQuickQuoteResults());

    // debugger;
    // sompoAPICall(apiRequestQQ);
    // KotakAPICall(apiRequestQQ);
    // digitAPICall(apiRequestQQ);

    handleFilteredResult(apiRequestQQ, newStructureData);
  };

  // const filterNew = () => {};

  const handleChangeFilter = (e) => {
    const { name, checked } = e.target;
    setFilter({ ...filter, [name]: checked });
  };

  const handleChnageValue = (e) => {
    const { name, value } = e.target;
    setFilter({ ...filter, [name]: parseFloat(value) });
  };

  const filteredAddons = (addons) => {
    if (motorRequest?.vehicleType === VEHICLE_TYPE?.TWO_WHEELER) {
      return addons.filter((item) => item.value !== "isCngAccessories");
    }
    return addons;
  };

  const filteredTPAddons = filteredAddons(TPAddons);
  const filteredODAddons = filteredAddons(odAddons);
  const filteredAddonsList = filteredAddons(Addons);

  const addonsToRender =
    motorRequest.newPolicyType === POLICY_TYPE?.THIRDPARTY
      ? filteredTPAddons
      : motorRequest.newPolicyType === POLICY_TYPE?.ODONLY
      ? filteredODAddons
      : filteredAddonsList;

  return (
    <Modal isOpen={show} toggle={toggle} centered backdrop="static" size="md">
      <ModalBody>
        <div class="p-2">
          <div class="gsc_modalWrapper">
            <div className="modal-logo">
              <a href="">
                <img src="/assets/images/insurefastlogos.svg"></img>
              </a>
            </div>
            <div className="modal_heading w-100 mb-3">
              <h4 className="text-center fs-5">Add-on Cover</h4>

              <div onClick={toggle} class="v2_close false">
                <a>
                  <img
                    src="/assets/images/delete_icon.svg"
                    title="Close"
                    alt="close"
                  />
                </a>
              </div>
            </div>

            <div className="mb-3">
              <div className="add-ons-outer px-1">
                {
                  // (apiRequestQQ.NewPolicyType === "ThirdParty"
                  //   ? TPAddons
                  //   : apiRequestQQ.NewPolicyType === "ODOnly"
                  //   ? odAddons
                  //   : Addons
                  // )
                  addonsToRender.map((item, index) => (
                    <div className="form-check mb-2 ">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        value={item.value}
                        style={{ border: "1px solid black" }}
                        name={item.value}
                        checked={filter[item.value]}
                        onChange={(e) => handleChangeFilter(e)}
                        id={item.value}
                      />
                      <label
                        className="form-check-lable d-block"
                        htmlFor={item.value}
                      >
                        {item.label}
                      </label>
                      {item.value !== "isPassengerCover" &&
                        item.value !== "liabilitiesToPaidDriver" &&
                        item.type == "input" &&
                        filter[item.value] == true && (
                          <input
                            type="number"
                            min={item.min}
                            name={item.inputName}
                            value={filter[item.inputName]}
                            onChange={(e) => handleChnageValue(e, item.value)}
                            style={{ padding: "5px 12px", borderRadius: "6px" }}
                          />
                        )}
                      {item.value === "isPassengerCover" &&
                        item.type == "input" &&
                        filter[item.value] == true && (
                          <>
                            <select
                              className=""
                              style={{
                                padding: "8px 12px",
                                width: "205px",
                                border: "2px solid black",
                                fontSize: "14px",
                                borderRadius: "6px",
                              }}
                              value={filter[item.inputName]}
                              onChange={(e) => handleChnageValue(e)}
                              name={item.inputName}
                            >
                              {Object.entries(item.options).map(
                                ([key, value]) => (
                                  <option key={key} value={value}>
                                    {value}
                                  </option>
                                )
                              )}
                            </select>
                          </>
                        )}
                      {item.value === "liabilitiesToPaidDriver" &&
                        item.type == "input" &&
                        filter[item.value] == true && (
                          <>
                            <select
                              className=""
                              style={{
                                padding: "8px 12px",
                                width: "205px",
                                border: "2px solid black",
                                fontSize: "14px",
                                borderRadius: "6px",
                              }}
                              value={filter[item.inputName]}
                              onChange={(e) => handleChnageValue(e)}
                              name={item.inputName}
                            >
                              {Object.entries(item.options).map(
                                ([key, value]) => (
                                  <option key={key} value={value}>
                                    {value}
                                  </option>
                                )
                              )}
                            </select>
                          </>
                        )}
                    </div>
                  ))
                }
              </div>
            </div>
            <div class="v2_btngrp">
              <div class="wrapper appliedno">
                <div class="bold">
                  (
                  {
                    Object.values(filter).filter((value) => value === true)
                      .length
                  }
                  ) Applied
                </div>{" "}
                <div class="match">Matching with your criteria</div>
              </div>
              <button
                type="submit"
                name="submitBtn"
                class="button bold font-13"
                style={{ width: "30%" }}
                onClick={() => {
                  handleFilterOptions("", "");
                  toggle();
                }}
              >
                Apply
              </button>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
}

export default QuotelistAddOns;

// const Addons = [
//   { label: "Zero Dep Cover", value: "zeroDepriciationCover", type: "select" },
//   {
//     label: "Road Side Assistance",
//     value: "roadSideAssistance",
//     type: "select",
//   },
//   { label: "Consumables", value: "consumables", type: "select" },
//   { label: "Key Replacement", value: "keyReplacement", type: "select" },
//   { label: "Invoice Cover", value: "invoiceCover", type: "select" },
//   { label: "Engine Gearbox Cover", value: "engineGearBox", type: "select" },
//   { label: "NCB Protection", value: "ncbProtection", type: "select" },
//   {
//     label: "Loss Of Personal Belongings",
//     value: "lossOfPersonalBelongings",
//     type: "select",
//   },
//   { value: "engineProtection", label: "Engine Protection", type: "select" },
//   { value: "tyreProtection", label: "Tyre Protection", type: "select" },
//   { value: "rimProtection", label: "Rim Protection", type: "select" },

//   {
//     label: "Voluntary Deductive",
//     value: "isVoluntaryDeductable",
//     type: "input",
//     min: 0,
//     inputName: "voluntaryDeductableAmount",
//   },
//   {
//     label: "Personal Accident Cover",
//     value: "personalAccident",
//     type: "select",
//   },
//   {
//     label: "Electrical Accessories",
//     value: "isElectricalAccessories",
//     type: "input",
//     min: "1000",
//     inputName: "electricAmount",
//   },
//   {
//     label: "Non-Electrical Accessories",
//     value: "isNonElectricalAccessories",
//     type: "input",
//     min: "1000",
//     inputName: "nonElectricAmount",
//   },
//   {
//     label: "Is Bi-fuel Kit?",
//     value: "isCngAccessories",
//     type: "input",
//     min: 0,
//     inputName: "cngValue",
//   },
//   {
//     label: "Passenger Cover",
//     value: "isPassengerCover",
//     type: "input",
//     min: "10000",
//     inputName: "passengerCoverAmount",
//     options: {
//       0: "Select Amount",
//       1: 10000,
//       2: 50000,
//       3: 100000,
//       4: 200000,
//     },
//   },
//   {
//     label: "Legal Libability To Paid Driver",
//     value: "liabilitiesToPaidDriver",
//     type: "input",
//     min: "10000",
//     inputName: "noOfPaidDrivers",
//     options: {
//       1: 1,
//       2: 2,
//     },
//   },
//   {
//     label: "Tppd Discount",
//     value: "isTppdDiscount",
//     type: "select",
//   },
// ];
// const odAddons = [
//   { label: "Zero Dep Cover", value: "zeroDepriciationCover", type: "select" },
//   {
//     label: "Road Side Assistance",
//     value: "roadSideAssistance",
//     type: "select",
//   },
//   { label: "Consumables", value: "consumables", type: "select" },
//   { label: "Key Replacement", value: "keyReplacement", type: "select" },
//   { label: "Invoice Cover", value: "invoiceCover", type: "select" },
//   { label: "Engine Gearbox Cover", value: "engineGearBox", type: "select" },
//   { label: "NCB Protection", value: "ncbProtection", type: "select" },
//   {
//     label: "Loss Of Personal Belongings",
//     value: "lossOfPersonalBelongings",
//     type: "select",
//   },
//   { value: "engineProtection", label: "Engine Protection", type: "select" },
//   { value: "tyreProtection", label: "Tyre Protection", type: "select" },
//   { value: "rimProtection", label: "Rim Protection", type: "select" },

//   {
//     label: "Voluntary Deductive",
//     value: "isVoluntaryDeductable",
//     type: "input",
//     min: 0,
//     inputName: "voluntaryDeductableAmount",
//   },
//   {
//     label: "Electrical Accessories",
//     value: "isElectricalAccessories",
//     type: "input",
//     min: "1000",
//     inputName: "electricAmount",
//   },
//   {
//     label: "Non-Electrical Accessories",
//     value: "isNonElectricalAccessories",
//     type: "input",
//     min: "1000",
//     inputName: "nonElectricAmount",
//   },
//   {
//     label: "Is Bi-fuel Kit?",
//     value: "isCngAccessories",
//     type: "input",
//     min: 0,
//     inputName: "cngValue",
//   },
// ];

// const TPAddons = [
//   {
//     label: "Personal Accident Cover",
//     value: "personalAccident",
//     type: "select",
//   },
//   {
//     label: "Passenger Cover",
//     value: "isPassengerCover",
//     type: "input",
//     min: "10000",
//     inputName: "passengerCoverAmount",
//     options: {
//       0: "Select Amount",
//       1: 10000,
//       2: 50000,
//       3: 100000,
//       4: 200000,
//     },
//   },
//   {
//     label: "Legal Libability To Paid Driver",
//     value: "liabilitiesToPaidDriver",
//     type: "input",
//     min: "10000",
//     inputName: "noOfPaidDrivers",
//     options: {
//       1: 1,
//       2: 2,
//     },
//   },
//   {
//     label: "Tppd Discount",
//     value: "isTppdDiscount",
//     type: "select",
//   },
// ];
