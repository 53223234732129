import {
  API_ERROR_RESPONSE,
  INSPECTION_QUICK_QUOTE,
  LOGGED_USER_DETAILS,
  QUICK_QUOTE_PAYLOAD,
  QUICK_QUOTE_RESULTS,
  REMOVE_ERROR_MESSAGE,
  RESET_QUICK_QUOTE_PAYLOAD,
  RESET_QUICK_QUOTE_RESULTS,
  RESET_SELECTED_PLAN,
  SELECTED_PLAN,
  HEALTH_QUICK_QUOTE_PAYLOAD,
  HEALTH_QUICK_QUOTE_RESULTS,
  RESET_HEALTH_QUICK_QUOTE_PAYLOAD,
  RESET_HEALTH_QUICK_QUOTE_RESULTS,
  RESET_USER_DETAILS,
  USER_DATA,
  RESET_USER_DATA,
  PROCESS_FLOW_MOTOR,
  RESET_PROCESS_FLOW_MOTOR,
  PROCESS_FLOW_HEALTH,
  RESET_PROCESS_FLOW_HEALTH,
  PET_QUICK_QUOTE_PAYLOAD,
  PET_QUICK_QUOTE_RESULT,
  RESET_PET_QUICK_QUOTE_PAYLOAD,
  RESET_PET_QUICK_QUOTE_RESULT,
  MOTOR_QUOTE_PAYLOAD,
  RESET_MOTOR_QUOTE_PAYLOAD,
  KYC_CONFIG,
  RESET_KYC_CONFIG,
} from "../types";
import store from "../index";

export const saveUserDetails = (payload) => {
  return {
    type: LOGGED_USER_DETAILS,
    payload,
  };
};
export const resetUserDetails = (payload) => {
  return {
    type: RESET_USER_DETAILS,
    payload,
  };
};

export const saveUserData = (payload) => {
  return {
    type: USER_DATA,
    payload,
  };
};

export const resetUserData = (payload) => {
  return {
    type: RESET_USER_DATA,
    payload,
  };
};

export const quickQuotePayload = (payload) => {
  return {
    type: QUICK_QUOTE_PAYLOAD,
    payload,
  };
};

export const motorQuotePayload = (payload) => {
  return {
    type: MOTOR_QUOTE_PAYLOAD,
    payload,
  };
};

export const processFlowMotor = (payload) => {
  return {
    type: PROCESS_FLOW_MOTOR,
    payload,
  };
};

export const processFlowHealth = (payload) => {
  return {
    type: PROCESS_FLOW_HEALTH,
    payload,
  };
};

export const quickQuoteResult = (payload) => {
  return {
    type: QUICK_QUOTE_RESULTS,
    payload,
  };
};

export const healthQuickQuotePayload = (payload) => {
  return {
    type: HEALTH_QUICK_QUOTE_PAYLOAD,
    payload,
  };
};

export const healthQuickQuoteResult = (payload) => {
  return {
    type: HEALTH_QUICK_QUOTE_RESULTS,
    payload,
  };
};

export const petQuickQuotePayload = (payload) => {
  return {
    type: PET_QUICK_QUOTE_PAYLOAD,
    payload,
  };
};

export const petQuickQuoteResult = (payload) => {
  return {
    type: PET_QUICK_QUOTE_RESULT,
    payload,
  };
};

export const kycConfig = (payload) => {
  return {
    type: KYC_CONFIG,
    payload,
  };
};

export const resetKycConfig = (payload) => {
  return {
    type: RESET_KYC_CONFIG,
    payload,
  };
};

export const apiErrorReponse = (payload) => {
  return {
    type: API_ERROR_RESPONSE,
    payload,
  };
};

export const removeApiErrorMessage = (payload) => {
  return {
    type: REMOVE_ERROR_MESSAGE,
    payload,
  };
};

export const selectedPlanAction = (payload) => {
  return {
    type: SELECTED_PLAN,
    payload,
  };
};

export const resetapiRequestQQ = (payload) => {
  return {
    type: RESET_QUICK_QUOTE_PAYLOAD,
    payload,
  };
};

export const resetMotorRequest = (payload) => {
  return {
    type: RESET_MOTOR_QUOTE_PAYLOAD,
    payload,
  };
};

export const resetProcessFlowMotor = (payload) => {
  return {
    type: RESET_PROCESS_FLOW_MOTOR,
    payload,
  };
};

export const resetProcessFlowHealth = (payload) => {
  return {
    type: RESET_PROCESS_FLOW_HEALTH,
    payload,
  };
};

export const resethealthRequestQQ = (payload) => {
  return {
    type: RESET_HEALTH_QUICK_QUOTE_PAYLOAD,
    payload,
  };
};

export const resetPetRequestQQ = (payload) => {
  return {
    type: RESET_PET_QUICK_QUOTE_PAYLOAD,
    payload,
  };
};

export const resetSelectedPlan = () => {
  return {
    type: RESET_SELECTED_PLAN,
  };
};

export const resetQuickQuoteResults = () => {
  return {
    type: RESET_QUICK_QUOTE_RESULTS,
  };
};

export const resetHealthQuickQuoteResults = () => {
  return {
    type: RESET_HEALTH_QUICK_QUOTE_RESULTS,
  };
};

export const resetPetQuickQuoteResult = () => {
  return {
    type: RESET_PET_QUICK_QUOTE_RESULT,
  };
};

export const inspectionQuickQuoteSet = (payload) => {
  return {
    type: INSPECTION_QUICK_QUOTE,
    payload,
  };
};

export const dispatchQuickQuote = (key, value) => {
  let obj = {
    key: key,
    value: value,
  };
  store.dispatch(quickQuotePayload(obj));
};

export const dispatchMotorQuote = (key, value) => {
  let obj = {
    key: key,
    value: value,
  };
  store.dispatch(motorQuotePayload(obj));
};

export const dispatchProcessFlowMotor = (key, value) => {
  let obj = {
    key: key,
    value: value,
  };
  store.dispatch(processFlowMotor(obj));
};

export const dispatchProcessFlowHealth = (key, value) => {
  let obj = {
    key: key,
    value: value,
  };
  store.dispatch(processFlowHealth(obj));
};

export const dispatchHealthQuickQuote = (key, value) => {
  let obj = {
    key: key,
    value: value,
  };
  store.dispatch(healthQuickQuotePayload(obj));
};

export const dispatchPetQuickQuote = (key, value) => {
  let obj = {
    key: key,
    value: value,
  };
  store.dispatch(petQuickQuotePayload(obj));
};

export const dispatchUserDetails = (key, value) => {
  let obj = {
    [key]: value,
    // value: value,
  };
  store.dispatch(saveUserDetails(obj));
};

export const saveApiErrorReponse = (api, error, image) => {
  store.dispatch(
    apiErrorReponse({
      Api_name: api,
      error: error,
      image: image,
    })
  );
};

export const dispatchUserData = (key, value) => {
  let obj = {
    [key]: value,
  };
  store.dispatch(saveUserData(obj));
};

export const dispatchKycConfig = (value) => {
  // let obj = {
  //   [key]: value,
  // };
  store.dispatch(kycConfig(value));
};

export const dispatchResetKycConfig = (value) => {
  // let obj = {
  //   [key]: value,
  // };
  store.dispatch(kycConfig(value));
};

export const dispatchResetQuickQuote = (key, value) => {
  let obj = {
    isFiltered: false,
    quotationNumber: "",
    VehicleType: "",
    CustomerType: "INDIVIDUAL",
    IsPreviousInsurerKnown: true,
    Salutation: "Mr",
    RegistrationDate: "",
    RegistrationNumber: "",
    PolicyEndDate: "",
    PolicyStartDate: "",
    PreInsurerCode: "",
    PrePolicyNumber: "",
    ProductCode: "",
    ManufaturingDate: "",
    ProposalId: "",
    VariantCode: {
      Digit: "",
      Bajaj: "",
      HDFC: "",
      Shriram: "",
      Kotak: "",
      Reliance: "",
      Future: "",
      Royal: "",
      Sompo: "",
    },
    ApiUniqueNumber: {
      Digit: "",
      Bajaj: "",
      HDFC: "",
      Shriram: "",
      Kotak: "",
      Reliance: "",
      Future: "",
      Royal: "",
      Magma: "",
      Sompo: "",
    },
    Email: "",
    FirstName: "",
    LastName: "",
    MiddleName: "",
    MobileNumber: "",
    FlatNumber: "",
    StreetNumber: "",
    Street: "",
    District: "",
    City: "",
    State: "",
    StateCode: "",
    Country: "",
    Pincode: "",
    PermanentAddress: "",
    Dob: "",
    Gender: "",
    PrePolicyEndDate: "",
    MakeName: "",
    ModelName: "",
    VariantName: "",
    PolicyType: "Renewal",
    RegistrationYear: "",
    PreviousPolicyType: "",
    NewPolicyType: "",
    FuelType: "",
    customerId: "",
    ApiId: "",
    policyId: "",
    RtoCode: "",
    EngineNumber: "",
    ChassisNumber: "",
    NomineeFirstName: "",
    NomineeLastName: "",
    NomineeDateOfBirth: "",
    NomineeRelationship: "",
    PreviousInsuranceClaimed: false,
    PreviousOwnerChanged: false,
    Idv: 0,
    PreviousNoClaimBonus: "0",
    PersonalAccident: {
      Selection: false,
      InsuredAmount: false,
      CoverTerm: false,
    },
    AddOns: {
      RoadSideAssistance: false,
      EngineProtection: false,
      TyreProtection: false,
      RimProtection: false,
      Consumables: false,
      IsElectricalAccessories: false,
      IsNonElectricalAccessories: false,
      IsCngAccessories: false,
      PersonalAccident: false,
      InvoiceCover: false,
      EngineGearBox: false,
      NCBProtection: false,
      VoluntaryDeductive: false,
      PassengerCover: false,
      LossOfPersonalBelongings: false,
      ZeroDepriciationCover: false,
      LiabilitiesToPaidDriver: false,
      ElectricAmount: 0,
      NonElectricAmount: 0,
      CNGValue: 0,
      PassengerCoverAmount: 0,
      VoluntaryDeductiveAmount: 0,
      discount: 0,
      NoOfLegelDrives: 0,
    },
    PaymentAmount: 0,
    PaymentId: "",
    CubicCapacity: "",
    CarryingCapacity: "",
    VehicleMakeCode: "",
    VehicleModelCode: "",
    VehicleSubTypeCode: "",
    RegistrationCity: "",
    IsVehicleNew: false,
    applicationId: "",
    PolicyStatus: "continue",
    PanNumber: "",
    PanImage: "",
    AdharImage: "",
    IsHypothecation: false,
    HpnBank: "",
    HpnCity: "",
    PreTPPolicyNumber: "",
    PreTPInsurerCode: "",
    PreTPPolicyEndDate: "",
    GSTNo: "",
    IsValidPuc: false,
    PUCNumber: "",
    PUCValidUpto: "",
    RelianceRTOCode: "",
    IsInternalCNG: false,
    KYC: {
      HDFC: "",
      Future: "",
      Reliance: "",
      Kotak: "",
      RoyalRefNo: "",
      Royal: "",
      Shriram: "",
      AadharNo: "",
      FatherName: "",
      MotherName: "",
      AadharBackPic: "",
      PassportPic: "",
      Sompo: "",
    },
    NCBSET: false,
  };
  store.dispatch(resetapiRequestQQ(obj));
};

export const dispatchResetMotorQuote = (key, value) => {
  let obj = {
    customerLeadId: "",
    customerUniqueNumber: "",
    rId: "",
    source: "customer",
    startingSource: "",
    emplId: "",
    vehicleType: "",
    vehicleSubType: "",
    newPolicyType: "",
    quotationNumber: "",
    makeName: "",
    makeId: "",
    modelName: "",
    modelId: "",
    variantName: "",
    variantId: "",
    fuelType: "",
    salutation: "",
    email: "",
    firstName: "",
    lastName: "",
    middleName: "",
    mobileNumber: "",
    addressLine1: "",
    addressLine2: "",
    addressLine3: "",
    cityId: "",
    stateId: "",
    pincode: "",
    isRcAddressSame: "",
    rcAddressLine1: "",
    rcAddressLine2: "",
    rcAddressLine3: "",
    roadSideAssistance: false,
    engineProtection: false,
    tyreProtection: false,
    consumables: false,
    isElectricalAccessories: false,
    isNonElectricalAccessories: false,
    isCngAccessories: false,
    cngValue: "",
    cngKitType: "",
    isTppdDiscount: false,
    personalAccident: false,
    invoiceCover: false,
    ncbProtection: false,
    isVoluntaryDeductable: false,
    rimProtection: false,
    voluntaryDeductableAmount: "",
    lossOfPersonalBelongings: false,
    zeroDepriciationCover: false,
    keyReplacement: false,
    electricAmount: "",
    nonElectricAmount: "",
    passengerCoverAmount: "",
    isPassengerCover: false,
    liabilitiesToPaidDriver: false,
    noOfPaidDrivers: "",
    isHypothecation: false,
    hpnCity: "",
    hpnBank: "",
    hpnBankCode: "",
    customerType: "individual",
    planId: "",
    isOwnerChanged: false,
    prevPolicyExpiryStatus: "",
    businessType: "",
    insurerCode: "",
    isPreviousInsuranceClaimed: false,
    prevPolicyEndDate: "",
    prevPolicyNumber: "",
    prevNoClaimBonus: 0,
    prevPolicyType: "",
    prevTPInsurerCode: "",
    prevTPPolicyNumber: "",
    prevTPPolicyEndDate: "",
    previousPolicyDetails: "",
    previousTpPolicyDetails: "",
    rtoCode: "",
    manufaturingDate: "",
    registrationDate: "",
    registrationNumber: "",
    differenceInYears: "",
    variantCode: "",
    idv: 0,
    engineNumber: "",
    chassisNumber: "",
    isValidPuc: false,
    pucNumber: "",
    pucValidUpto: "",
    nomineeName: "",
    nomineeDob: "",
    nomineeRelation: "",
    appointeeName: "",
    appointeeDob: "",
    appointeeRelation: "",
    reasonForNotOptingPersonalAccident: "",
    caseId: "",
    dob: "",
    gender: "",
    maritalStatus: "",
    docName: "",
    docNumber: "",
    kycStatus: "",
    kycType: "",
    city: "",
    state: "",
    kycData: {},
    isVehicleNew: false,
    handlePreviousButton: false,
    rtoCityName: "",
    isPrevZeroDepreciationCover: true,
    isPrevInvoiceCover: true,
    isPrevEngineProtection: true,
    isPrevTyreProtection: true,
    reasonForNotOptingCpa: "",
    updatedFutureQuotes: false,
    journeyFromStart: false,
    hpnBankData: {},
    ovdActive: false,
  };
  store.dispatch(resetMotorRequest(obj));
};

export const dispatchResetProcessFlowMotor = (key, value) => {
  let obj = {
    quotationNumber: "",
    step1: {
      registerationNumber: "",
    },
    customerDetails: {
      FullName: "",
      Gender: "",
      Email: "",
      Phone: "",
      DateofBirth: "",
      MaritalStatus: "",
      City: "",
      CustomerUniqueNumber: "",
    },
    step2: {
      make: "",
    },
    step3: {},
    step4: {},
    step5: {},
    step6: {},
    step7: {},
    step8: {},
    step9: {},
    step10: {},
    step11: {},
    step12: {},
    step13: {},
  };
  store.dispatch(resetProcessFlowMotor(obj));
};

export const dispatchResetProcessFlowHealth = (key, value) => {
  let obj = {
    quotationNumber: "",
    step1: {
      registerationNumber: "",
    },
    customerDetails: {
      FullName: "",
      Gender: "",
      Email: "",
      Phone: "",
      DateofBirth: "",
      MaritalStatus: "",
      City: "",
      CustomerUniqueNumber: "",
    },
    step2: {},
    step3: {},
    step4: {},
    step5: {},
    step6: {},
    step7: {},
    step8: {},
    step9: {},
    step10: {},
    step11: {},
    step12: {},
    step13: {},
  };
  store.dispatch(resetProcessFlowHealth(obj));
};

export const dispatchResetHealthQuickQuote = (healthKey, healthValue) => {
  let healthObj = {
    enquiryId: "",
    paymentURL: "",
    quotationNumber: "",
    CustomerDetails: {
      customerSaluation: "", // either MR or MS
      customerFirstName: "",
      customerMiddleName: "",
      customerLastName: "",
      customerGender: "",
      customerRelation: "",
      customerMobileNumber: "",
      customerPincode: "",
      customerAge: "",
      customerMedicalHistory: {
        NoExistingDesease: false, // it should have blank String or NoDeases
        Diabetes: false,
        DiabetesSince: "",
        hypertensionOrBloodPressure: false,
        hypertensionOrBloodPressureSince: "",
        liverDesease: false,
        liverDeseaseSince: "",
        cancerDeaseas: false,
        cancerDeaseasSince: "",
        cardiacDeaseas: false,
        cardiacDeaseasSince: "",
        jointPain: false,
        jointPainSince: "",
        kidneyDeasease: false,
        kidneyDeaseaseSince: "",
        paralysis: false,
        paralysisSince: "",
        congenitalDeaseas: false,
        congenitalDeaseasSince: "",
        hivAndAidsDeaseas: false,
        hivAndAidsDeaseasSince: "",
        lungDeaseas: false,
        lungDeaseasSince: "",
        endoAndPituitaryDeasease: false,
        endoAndPituitaryDeaseaseSince: "",
        illness: false,
        illnessSince: "",
        surgeries: false,
        surgeriesSince: "",
        otherDeaseas: false,
        otherDeaseasSince: "",
        otherDeaseasDiscription: "",
      },
      customerAdditionalHealthEnquiry: {
        healthHospitalized: false,
        PreviousHealthClaim: false,
        healthDeclined: false,
        healthCovered: false,
        isSmoking: false,
        smokingSince: "",
        smokingDetails: "",
      },
      medicalHistory: {
        Alcohol: false,
        AlcoholDuration: false,
        AlcoholQuantity: false,
        AlcoholType: false,
        Hospitalized: false,
        IllnessOrDisease: false,
        Narcotics: false,
        PED: false,
        Smoker: false,
        SmokerDuration: false,
        SmokerQuantity: false,
        SmokerType: false,
        TakenAnyTreatment: false,
        Tobacco: false,
        TobaccoDuration: false,
        TobaccoQuantity: false,
        TobaccoType: false,
        TreatmentOrMedicine: false,

        VisitedDoctor: false,
      },
      customerDOB: "", // date should be in dd-mm-yyyy format
      customerWeight: "",
      customerHeight: "",
      customerBMI: "",
      customerOccupation: "",
      customerEmailAddress: "",
      customerAddress: {
        permanentAddress: {
          address_1: "",
          address_2: "",
          state: "",
          city: "",
          pincode: "",
          area: "",
        },
        communicationAddress: {
          address_1: "",
          address_2: "",
          state: "",
          city: "",
          pincode: "",
          area: "",
        },
      },
      customerCity: "",
      customerPanNumber: "",
    },
    NomineeDetails: {
      NomineeName: "",
      NomineeRelation: "",
      NomineeDOB: "",
    },
    PlanDetails: {
      sumInsuredValue: "5LAC",
      countOfMembers: "1",
      planTenure: "1year",
      planType: "Individual", // this should be individual or family-floater, if family members are more than 1, then consider it as a family floater
      addons: {
        NCBsuper: false,
        OPDCare: false,
        OPDCareValue: 0,
        smartSelect: false,
        careShield: false,
        co_paymentWaiver: false,
        PEDReduction: false,
        coverageNonMedical: false,
        preExistingDiseaseWaitingPeriod: false,
        maternityChildcareBenefit: false,
      },
    },
  };
  store.dispatch(resethealthRequestQQ(healthObj));
};

export const dispatchResetPetQuickQuote = (petKey, petValue) => {
  let petObj = {
    uniqueId: "",
    quotationNumber: "INSFT000126",
    userDetails: {
      ownerFullName: "", // apply validations (first+middle+last)name
      ownerEmail: "", // apply validations
      ownerNumber: "", // apply validations
      ownerDOB: "",
      ownerGender: "", // male, female & other will be in request,
      ownerAddress1: "",
      ownerAddress2: "",
      ownerAddress3: "",
      pincode: "",
      state: "",
      city: "",
      panNumber: "",
      ownerOccupation: "",
      ownerMaritalStatus: "",
    },
    petDetails: {
      dogBreed: "",
      dogName: "",
      ageOfDog: {
        ageInYears: "",
        ageInMonth: "",
      },
      questionOfDogED: false,
      dogWeight: "",
      gender: "", // male and female only two options will be visible
      identification: "",
      features: "",
      petTagNo: "",
      petValue: "",
      gogreen: false, // Gogreen - Y or N – Do you want to go green
      vaterinary: false, // Veterinary - Y or N  - access to a Veterinary Doctor?
      declInsurance: false, // "DecIns 	Y or N -  Declined insurance of any of your pet dogs? "
      declRenew: false, // "DecRen 	Y or N - Declined to renew the insurance? "
      IncPremium: false, //"IncPrem 	Y or N - increased your premium "
      question1: true, // "Q1Answer 	 Is/are your pet dog(s) sound and healthy? "
      question2: "", // "Q2Answer 	 Full particulars of defects and aliments (If No for Q1Answer)" **this field will have free text field**
      question3: false, //"Q3Answer 	Any Pre-existing disease/conditions Pet Dog(s) suffers? "
      question4: "", //"Q4Answer 	Full particulars of disease and conditions (If Yes for Q3Answer) " **this field will have free text field**
      question5: "", //"Q5Answer 	vaccinations provided( Separated by Coma) " **this field will have free text field**
      question6: false, //"Q6Answer 	used for commercial purpose? "
      question7: "", //"Q7Answer 	Details for commercial purpose (if yes for Q6Answer) " **this field will have free text field**
      question8: false, //"Q8Answer 	Insure Elsewhere? "

      // below two fields will be asked if q8 answer is true
      question9: "", //"Q9Answer 	Policy Number(if Insured for Q8Answer) "
      question10: "", //"Q10Answer 	 Name of Insurance Company (if Insured for Q8Answer) "
      photo1: "",
      photo2: "",
      photo3: "",
      photo4: "",
      photo5: "",
      document: "",
    },

    planDetails: {
      dogSI: "",
      dogCount: 1,
    },
    addons: {
      funeralCost: false, // this will be boolean value
      TerminalDeaseas: false, // this will be boolean value
      vetOncall: {
        planSelected: false, // boolean
        visits: "", // dropdown of 5visits and 10visits
        amountPerVisit: "", // dropdown of 1000 and 2000
      },
      lostAndStolen: false, // boolean
    },
    kycDetails: {
      kycIdType: "", // aadharNum, pan, ckyc, cin, voterId, DrivingLicence, passport
      idNumber: "",
    },
  };
};

export function generateArrayOfYears() {
  var max = new Date().getFullYear();
  var min = max - 18;
  var years = [];

  for (var i = max; i >= min; i--) {
    years.push(i);
  }
  return years;
}

export function generateArrayOfYearsForNew() {
  var max = new Date().getFullYear();
  var min = max - 1;
  var years = [];

  for (var i = max; i >= min; i--) {
    years.push(i);
  }
  return years;
}
