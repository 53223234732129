import React, { useEffect, useState } from "react";
import Header from "../common/Header";
import {
  getVehicleMake,
  getVehicleModel,
  getVehiclePreviousInsurer,
  getVehicleRto,
  getVehicleVariant,
} from "../services/masterServices";
import ReactSelect from "../Tags/ReactSelectProposal";
import {
  dispatchMotorQuote,
  dispatchProcessFlowMotor,
  dispatchQuickQuote,
  dispatchResetHealthQuickQuote,
  dispatchResetProcessFlowHealth,
  dispatchResetProcessFlowMotor,
  dispatchResetQuickQuote,
  dispatchUserData,
  dispatchUserDetails,
  generateArrayOfYears,
  generateArrayOfYearsForNew,
  resetProcessFlowMotor,
  resetQuickQuoteResults,
} from "../../store/action/userActions";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";
import {
  bikePolicyInfoArr,
  fourWheelerModel,
  gcvVehiclePolicyInfoArr,
  isWithinFiveYears,
  isWithinThreeYears,
  pcvVehiclePolicyInfoArr,
  pvtCarPolicyInfoArr,
  twoWheelerMake,
} from "../common/MakeModelArr";
import { useDispatch, useSelector } from "react-redux";
import WhyChooseUs from "../common/WhyChooseUs";
import WhyChoose from "../WhyChoose";
import FastestInsurance from "../common/FastestInsurance";
import WhyUs from "../common/WhyUs";

import Footer from "../common/Footer";
import AboutBike from "../AboutBike";
import { useForm } from "react-hook-form";
import {
  KotakAPICall,
  checkCustomer,
  createCustomer,
  digitAPICall,
  getEmployeeDetails,
  getQuotationNumber,
  processFlow,
  savePolicyToDatabase,
  sompoAPICall,
} from "../services/TPApiCall";
import Layout from "../common/Layout";
import PreviousButton from "../common/PreviousButton";
import AboutCar from "../AboutCar";
import { PostDataWithToken } from "../../api/apiHelper";
import {
  CUSTOMER_TYPE,
  CUSTOMER_TYPE_INDIVIDUAL,
  POLICY_STATUS,
  POLICY_TYPE,
  TOTAL_FUEL_TYPES,
  VEHICLE_TYPE,
  moveToTop,
  toastFailure,
} from "../utility/constants";
import { useCookies } from "react-cookie";
import DatePicker from "react-datepicker";
import ReactInputMask from "react-input-mask";

const MotorProcess = () => {
  const {
    handleSubmit: handleSubmit0,
    watch: watch0,
    register: register0,
    formState: { errors: errors0 },
    clearErrors: clearErrors0,
  } = useForm({ mode: "onBlur" });

  const {
    handleSubmit,
    watch,
    register,
    formState: { errors },
    clearErrors,
    setValue,
  } = useForm({ mode: "onBlur" });

  const {
    handleSubmit: handleSubmit1,
    watch: watch1,
    register: register1,
    formState: { errors: errors1 },
    clearErrors: clearErrors1,
  } = useForm({ mode: "onBlur" });

  // const [rtoData, setRtoData] = useState([]);
  const [cookies, setCookie, removeCookie] = useCookies(["customerLeadId"]);
  const [insurerData, setInsurerData] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const [isNewVehicle, setIsNewVehicle] = useState(false);
  const [makeData, setMakeData] = useState([]);
  const [vehicleModel, setVehicleModel] = useState("");
  const [policyInputs, setPolicyInputs] = useState([]);
  const [quickPick, setQuickPick] = useState([]);
  const [modelData, setModelData] = useState([]);
  const [variantData, setVariantData] = useState([]);
  const [fuelTypes, setFuelTypes] = useState([]);
  const [regYear, setRegYear] = useState("");
  const [maxRegDate, setMaxRegDate] = useState("");
  const [differenceYear, setDifferenceYear] = useState(0);
  const [email, setEmail] = useState(null);
  const [filledRegDate, setFilledRegDate] = useState(false);
  const [mfgYear, setMfgYear] = useState(0);
  const [expiredStatus, setExpiredStatus] = useState({
    status: false,
    date: moment().subtract(90, "day").format(`DD MMM, YYYY`),
  });
  const [employeeData, setEmployeeData] = useState(null);
  const [prevPolicyEndDate, setPrevPolicyEndDate] = useState(false);

  const motorRequest = useSelector((state) => state?.root?.motorRequest);

  const [registrationDate, setRegistrationDate] = useState(
    motorRequest?.isVehicleNew
      ? new Date().toISOString().split("T")[0]
      : motorRequest?.defaultRegistrationDate || ""
  );

  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(window.location.search);
  console.log("query", query);

  const redirectFromBpm = () => {
    let vehicleModalObj = {};
    if (query) {
      let vehicleType = query.get("vehicleType");
      if (vehicleType === "2w") {
        vehicleModalObj.vehicleModal = "MotorBike";
        vehicleModalObj.vehicleType = vehicleType;
      } else {
        vehicleModalObj.vehicleModal = "Pvt Car";
        vehicleModalObj.vehicleType = vehicleType;
      }
    }
    console.log("redirectFromBpm", vehicleModalObj);

    return vehicleModalObj;
  };

  useEffect(() => {
    moveToTop();
  }, [activeStep]);

  const vTypes =
    query.size > 0
      ? redirectFromBpm()
      : location?.state?.journeyContinue
      ? location?.state
      : location?.state?.vTypeForAadrila ||
        location?.state?.updatedVType ||
        location?.state?.selectedVehicleType;
  if (vTypes) {
    var { vehicleModal, vehicleType } = vTypes;
  }

  const getEmployeeDetail = async () => {
    try {
      const empId = query.get("employeeId");
      if (empId) {
        const response = await getEmployeeDetails(empId);
        if (response?.data?.status === "failure") {
          response?.data?.errors.map((error) =>
            toastFailure(error.displayMessage)
          );
        } else {
          const employeeData = response?.data?.employeeData;
          console.log("response", response?.data?.employeeData);
          console.log("response", response);
          setEmployeeData(employeeData);
          for (let key in employeeData) {
            dispatchUserDetails(`${key}`, employeeData[key]);
            if (key === "username")
              dispatchMotorQuote("emplId", employeeData[key]);
            dispatchMotorQuote("source", "empl");
          }
        }
      }
    } catch (error) {
      console.log("getEmployeeDetail Error", error);
      toastFailure("Failed to fetch employee details");
    }
  };

  const apiRequestQQ = useSelector((state) => state.root.apiRequestQQ);
  const userDetails = useSelector((state) => state?.root?.userDetails);
  const userData = useSelector((state) => state?.root?.userData);
  const processFloww = useSelector((state) => state?.root?.processFlowObjMotor);

  const dispatch = useDispatch();

  // let rtoData = location?.state?.rtoData;
  // console.log("rtoData", rtoData);

  // -----------------------------*********------------------------------
  const [isBharatSeries, setIsBharatSeries] = useState(false);
  const [RegistrationNumber, setRegistrationNumner] = useState("");
  const [selectedBox, setSelectedBox] = useState("");
  const [rcName, setRCName] = useState("");
  const [rtoData, setRtoData] = useState([]);

  const processFlowObjMotor = useSelector(
    (state) => state?.root?.processFlowObjMotor
  );

  // -----------------------------*********------------------------------

  // useEffect(() => {
  //   console.log("activeStep", activeStep);
  //   console.log("location", location);
  //   setActiveStep(0);
  // }, [activeStep]);

  useEffect(() => {
    // setRtoData(location?.state?.rtoData);
    // if (motorRequest.registrationNumber) {
    //   setVehicleType();
    //   const startStep = location.state?.activeStep || 1;

    //   if (startStep === 1) {
    //     setActiveStep(startStep + 1);
    //   } else {
    //     setActiveStep(startStep);
    //   }
    // }
    if (query?.size > 0) {
      getEmployeeDetail();
      setActiveStep(0);
    }
    getVehicleRto().then((response) => {
      if (response.status === "success") {
        let data = response.data;
        let i = 0;
        let arr = [];
        while (i < data.length) {
          let item = data[i];
          arr.push({
            label: item.registered_city_name + " (" + item.RTO_Code + ")",
            value: item.RTO_Code,
            registered_state_name: item.registered_state_name,
            id: item.id,
            registered_city_name: item.registered_city_name,
          });
          i++;
        }

        setRtoData(arr);
      }
    });
    if (location?.state?.activeStep === 0) {
      setActiveStep(0);
      setSelectedBox(location?.state?.selectedVehicleType?.vehicleModal);
      console.log("location", location);
    }
    if (location.state?.activeStep === 3) {
      getVehicleModel({
        makeId: motorRequest?.makeId,
        vehicleType: vehicleType,
      }).then((response) => {
        if (response.status === "success") {
          let data = response.data;
          let i = 0;
          let arr = [];
          while (i < data.length) {
            let item = data[i];
            arr.push({
              label: item?.model,
              value: item?.modelId,
              makeId: item?.makeId,
            });
            i++;
          }

          setModelData(arr);
        }
      });
    } else if (location.state?.activeStep === 4) {
      getVehicleVariant({
        makeId: motorRequest?.makeId,
        modelId: motorRequest?.modelId,
        vehicleType: vehicleType,
      }).then((response) => {
        if (response.status === "success") {
          let data = response.data;
          let fuelTypeArr = data.filter(
            (v, i, a) => a.findIndex((v2) => v2.fuelType === v.fuelType) === i
          );
          let j = 0;
          let fuelarr = [];
          while (j < fuelTypeArr.length) {
            fuelarr.push(fuelTypeArr[j].fuelType);
            j++;
          }
          setFuelTypes(fuelarr);
          let i = 0;
          let arr = [];
          while (i < data.length) {
            let item = data[i];
            arr.push({
              make: item?.make,
              makeId: item?.makeId,
              model: item?.model,
              modelId: item?.modelId,
              label: `${item.variant}(${item.cc}cc)`,
              value: item.variantId,
              fuelType: item.fuelType,
              cubicCapacity: item.cc,
              seatingCapacity: item.seats,
            });
            i++;
          }
          setVariantData(arr);

          // if (motorRequest?.vehicleType === "4w") {
          //   handleStepSelection(activeStep + 1);
          // } else {
          //   dispatchMotorQuote("fuelType", fuelarr[0]);
          //   handleStepSelection(activeStep + 2);
          // }
        }
      });
    } else if (location.state?.activeStep === 5) {
      setActiveStep(6);
    } else if (location.state?.activeStep === 8) {
      const maxRegDate = moment(motorRequest?.manufaturingDate)
        .add(731, "days")
        .format("YYYY-MM-DD");
      const year = new Date(maxRegDate).getFullYear();
      const today = new Date().toISOString().split("T")[0];
      if (year === 2025 || year === 2024 || year === 2023) {
        setMaxRegDate(today);
      } else {
        setMaxRegDate(maxRegDate);
      }
    }
  }, []);

  // useEffect(() => {
  //   console.log("userDetails", userDetails);
  //   getQuotationNumber(apiRequestQQ, userDetails);
  //   if (userDetails.CustomerUniqueNumber) {
  //     generateQuotationNumber(apiRequestQQ, userDetails);
  //   }
  // }, []);

  // const generateQuotationNumber = (obj) => {
  //   debugger;
  //   const today = new Date(); // Get today's date
  //   const todayFormatted = `${today.getDate()}_${
  //     today.getMonth() + 1
  //   }_${today.getFullYear()}`; // Format today's date
  //   console.log("todayFormatted", todayFormatted);

  //   if (obj.responseQuotationNumber) {
  //     const currentQuotationNumber = obj.responseQuotationNumber;
  //     if (currentQuotationNumber.startsWith("INFST")) {
  //       const parts = currentQuotationNumber.split("/");
  //       const lastPart = parts[2]; // Extracting the numerical part
  //       const incrementedNumber = parseInt(lastPart) + 1; // Incrementing the numerical part
  //       const newQuotationNumber =
  //         parts[0] +
  //         "/" +
  //         parts[1] +
  //         "/" +
  //         incrementedNumber.toString().padStart(4, "0");
  //       console.log("newQuotationNumber", newQuotationNumber);
  //       dispatchQuickQuote("UniqueId", newQuotationNumber);
  //       return newQuotationNumber;
  //     }
  //   }
  //   console.log("todayFormatted", todayFormatted);
  //   const newQuotationNumber = `INFST/${todayFormatted}/0001`;
  //   console.log("newQuotationNumber", newQuotationNumber);
  //   dispatchQuickQuote("UniqueId", newQuotationNumber);
  //   return newQuotationNumber;
  // };

  useEffect(() => {
    // getVehicleRto().then((response) => {
    //   console.log(response, "response");
    //   if (response.status === "success") {
    //     let data = response?.data;
    //     let i = 0;
    //     let arr = [];
    //     while (i < data?.length) {
    //       let item = data[i];
    //       arr.push({
    //         label: item.registered_city_name + " (" + item.RTO_Code + ")",
    //         value: item.RTO_Code,
    //         registered_state_name: item.registered_state_name,
    //         id: item.id,
    //         registered_city_name: item.registered_city_name,
    //       });
    //       i++;
    //     }
    //     setRtoData(arr);
    //   }
    // });

    getVehiclePreviousInsurer().then((response) => {
      if (response.status === true) {
        let i = 0;
        let data = response.data;
        let arr = [];
        while (i < data.length) {
          let item = data[i];
          arr.push({
            option: item.Name,
            // value: item.Digit_Code,
            value: item?.prevInsurerId,
          });
          i++;
        }
        setInsurerData(arr);
      }
    });
  }, []);

  useEffect(() => {
    if (vehicleModal) {
      setVehicleType();
    }
  }, []);

  // -----------------------------*********------------------------------

  const formatReg = (e) => {
    var t = e;
    t = t.replace(/-/g, "");
    var o = new Array(4);
    (o[0] = t.slice(0, 2)), (o[1] = ""), (o[2] = ""), (o[3] = "");
    try {
      isNaN(t.slice(2, 4))
        ? isNaN(t.slice(3, 4))
          ? (isNaN(t.slice(2, 3)) || (o[1] = t.slice(2, 3)),
            isNaN(t.slice(3, 4)) && (o[2] = t.slice(3, 4)))
          : (o[1] = t.slice(2, 3) + t.slice(3, 4))
        : (o[1] = t.slice(2, 4)),
        isNaN(t.slice(4, 8))
          ? ((o[2] = o[2] + t.slice(4, 5)),
            1 == isNaN(t.slice(5, 6)) && 1 == isNaN(t.slice(6, 7))
              ? (o[2] = o[2] + t.slice(5, 7))
              : (isNaN(t.slice(5, 6))
                  ? (o[2] = o[2] + t.slice(5, 6))
                  : (o[3] = o[3] + t.slice(5, 6)),
                isNaN(t.slice(6, 7)) || (o[3] = o[3] + t.slice(6, 7))),
            isNaN(t.slice(7, 11)) || (o[3] = o[3] + t.slice(7, 11)))
          : (o[3] = o[3] + t.slice(4, 8));
    } catch (e) {}
    return o
      .join("-")
      .replace(/ /g, "")
      .replace(/--/g, "-")
      .replace(/-\s*$/, "")
      .replace(/[^- 0-9 a-z A-z]/g, "");
  };

  const setRegistrationValue = (e) => {
    var message = document.getElementById("message");
    if (isBharatSeries === true) {
      const regex = /^(0[1-9]|[1-9][0-9])\sBH\s\d{4}\s[A-Z]{2}$/;
      if (regex.test(e.target.value)) {
        message.innerHTML = "";
        setRegistrationNumner(e.target.value);
      } else {
        message.innerHTML =
          "The Bharat series number format is XXBH####YY(12BH1234AB)";
      }
    } else {
      if (navigator.userAgent.match(/UCBrowser/)) return !1;
      var t = document.querySelector("#vinpuut").value,
        o = e.keyCode;
      if (
        -1 != navigator.userAgent.indexOf("MSIE") ||
        1 == !!document.documentMode
      ) {
        if (32 == o || 8 == o) return !1;
        t.length >= 3 && (t = formatReg(t)),
          t.length >= 5 && (t = formatReg(t)),
          t.length >= 7 && (t = formatReg(t)),
          (document.querySelector("#vinpuut").value = t.toUpperCase());
      } else
        setTimeout(function () {
          if (32 == o || 8 == o) return !1;
          (t = document.querySelector("#vinpuut").value).length >= 3 &&
            (t = formatReg(t)),
            t.length >= 5 && (t = formatReg(t)),
            t.length >= 7 && (t = formatReg(t)),
            (document.querySelector("#vinpuut").value = t.toUpperCase());
        }, 100);
      setRegistrationNumner(t);
    }
  };

  const submitRegistrationNumber = () => {
    // var message1 = document.getElementById("message");
    // if (0 == validateRegNo()) return;
    // if (RegistrationNumber == "") {
    //   return (message1.innerHTML = "Please enter registration number");
    // }
    // var message = document.getElementById("message1");
    // if (mobileNumber == "") {
    //   return (message.innerHTML = "Please enter mobile number");
    // }
    // if (!mobileNumber.match(/^[6-9]\d{9}$/)) {
    //   return (message.innerHTML = "Invalid mobile number");
    // }
    dispatch(resetQuickQuoteResults());
    dispatch(resetProcessFlowMotor());
    // localStorage.clear();
    getPolicyTenuare();
    let rtocode;
    if (!isBharatSeries) {
      if (RegistrationNumber.includes("-")) {
        rtocode = RegistrationNumber.substring(0, 5)
          .replaceAll("-", "")
          .toUpperCase();
        console.log("rtocode", rtocode);
      } else {
        rtocode = RegistrationNumber.substring(0, 4).toUpperCase();
        console.log("rtocode", rtocode);
      }
      let index = rtoData.findIndex((item) => item.value === rtocode);
      dispatchQuickQuote(
        "RegistrationCity",
        rtoData[index]?.registered_city_name.toUpperCase()
      );
      // dispatchQuickQuote("State", rtoData[index]?.registered_state_name);
      dispatchQuickQuote("IsVehicleNew", false);
      dispatchQuickQuote("RTOCityName", rtocode);
      dispatchMotorQuote(
        "rtoCityName",
        rtoData[index]?.registered_city_name.toUpperCase()
      );
      dispatchMotorQuote("rtoCode", rtocode);
    }
    setIsNewVehicle(false);

    // setValue("rc_no", RegistrationNumber);
    // dispatchQuickQuote("RegistrationNumber", RegistrationNumber.toUpperCase());
    dispatchUserData("RegistrationNumber", RegistrationNumber.toUpperCase());
    // toggleModal();
    // toggleRtoModal()
    dispatchQuickQuote("IsVehicleNew", false);
    // apiRequestQQ.PolicyType === "Renewal" ? toggleTab(activeTab + 2) : toggleTab(activeTab + 1);
  };

  const submitQuoteDetails = (data) => {
    submitRegistrationNumber();
    let registrationNumberWithoutHyphen;
    if (selectedBox !== "health") {
      registrationNumberWithoutHyphen = data?.rc_no.replace(/-/g, "");
    }

    dispatchResetProcessFlowHealth();
    dispatchResetProcessFlowMotor();

    if (selectedBox === "MotorBike" || vTypes.vehicleModal === "MotorBike") {
      dispatchProcessFlowMotor("quoteType", "motor");
      dispatchProcessFlowMotor(
        "loggedInUser",
        userDetails?.Phone && userDetails?.CustomerUniqueNumber ? true : false
      );
      if (userDetails?.Phone && userDetails?.CustomerUniqueNumber) {
        for (let key in userDetails) {
          dispatchProcessFlowMotor(`customerDetails.${key}`, userDetails[key]);
        }
      }
      dispatchQuickQuote("vehicleModal", "MotorBike");
      const newDataa = {
        ...processFlowObjMotor,
        motorRequest: {
          ...motorRequest,
          vehicleType: VEHICLE_TYPE.TWO_WHEELER,
        },
        step: "step1",
        quoteType: "motor",
        processDiscription: "registrationNumber",
        loggedInUser:
          userDetails?.Phone && userDetails?.CustomerUniqueNumber
            ? true
            : false,
        step1: {
          registrationNumber: data?.rc_no,
          vehicleType: VEHICLE_TYPE.TWO_WHEELER,
        },
      };
      processFlow(newDataa).then((response) => {
        console.log("RESPONSE", response);
        if (
          response?.status === "success" &&
          response?.data?.message === "process started!!"
        ) {
          dispatchMotorQuote(
            "customerLeadId",
            response?.data?.data?.customerLeadId
          );
          const now = new Date();
          // Set the cookie to expire in 2 hours
          const expires = new Date(now.getTime() + 2 * 60 * 60 * 1000);
          setCookie("customerLeadId", response?.data?.data?.customerLeadId, {
            expires,
          });
        }
      });
      dispatchMotorQuote("registrationNumber", registrationNumberWithoutHyphen);
      dispatchMotorQuote("vehicleType", VEHICLE_TYPE?.TWO_WHEELER);
      dispatchProcessFlowMotor("step1.registrationNumber", data?.rc_no);
      dispatchProcessFlowMotor("step", "step1");
      dispatchResetHealthQuickQuote();
      setRCName(data?.rc_no);

      setRCName(data?.rc_no);
      if (activeStep === 0) setActiveStep(2);
    } else if (selectedBox === "Pvt Car" || vTypes.vehicleModal === "Pvt Car") {
      dispatchProcessFlowMotor("quoteType", "motor");
      dispatchProcessFlowMotor(
        "loggedInUser",
        userDetails?.Phone && userDetails?.CustomerUniqueNumber ? true : false
      );
      if (userDetails?.Phone && userDetails?.CustomerUniqueNumber) {
        for (let key in userDetails) {
          dispatchProcessFlowMotor(`customerDetails.${key}`, userDetails[key]);
        }
      }

      const newDataa = {
        ...processFlowObjMotor,
        motorRequest: {
          ...motorRequest,
          vehicleType: VEHICLE_TYPE.FOUR_WHEELER,
        },
        step: "step1",
        processDiscription: "registrationNumber",
        quoteType: "motor",
        loggedInUser:
          userDetails?.Phone && userDetails?.CustomerUniqueNumber
            ? true
            : false,
        step1: {
          registrationNumber: data?.rc_no,
          vehicleType: VEHICLE_TYPE.FOUR_WHEELER,
        },
      };
      processFlow(newDataa).then((response) => {
        if (
          response?.status === "success" &&
          response?.data?.message === "process started!!"
        ) {
          dispatchMotorQuote(
            "customerLeadId",
            response?.data?.data?.customerLeadId
          );
          const now = new Date();
          // Set the cookie to expire in 2 hours
          const expires = new Date(now.getTime() + 2 * 60 * 60 * 1000);
          setCookie("customerLeadId", response?.data?.data?.customerLeadId, {
            expires,
          });
        }
      });

      dispatchMotorQuote("registrationNumber", registrationNumberWithoutHyphen);
      dispatchMotorQuote("vehicleType", VEHICLE_TYPE?.FOUR_WHEELER);
      dispatchProcessFlowMotor("step1.registerationNumber", data?.rc_no);
      dispatchProcessFlowMotor("step", "step1");
      dispatchResetHealthQuickQuote();
      setRCName(data?.rc_no);
      if (activeStep === 0) setActiveStep(2);
      // console.log("vTypeForAadrila -", vTypeForAadrila);
      // navigate("/motor", {
      //   state: { vTypeForAadrila, journeyContinue: false },
      // });
      // getRC(newData).then((response) => {
      //   console.log("response getRC", response);
      //   if (
      //     response?.data?.success === false &&
      //     response?.data?.message === "not able to fetch registration number"
      //   ) {
      //     navigate("/motor", { state: vTypeForAadrila });
      //   } else {
      //     setModal(true);
      //   }
      // });

      // if (data.rc_no !== "") setModal(true);
    }
  };

  // -----------------------------*********------------------------------

  const getPolicyTenuare = () => {
    if (isNewVehicle === true && vehicleType == "MotorBike") {
      const startDate = moment().format("YYYY-MM-DD");
      const endDate = moment()
        .add(5, "year")
        .subtract(1, "day")
        .format("YYYY-MM-DD");
      dispatchQuickQuote("PolicyStartDate", startDate);
      dispatchQuickQuote("PolicyEndDate", endDate);
    } else if (isNewVehicle === true && vehicleType == "Pvt Car") {
      const startDate = moment().format("YYYY-MM-DD");
      const endDate = moment()
        .add(3, "year")
        .subtract(1, "day")
        .format("YYYY-MM-DD");
      dispatchQuickQuote("PolicyStartDate", startDate);
      dispatchQuickQuote("PolicyEndDate", endDate);
    } else {
      const startDate = moment().format("YYYY-MM-DD");
      const endDate = moment()
        .add(1, "year")
        .subtract(1, "day")
        .format("YYYY-MM-DD");
      dispatchQuickQuote("PolicyStartDate", startDate);
      dispatchQuickQuote("PolicyEndDate", endDate);
    }
  };

  const handleSelectRtoCity = (val) => {
    dispatchQuickQuote("State", val.registered_state_name);
    getPolicyTenuare();
    dispatchQuickQuote("RtoCode", val.value);
    dispatchMotorQuote("rtoCode", val.value);
    dispatchMotorQuote("rtoCityName", val?.registered_city_name);
    dispatchMotorQuote("businessType", POLICY_STATUS.NEW);
    dispatchQuickQuote(
      "RegistrationCity",
      val.registered_city_name.toUpperCase()
    );
    const newData = {
      ...processFloww,

      step: "step1",
      step1: { rtoCode: val.value, vehicleType: motorRequest?.vehicleType },
    };

    processFlow(newData).then((response) => {
      dispatchMotorQuote(
        "customerLeadId",
        response?.data?.data?.customerLeadId
      );
    });
    handleStepSelection(activeStep + 1);
  };

  const handleStepSelection = (step) => {
    setActiveStep(step);
  };

  const handlePreviousStep = () => {
    if (
      motorRequest?.vehicleType === VEHICLE_TYPE?.TWO_WHEELER &&
      activeStep === 5 &&
      fuelTypes.length < 2
    ) {
      setActiveStep(activeStep - 2);
    } else if (!motorRequest?.isVehicleNew && activeStep === 2) {
      return;
    } else {
      setActiveStep(activeStep - 1);
    }

    // if (
    //   motorRequest?.vehicleType === VEHICLE_TYPE?.TWO_WHEELER &&
    //   activeStep === 5
    // ) {
    //   setActiveStep(activeStep - 2);
    // } else {
    //   setActiveStep(activeStep - 1);
    // }
  };

  const setVehicleType = () => {
    // vehicleModal === "Pvt Car"
    //   ? setPolicyInputs(pvtCarPolicyInfoArr)
    //   : vehicleModal === "MotorBike"
    //   ? setPolicyInputs(bikePolicyInfoArr)
    //   : vehicleModal === "Goods Carrying"
    //   ? setPolicyInputs(gcvVehiclePolicyInfoArr)
    //   : vehicleModal === "Passenger Carrying" && setPolicyInputs(pcvVehiclePolicyInfoArr);
    setVehicleModel(vehicleModal);

    let arr = [];

    getVehicleMake({ vehicleType: vehicleType }).then((response) => {
      if (response.status === "success") {
        let data = response.data;
        let i = 0;
        while (i < data.length) {
          let item = data[i];
          arr.push({
            label: item.make,
            value: item.makeId,
          });
          i++;
        }
        setMakeData(arr);
        vehicleModal == "Passenger Carrying"
          ? setQuickPick(arr.slice(0, 12))
          : vehicleModal == "Goods Carrying" && setQuickPick(arr.slice(0, 12));
      }
    });
    dispatchQuickQuote("VehicleType", vehicleType);
    dispatchMotorQuote("vehicleType", vehicleType);
    dispatchUserData("VehicleType", vehicleType);
    vehicleType === "2w"
      ? setQuickPick(twoWheelerMake)
      : vehicleType === "4w" && setQuickPick(fourWheelerModel);
  };

  const handleSelectMake = (make) => {
    dispatchQuickQuote("MakeName", make?.label);
    dispatchMotorQuote("makeName", make?.label);
    dispatchMotorQuote("makeId", make?.value);
    dispatchProcessFlowMotor("step2.make", make?.label);
    dispatchProcessFlowMotor("step", "step2");
    dispatchQuickQuote("ModelName", "");
    dispatchQuickQuote("VariantName", "");
    getVehicleModel({
      makeId: make?.value,
      vehicleType: motorRequest?.vehicleType,
    }).then((response) => {
      if (response.status === "success") {
        handleStepSelection(activeStep + 1);
        let data = response.data;
        let i = 0;
        let arr = [];
        while (i < data.length) {
          let item = data[i];
          arr.push({
            label: item?.model,
            value: item?.modelId,
            makeId: item?.makeId,
          });
          i++;
        }
        setModelData(arr);
      }
    });

    const newData = {
      ...processFloww,
      motorRequest: { ...motorRequest, makeName: make },
      customerLeadId: motorRequest?.customerLeadId,
      step: "step2",
      processDiscription: "makeSelection",
      step2: { ...processFloww.step2, make: make },
    };

    processFlow(newData).then((response) => {
      console.log("RESPONSE", response);
    });
  };

  const handleSelectModel = (model) => {
    dispatchQuickQuote("ModelName", model?.label);
    dispatchMotorQuote("modelName", model?.label);
    dispatchMotorQuote("modelId", model?.value);
    dispatchProcessFlowMotor("step3.model", model?.label);
    dispatchProcessFlowMotor("step", "step3");

    dispatchQuickQuote("VariantName", "");
    getVehicleVariant({
      makeId: model?.makeId,
      modelId: model?.value,
      vehicleType: motorRequest?.vehicleType,
    }).then((response) => {
      if (response.status === "success") {
        let data = response.data;
        let fuelTypeArr = data.filter(
          (v, i, a) => a.findIndex((v2) => v2.fuelType === v.fuelType) === i
        );
        let j = 0;
        let fuelarr = [];
        while (j < fuelTypeArr.length) {
          fuelarr.push(fuelTypeArr[j].fuelType);
          j++;
        }
        console.log("fuelTypes", fuelarr, fuelarr.length);
        setFuelTypes(fuelarr);
        let i = 0;
        let arr = [];
        while (i < data.length) {
          let item = data[i];
          arr.push({
            make: item?.make,
            makeId: item?.makeId,
            model: item?.model,
            modelId: item?.modelId,
            label: `${item.variant}(${item.cc}cc)`,
            value: item.variantId,
            fuelType: item.fuelType,
            cubicCapacity: item.cc,
            seatingCapacity: item.seats,
          });
          i++;
        }
        setVariantData(arr);

        if (fuelarr.length > 1) {
          handleStepSelection(activeStep + 1);
        } else {
          dispatchMotorQuote("fuelType", fuelarr[0]);
          handleStepSelection(activeStep + 2);
        }

        // if (motorRequest?.vehicleType === "4w") {
        //   handleStepSelection(activeStep + 1);
        // } else {
        //   if (fuelarr.length > 1) {
        //     handleStepSelection(activeStep + 1);
        //   } else {
        //     dispatchMotorQuote("fuelType", fuelarr[0]);
        //     handleStepSelection(activeStep + 2);
        //   }
        // }
      }
    });

    const newData = {
      ...processFloww,
      motorRequest: { ...motorRequest, modelName: model },
      customerLeadId: motorRequest?.customerLeadId,
      step: "step3",
      processDiscription: "modelSelection",
      step3: { ...processFloww.step3, model: model },
    };
    if (!cookies.customerLeadId) {
      const now = new Date();
      // Set the cookie to expire in 2 hours
      const expires = new Date(now.getTime() + 2 * 60 * 60 * 1000);
      setCookie("customerLeadId", motorRequest?.customerLeadId, { expires });
    }
    processFlow(newData).then((response) => {
      console.log("RESPONSE", response);
    });
  };

  const handleSelectPetrolType = (val) => {
    if (val == "CNG" || val == "LPG") {
      dispatchQuickQuote("IsInternalCNG", true);
    }
    dispatchQuickQuote("FuelType", val);
    dispatchMotorQuote("fuelType", val);
    dispatchUserData("FuelType", val);
    handleStepSelection(activeStep + 1);
  };

  const handleSelectVariant = (variant) => {
    dispatchQuickQuote("VariantCode.Digit", variant.value);
    dispatchMotorQuote("variantName", variant.label);
    dispatchMotorQuote("variantId", variant.value);

    dispatchQuickQuote("FuelType", variant.fuelType);
    dispatchQuickQuote("VariantName", variant.label);
    dispatchQuickQuote("CarryingCapacity", variant.Seating_Capacity);
    dispatchQuickQuote("CubicCapacity", variant.Cubic_Capacity);
    dispatchProcessFlowMotor("step4.variantSelection", variant);
    dispatchProcessFlowMotor("step", "step4");
    const newData = {
      ...processFloww,
      motorRequest: { ...motorRequest },
      customerLeadId: motorRequest?.customerLeadId,
      step: "step4",
      processDiscription: "variantSelection",
      step4: { ...processFloww.step4, variantSelection: variant },
    };

    if (!cookies.customerLeadId) {
      const now = new Date();
      // Set the cookie to expire in 2 hours
      const expires = new Date(now.getTime() + 2 * 60 * 60 * 1000);
      setCookie("customerLeadId", motorRequest?.customerLeadId, { expires });
    }
    processFlow(newData).then((response) => {
      console.log("RESPONSE", response);
    });
    handleStepSelection(activeStep + 1);
  };

  function getDefaultRegDate(year) {
    const today = new Date();
    return new Date(year, today.getMonth(), today.getDate());
  }

  const selectVehicleYear = (year) => {
    setRegYear(year);
    let today = new Date();
    today.setFullYear(year);
    let month = today.getMonth();
    console.log("today", today, month);
    let mfgDate = new Date(year, month, 1);
    mfgDate = moment(mfgDate).format("YYYY-MM-DD");

    const REGDATE = moment(mfgDate).add(731, "days").format("YYYY-MM-DD");
    if (year === 2024 || year === 2023) {
      const today = new Date().toISOString().split("T")[0];

      setMaxRegDate(today);
    } else {
      setMaxRegDate(REGDATE);
    }
    let defaultRegistrationDate = getDefaultRegDate(year);
    defaultRegistrationDate = moment(defaultRegistrationDate).format(
      "YYYY-MM-DD"
    );
    dispatchMotorQuote("defaultRegistrationDate", defaultRegistrationDate);
    console.log("defaultRegistrationDate", defaultRegistrationDate);
    setRegistrationDate(defaultRegistrationDate);
    dispatchQuickQuote("ManufaturingDate", mfgDate);
    dispatchMotorQuote("manufaturingDate", mfgDate);
    dispatchMotorQuote("registrationDate", "");
    handleStepSelection(activeStep + 1);
    dispatchQuickQuote("CustomerType", "INDIVIDUAL");
    dispatchMotorQuote("customerType", CUSTOMER_TYPE?.INDIVIDUAL);
    dispatchQuickQuote("PreviousOwnerChanged", false);
    setFilledRegDate(false);
  };

  const setPolicyStatus = (status) => {
    if (status == "false") {
      dispatchQuickQuote("IsPreviousInsurerKnown", false);
    } else {
      dispatchQuickQuote("PolicyStatus", status);
    }
  };

  const submitUserDetails = async (data) => {
    console.log(
      "min DATE",
      motorRequest.manufaturingDate,
      moment(motorRequest?.manufaturingDate)
        .startOf("year")
        .format("DD/MM/YYYY")
    );
    dispatchMotorQuote("journeyFromStart", true);
    let newData = {
      FirstName: "",
      MiddleName: "",
      LastName: "",
      Email: data.Email,
      MobileNumber: data.MobileNumber,
    };
    let newDataStructure = {
      firstName: "",
      middleName: "",
      lastName: "",
      email: data.Email,
      mobileNumber: data.MobileNumber,
    };
    const nameParts = data.FullName.split(" ");

    if (nameParts.length > 0) {
      newData.FirstName = nameParts[0];
    }

    if (nameParts.length > 2) {
      newData.MiddleName = nameParts[1];
    }

    if (nameParts.length > 1) {
      newData.LastName = nameParts[nameParts.length - 1];
    }

    // New Structure

    const newNameParts = data?.FullName.split(" ");

    if (newNameParts.length === 1) {
      // Only one part, treat it as first name
      newDataStructure.firstName = newNameParts[0];
    } else if (newNameParts.length === 2) {
      // Two parts, treat them as first and last names
      newDataStructure.firstName = newNameParts[0];
      newDataStructure.lastName = newNameParts[1];
    } else {
      // More than two parts
      newDataStructure.firstName = newNameParts[0];
      newDataStructure.lastName = newNameParts[newNameParts.length - 1];
      newDataStructure.middleName = newNameParts.slice(1, -1).join(" ");
    }

    for (let key in newData) {
      dispatchQuickQuote(key, newData[key]);
    }

    for (let key in newDataStructure) {
      dispatchMotorQuote(key, newDataStructure[key]);
    }

    PostDataWithToken("admin/checkCustomer", {
      phoneNumber: data?.MobileNumber,
    }).then((response) => {
      if (
        response?.data?.success === false &&
        response?.data?.message === "Customer Does not exist with this Number!!"
      ) {
        const min = 1000000000000;
        const max = 9999999999999;
        const UniqueId = Math.floor(Math.random() * (max - min + 1) + min);

        const newUserData = {
          name: data?.FullName,
          emailId: data?.Email,
          phoneNumber: data?.MobileNumber,
          customerUniqueNumber: UniqueId,
        };
        PostDataWithToken("admin/add_customer", newUserData).then(
          (response) => {
            dispatchProcessFlowMotor("step", "step5");
            dispatchProcessFlowMotor("step5.customerDetails", response?.data);
            dispatchProcessFlowMotor(
              "step5.manufaturingDate",
              motorRequest?.manufaturingDate
            );
            dispatchProcessFlowMotor(
              "step5.customerType",
              motorRequest?.customerType
            );
            dispatchProcessFlowMotor(
              "step5.isOwnerChanged",
              motorRequest?.isOwnerChanged
            );
            const newData = {
              ...processFloww,
              motorRequest: { ...motorRequest },
              customerLeadId: motorRequest?.customerLeadId,
              step: "step5",
              processDiscription: "customerDetails",
              step5: {
                ...processFloww.step5,
                customerDetails: response?.data,
                manufaturingDate: motorRequest?.manufaturingDate,
                customerType: motorRequest?.customerType,
                isOwnerChanged: motorRequest?.isOwnerChanged,
              },
            };

            if (!cookies.customerLeadId) {
              const now = new Date();
              // Set the cookie to expire in 2 hours
              const expires = new Date(now.getTime() + 2 * 60 * 60 * 1000);
              setCookie("customerLeadId", motorRequest?.customerLeadId, {
                expires,
              });
            }
            processFlow(newData).then((response) => {
              console.log("RESPONSE", response);
            });
          }
        );
      } else if (
        response?.success === true &&
        response?.message === "Customer already Exists!!"
      ) {
        dispatchProcessFlowMotor("step", "step5");
        dispatchProcessFlowMotor("step5.customerDetails", response?.data);
        dispatchProcessFlowMotor(
          "step5.manufaturingDate",
          motorRequest?.manufaturingDate
        );
        dispatchProcessFlowMotor(
          "step5.customerType",
          motorRequest?.customerType
        );
        dispatchProcessFlowMotor(
          "step5.isOwnerChanged",
          motorRequest?.isOwnerChanged
        );
        const newData = {
          ...processFloww,
          motorRequest: { ...motorRequest },
          customerLeadId: motorRequest?.customerLeadId,
          step: "step5",
          processDiscription: "customerDetails",

          step5: {
            ...processFloww.step5,
            customerDetails: response?.data,
            manufaturingDate: motorRequest?.manufaturingDate,
            customerType: motorRequest?.customerType,
            isOwnerChanged: motorRequest?.isOwnerChanged,
          },
        };

        if (!cookies.customerLeadId) {
          const now = new Date();
          // Set the cookie to expire in 2 hours
          const expires = new Date(now.getTime() + 2 * 60 * 60 * 1000);
          setCookie("customerLeadId", motorRequest?.customerLeadId, {
            expires,
          });
        }
        processFlow(newData).then((response) => {
          console.log("RESPONSE", response);
        });
      }
    });
    dispatch(resetQuickQuoteResults());
    localStorage.removeItem("filter");
    setFilledRegDate(false);
    // createCustomer("CREATE CUSTOMER POST DATA", postData);
    submitPolicyDetails();
  };

  const submitPolicyDetails = (data) => {
    let newData = { ...apiRequestQQ };
    let regDate =
      apiRequestQQ.IsVehicleNew == false
        ? //  moment()
          //     .add(1, 'day')
          //     .format(`${apiRequestQQ.RegistrationYear}-MM-DD`)
          moment()
            .add(1, "day")
            .format(`${apiRequestQQ.RegistrationYear}-MM-DD`)
        : moment().format(`${apiRequestQQ.RegistrationYear}-MM-DD`);
    dispatchQuickQuote("RegistrationDate", regDate);

    let prvPolEndDate =
      apiRequestQQ.IsVehicleNew == false &&
      apiRequestQQ.PolicyStatus === "continue"
        ? moment().add(5, "day").format(`YYYY-MM-DD`)
        : apiRequestQQ.PolicyStatus === "expired within 90 day"
        ? moment().subtract(90, "day").format(`YYYY-MM-DD`)
        : apiRequestQQ.PolicyStatus === "expired above 90 day"
        ? moment().subtract(180, "day").format(`YYYY-MM-DD`)
        : "";

    // dispatchQuickQuote(
    //   "ManufaturingDate",
    //   moment().subtract(2, "months").format(`${regYear}-MM-DD`)
    // );
    dispatchQuickQuote(
      "PurchaseDate",
      moment().subtract(3, "days").format(`${regYear}-MM-DD`)
    );

    let newPolicyType = getNewPolicyType(regDate);
    newData.NewPolicyType = newPolicyType;
    newData.PreviousPolicyType =
      newPolicyType == "ODOnly" ? "Bundled" : "Comprehensive";
    newData.PrePolicyEndDate = prvPolEndDate;
    dispatchQuickQuote("NewPolicyType", newPolicyType);
    dispatchQuickQuote(
      "PreviousPolicyType",
      newPolicyType == "ODOnly" ? "Bundled" : "Comprehensive"
    );
    dispatchQuickQuote("PrePolicyEndDate", prvPolEndDate);
    if (
      checkIfDateIsIn3Month(prvPolEndDate) &&
      apiRequestQQ.IsVehicleNew === false
    ) {
      const startDate = moment(prvPolEndDate)
        .add(1, "day")
        .format("YYYY-MM-DD");
      const endDate = moment(startDate, "YYYY-MM-DD")
        .add(1, "year")
        .subtract(1, "day")
        .format("YYYY-MM-DD");
      dispatchQuickQuote("PolicyStartDate", startDate);
      dispatchQuickQuote("PolicyEndDate", endDate);
      newData.PolicyStartDate = startDate;
      newData.PolicyEndDate = endDate;
    }
    for (let key in data) {
      if (Object.keys(apiRequestQQ).includes(key)) {
        if (key === "PolicyStatus" && data.PolicyStatus == "false") {
          dispatchQuickQuote("IsPreviousInsurerKnown", false);
          dispatchQuickQuote("PolicyStatus", "continue");
        }
        newData[key] = data[key];
        dispatchQuickQuote(key, data[key]);
      }
    }
    handleStepSelection(8);
    // navigate("/quotation");

    // savePolicyToDatabase(newData);
    setTimeout(() => {}, 2000);
  };

  const getNewPolicyType = (regDate) => {
    if (apiRequestQQ.VehicleType == "2w") {
      if (isWithinFiveYears(regDate) == true) {
        return "ODOnly";
      } else if (apiRequestQQ.VehicleType == "4w") {
        if (isWithinThreeYears(regDate) == true) {
          return "ODOnly";
        }
      }
    } else {
      return "Comprehensive";
    }
  };

  const submitPrevInsurernNCB = (data) => {
    if (userDetails?.name) {
      dispatchMotorQuote("source", "empl");
      dispatchMotorQuote("emplId", userDetails?.username);
    }
    const customerUniqueNumber =
      processFloww?.step5?.customerDetails?.customer_unique_number;
    dispatchMotorQuote("customerUniqueNumber", customerUniqueNumber);

    dispatchQuickQuote("initialIdvSet", false);
    dispatchQuickQuote("RegistrationDate", data?.registrationDate);
    dispatchProcessFlowMotor("step6.pypSelection", data);
    dispatchProcessFlowMotor("step", "step6");

    for (let key in data) {
      if (key === "insurerCode") {
        dispatchMotorQuote(`${key}`, parseInt(data[key]));
      } else if (key === "registrationDate") {
        dispatchMotorQuote(`${key}`, data[key]);
        let manufactureDate = new Date(data[key]);

        let year = manufactureDate.getFullYear();
        let month = manufactureDate.getMonth();

        manufactureDate = new Date(year, month, 1);
        manufactureDate = moment(manufactureDate).format("YYYY-MM-DD");
        dispatchMotorQuote("manufaturingDate", manufactureDate);
      } else {
        dispatchMotorQuote(`${key}`, data[key]);
      }
    }

    // let newStructureData = {
    //   newPolicyType:
    //     data?.NewPolicyType === "Comprehensive"
    //       ? POLICY_TYPE?.COMPREHENSIVE
    //       : data?.NewPolicyType === "ThirdParty"
    //       ? POLICY_TYPE?.THIRDPARTY
    //       : data?.NewPolicyType === "ODOnly"
    //       ? POLICY_TYPE?.ODONLY
    //       : data?.NewPolicyType === "Bundled"
    //       ? POLICY_TYPE?.COMPREHENSIVE
    //       : null,
    //   businessType: motorRequest?.isOwnerChanged
    //     ? "used"
    //     : data?.PolicyStatus === "continue"
    //     ? POLICY_STATUS?.NOTEXPIRED
    //     : data?.PolicyStatus === "expired within 90 day"
    //     ? POLICY_STATUS?.EXPIREDWITHIN90
    //     : POLICY_STATUS?.EXPIREDBEFORE90,
    //   prevPolicyEndDate: data?.PrePolicyEndDate,
    //   prevPolicyType:
    //     data?.PreviousPolicyType === "Comprehensive"
    //       ? POLICY_TYPE?.COMPREHENSIVE
    //       : data?.PreviousPolicyType === "ThirdParty"
    //       ? POLICY_TYPE?.THIRDPARTY
    //       : data?.PreviousPolicyType === "ODOnly"
    //       ? POLICY_TYPE?.ODONLY
    //       : data?.PreviousPolicyType === "Bundled"
    //       ? POLICY_TYPE?.COMPREHENSIVE
    //       : null,
    //   insurerCode:
    //     insurerData.find((insurer) => insurer?.value === data?.PreInsurerCode)
    //       ?.prevInsurerId || "",
    //   registrationDate: data?.RegistrationDate,
    // };
    // if (motorRequest?.isVehicleNew) {
    //   newStructureData = { ...newStructureData, businessType: "new" };
    //   dispatchMotorQuote("businessType", "new");
    // }

    // for (let key in newStructureData) {
    //   dispatchMotorQuote(`${key}`, newStructureData[key]);
    // }

    const postData = {
      ...processFloww,
      motorRequest: { ...motorRequest },
      customerLeadId: motorRequest?.customerLeadId,
      step: "step6",
      processDiscription: "pypSelection",
      step6: { ...processFloww.step6, pypSelection: data },
    };
    if (!cookies.customerLeadId) {
      const now = new Date();
      // Set the cookie to expire in 2 hours
      const expires = new Date(now.getTime() + 2 * 60 * 60 * 1000);
      setCookie("customerLeadId", motorRequest?.customerLeadId, { expires });
    }
    processFlow(postData);
    if (
      motorRequest?.isPreviousInsuranceClaimed === true ||
      motorRequest?.isOwnerChanged === true
    ) {
      dispatchMotorQuote("prevNoClaimBonus", 0);
    }

    navigate(`/quotelist/${motorRequest?.customerLeadId}`);
    // }
  };

  function handleCheckboxChange(name) {
    const errorMessage = document.getElementById(`error-${name}`);
    if (errorMessage) {
      errorMessage.style.display = "none"; // Hide the error message
      // Alternatively, you can use errorMessage.style.visibility = 'hidden'; to hide without affecting layout
      // Or errorMessage.disabled = true; to disable the error message input
    }
  }

  const handleChangeEmail = (event) => {
    let inputValue = event.target.value;
    let domainIndex = inputValue.lastIndexOf(".");
    if (domainIndex !== -1) {
      let domain = inputValue.slice(domainIndex);
      if (domain.includes(".com")) {
        // debugger;
        // domain = domain.slice(0, 4);
        return domain;
      } else if (domain.includes(".in")) {
        domain = domain.slice(0, 3);

        return domain;
      } else {
        domain = domain.replace(/[^ci]/g, "");

        return domain;
      }
    }
  };

  const handleRegistrationDate = (val) => {
    // let val = moment(date).format("YYYY-MM-DD");

    let today = new Date();
    setRegistrationDate(val);
    // Adding 60 days to today's date
    today.setDate(today.getDate() + 60);

    let inputValue = new Date(val);
    let regYear = inputValue.getFullYear();
    dispatchQuickQuote("RegistrationYear", regYear);

    let difference = today - inputValue;

    let differenceInYears = (
      difference /
      (1000 * 60 * 60 * 24 * 365.25)
    ).toFixed(4);

    setDifferenceYear(differenceInYears);

    setFilledRegDate(true);
  };

  const handleInputRegistrationDateChange = (event) => {
    const inputValue = event.target.value;

    // Validate the input date format (DD/MM/YYYY)
    if (/^\d{2}\/\d{2}\/\d{4}$/.test(inputValue)) {
      const [day, month, year] = inputValue.split("/");
      const date = new Date(`${year}-${month}-${day}`);

      if (!isNaN(date.getTime())) {
        const formattedDate = moment(date).format("YYYY-MM-DD");
        setRegistrationDate(date);
        clearErrors("registrationDate");
        setValue("registrationDate", formattedDate); // Update form value
        handleRegistrationDate(formattedDate);
      }
    } else {
      setRegistrationDate(null);
      clearErrors("registrationDate");
      setValue("registrationDate", ""); // Clear value if invalid
      handleRegistrationDate("");
    }
  };

  // Function to handle date change when selected via DatePicker
  const handleRegistrationDateChange = (date) => {
    if (date) {
      const formattedDate = moment(date).format("YYYY-MM-DD");
      setRegistrationDate(date);
      clearErrors("registrationDate");
      setValue("registrationDate", formattedDate);
      handleRegistrationDate(formattedDate);
    } else {
      setRegistrationDate(null);
      clearErrors("registrationDate");
      setValue("registrationDate", "");
      handleRegistrationDate("");
    }
  };

  // handle registration data for disabled policy type according to manufacturing date
  useEffect(() => {
    if (activeStep === 8) {
      if (motorRequest?.isVehicleNew) {
        setValue("newPolicyType", POLICY_TYPE.BUNDLED);
      }
    }
    console.log("registrationDAte", registrationDate);
    if (motorRequest?.defaultRegistrationDate) {
      clearErrors("registrationDate");
      setValue("registrationDate", motorRequest?.defaultRegistrationDate);
    }
  }, [activeStep === 8]);

  useEffect(() => {
    if (activeStep === 8 && !filledRegDate) {
      if (motorRequest?.registrationDate) {
        handleRegistrationDate(motorRequest?.registrationDate);
      } else {
        handleRegistrationDate(registrationDate);
      }
    }
  }, [watch("registrationDate"), watch("manufaturingDate"), activeStep === 8]);

  // const handlePrevPolicyEndDate = (date) => {
  //   const val = moment(date).format("YYYY-MM-DD");
  //   setPrevPolicyEndDate(val);
  //   setValue("prevPolicyEndDate", val);
  //   clearErrors("prevPolicyEndDate");
  // };

  const handleInputChange = (event) => {
    const inputValue = event.target.value;

    // Validate the input date format (DD/MM/YYYY)
    if (/^\d{2}\/\d{2}\/\d{4}$/.test(inputValue)) {
      const [day, month, year] = inputValue.split("/");
      const date = new Date(`${year}-${month}-${day}`);
      console.log("handleInputChange", date);
      if (!isNaN(date.getTime())) {
        setPrevPolicyEndDate(date);
        setValue("prevPolicyEndDate", date); // Update form value
      }
    } else {
      setPrevPolicyEndDate(null);
      setValue("prevPolicyEndDate", ""); // Clear value if invalid
    }
  };

  // Function to handle date change when selected via DatePicker
  const handlePrevPolicyEndDate = (val) => {
    const date = moment(val).format("YYYY-MM-DD");
    console.log("handleDateChange", date);
    if (date) {
      setPrevPolicyEndDate(date);
      setValue("prevPolicyEndDate", date);
    } else {
      setPrevPolicyEndDate(null);
      setValue("prevPolicyEndDate", "");
    }
  };

  return (
    <Layout>
      <div className="container">
        <div className="register-bike">
          {activeStep === 0 && (
            <div class="bike-brand-name">
              <div class="company-name-heading">
                <h1
                  className={
                    motorRequest?.vehicleType === VEHICLE_TYPE.FOUR_WHEELER
                      ? ""
                      : "mt-5"
                  }
                >
                  Upto
                  {`${
                    motorRequest?.vehicleType === VEHICLE_TYPE.FOUR_WHEELER
                      ? " 85% "
                      : " 80% "
                  }`}
                  Off on{" "}
                  {`${
                    motorRequest?.vehicleType === VEHICLE_TYPE.FOUR_WHEELER
                      ? "Car "
                      : "Bike "
                  }`}
                  Insurance. Renew in 3 minutes
                </h1>
              </div>
              {/* <h2 className="mb-3">Fill Out the Required Details</h2> */}
              <form
                className="regi_num"
                onSubmit={handleSubmit0(submitQuoteDetails)}
              >
                <div className="mt-3">
                  {/* <div className="mb-4">
                    <input type="checkbox" id="bhSeries" className="mx-2" />
                    <label htmlFor="bhSeries">Bharat Series Number</label>
                  </div> */}
                  <div className="did-floating-label-content">
                    <input
                      type="text"
                      className="did-floating-input"
                      id="vinpuut"
                      {...register0("rc_no", {
                        required: "Field is required",
                        minLength: {
                          value: 10,
                          message: "Enter a valid Number",
                        },

                        // pattern: {
                        //   value:
                        //     /^[A-Z]{2}[0-9]{2}[A-HJ-NP-Z]{1,2}[0-9]{4}$|^[0-9]{2}BH[0-9]{4}[A-HJ-NP-Z]{1,2}$/,
                        //   message: "Invalid Registration Number",
                        // },
                      })}
                      style={{ textTransform: "uppercase" }}
                      onChange={(e) => {
                        setRegistrationValue(e);
                        clearErrors("rc_no");
                      }}
                      placeholder=""
                    />
                    <label htmlFor="idxx4" className="did-floating-label">
                      Enter{" "}
                      {`${
                        motorRequest?.vehicleType === VEHICLE_TYPE.FOUR_WHEELER
                          ? "Car"
                          : "Bike"
                      }`}{" "}
                      Number
                    </label>
                    {errors?.rc_no?.message && (
                      <span className="text-danger">
                        {errors0?.rc_no?.message}
                      </span>
                    )}
                  </div>
                  <div className="d-flex justify-content-between mb-3 buy-new">
                    <p
                      onClick={() => {
                        setActiveStep(1);
                        dispatchMotorQuote("isVehicleNew", true);
                      }}
                      className="motorButton2 rounded"
                    >
                      Buying a new{" "}
                      {`${
                        motorRequest?.vehicleType === VEHICLE_TYPE.FOUR_WHEELER
                          ? "Car"
                          : "Bike"
                      }`}
                      ?
                    </p>
                    {/* <p
                      onClick={() => setActiveStep(1)}
                      className="motorButton2 rounded text-end"
                    >
                      Don't Know{" "}
                      {`${
                        motorRequest?.vehicleType === VEHICLE_TYPE.FOUR_WHEELER
                          ? "Car"
                          : "Bike"
                      }`}{" "}
                      Number?
                    </p> */}
                  </div>
                </div>

                <div className="d-flex final-step-inner-2-button final-step-inner-2-button-center gap-4">
                  {/* <PreviousButton onClick={handlePreviousStep} /> */}
                  <div class="rto-sub final-step-inner">
                    <button type="submit" className="btn-primary">
                      Free Quote
                    </button>
                  </div>
                </div>
              </form>
            </div>
          )}
          {activeStep === 1 && (
            <div className="bike-brand-name">
              <div class="company-name-heading">
                <h1>
                  Where is your{" "}
                  {vTypes?.vehicleModal === "MotorBike" ? "Bike" : "Car"}{" "}
                  Registered?
                </h1>
              </div>
              <div className="bike-rto">
                <div className="search-brand-name">
                  <div>
                    <ReactSelect
                      options={rtoData}
                      name="rto"
                      placeholder="Select RTO Location"
                      onChange={(val) => handleSelectRtoCity(val)}
                    />
                  </div>
                </div>

                <div className="brand-logo d-flex flex-column">
                  <ul className="brand-logo-list">
                    {rtoData &&
                      rtoData.length > 0 &&
                      rtoData
                        .filter(
                          (item) => item.registered_state_name === "RAJASTHAN"
                        )
                        .slice(0, 12)
                        .map((item, index) => (
                          <>
                            <li
                              className="brand-logo-1 d-flex justify-content-center align-items-center"
                              id={"rto" + item.id}
                              name="regcitycode"
                              onClick={() => handleSelectRtoCity(item)}
                            >
                              <label
                                htmlFor={"rto" + item.id}
                                className="illnessbox"
                              >
                                {item.value}
                              </label>
                            </li>
                          </>
                        ))}
                  </ul>
                </div>

                {/* <div className="more-rto">
              <select name="" id="">
                <option value="">View More</option>
              </select>
            </div> */}
                <div className="d-flex final-step-inner-2-button justify-content-center">
                  <div className="rto-sub">
                    <button type="button" className="btn-primary">
                      Continue
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
          {activeStep === 2 && (
            <div class="bike-brand-name">
              <div class="company-name-heading">
                <h1>Select the Wheeler Manufacture</h1>
              </div>
              <div class="search-brand-name">
                <ReactSelect
                  options={makeData}
                  name="make"
                  placeholder="Select Manufacturer"
                  onChange={(val) => handleSelectMake(val)}
                />
              </div>
              <div class="brand-logo d-flex flex-column">
                <h3 className="manufacture">Popular Manufacturer</h3>
                <ul className="brand-logo-list">
                  {vehicleType == "2w" ||
                  vehicleType == "4w" ||
                  vehicleType == "pcv"
                    ? quickPick.length > 0 &&
                      quickPick.map((item, i) => (
                        <li
                          value={item.value}
                          className="brand-logo-1 d-flex justify-content-center align-items-center"
                          id={"idx" + i}
                          name={"make"}
                          onClick={() => handleSelectMake(item)}
                        >
                          <div>
                            <img
                              src={item.image}
                              className="img-fluid "
                              style={{ width: "3rem", height: "3rem" }}
                              alt=""
                            />
                            {/* <i className="fas fa-check-circle text-success" /> */}
                          </div>
                        </li>
                      ))
                    : makeData &&
                      makeData.length > 0 &&
                      makeData.slice(0, 12).map((item, index) => (
                        <li
                          className="did-floating-input"
                          id={"make11" + index}
                          name="carComany"
                        >
                          <button
                            onClick={() => handleSelectMake(item)}
                            htmlFor={"make11" + index}
                          >
                            {item.value}
                          </button>
                        </li>
                      ))}
                </ul>
              </div>

              <div className="d-flex final-step-inner-2-button gap-4">
                <PreviousButton onClick={handlePreviousStep} />
                <div class="rto-sub">
                  <button type="button" className="btn-primary">
                    Continue
                  </button>
                </div>
              </div>
            </div>
          )}
          {activeStep === 3 && (
            <div class="bike-brand-name">
              <div class="company-name-heading">
                {vehicleType == "4w" ? (
                  <h1>Select Four Wheeler Model</h1>
                ) : vehicleType == "2w" ? (
                  <h1>Select Two Wheeler Model</h1>
                ) : vehicleType == "pcv" ? (
                  <h1>Select Two Wheeler Model</h1>
                ) : (
                  vehicleType == "gcv" && "GCV"
                )}
              </div>
              <div class="search-brand-name">
                <ReactSelect
                  options={modelData}
                  name="model"
                  placeholder="Select vehicle model"
                  onChange={(val) => handleSelectModel(val)}
                />
              </div>
              <div class="brand-logo d-flex flex-column">
                <ul className="brand-logo-list">
                  {modelData &&
                    modelData.length > 0 &&
                    modelData.slice(0, 15).map((item, index) => (
                      <li
                        onClick={() => handleSelectModel(item)}
                        className="brand-logo-1 d-flex justify-content-center align-items-center"
                        id={"rto11" + index}
                        name="carComanY"
                      >
                        <label className="illnessbox" htmlFor={"rto11" + index}>
                          {item.label}
                          {/* <input type="radio" name="radio" />
                          <span className="illcheckmark"></span> */}
                        </label>
                      </li>
                    ))}
                </ul>
              </div>
              {/* <div class="more-rto">
              <select name="" id="">
                <option value="">View More</option>
              </select>
            </div> */}
              <div className="d-flex final-step-inner-2-button gap-4">
                <PreviousButton onClick={handlePreviousStep} />
                <div class="rto-sub">
                  <button type="button" className="btn-primary">
                    Continue
                  </button>
                </div>
              </div>
            </div>
          )}
          {activeStep === 4 && (
            <div class="bike-brand-name">
              <div class="company-name-heading">
                <h1>Select Fuel Type</h1>
              </div>
              <div className="allcitybike">
                <ul className="brand-logo-list">
                  {fuelTypes.map((fuel) => (
                    <li
                      onClick={() => handleSelectPetrolType(fuel)}
                      className="brand-logo-1 d-flex justify-content-center align-items-center"
                      id={"rto11petrol"}
                      name="carComany"
                    >
                      <label
                        className={`illnessbox ${
                          fuel === "lpg" || fuel === "cng"
                            ? "text-uppercase"
                            : "text-capitalize"
                        }`}
                        htmlFor={"rto11petrol"}
                      >
                        {fuel}
                      </label>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="d-flex final-step-inner-2-button gap-4">
                <PreviousButton onClick={handlePreviousStep} />
                <div class="rto-sub">
                  <button type="button" className="btn-primary">
                    Continue
                  </button>
                </div>
              </div>
            </div>
          )}
          {activeStep === 5 && (
            <div class="bike-brand-name">
              <div class="company-name-heading">
                {vehicleType == "4w" ? (
                  <h1>Select Four Wheeler Variant</h1>
                ) : vehicleType == "2w" ? (
                  <h1>Select Two Wheeler Variant</h1>
                ) : vehicleType == "pcv" ? (
                  <h1>Select Two Wheeler Variant</h1>
                ) : (
                  vehicleType == "gcv" && "GCV"
                )}
              </div>
              <div class="search-brand-name">
                <ReactSelect
                  options={variantData.filter(
                    (item) => item.fuelType == motorRequest.fuelType
                  )}
                  name="variant"
                  placeholder="Select vehicle variant"
                  onChange={(val) => handleSelectVariant(val)}
                />
              </div>
              <div class="brand-logo d-flex flex-column">
                <ul className="brand-logo-list">
                  {variantData &&
                    variantData.length > 0 &&
                    variantData
                      .filter((item) => item.fuelType == motorRequest.fuelType)
                      .slice(0, 12)
                      .map((item, index) => {
                        const labelText =
                          motorRequest.fuelType === "battery"
                            ? item.label.split("(")[0].trim() // Remove the part inside parentheses
                            : item.label;

                        return (
                          <li
                            id={"rot12" + index}
                            name="carComanY11"
                            onClick={() => handleSelectVariant(item)}
                            className="brand-logo-1 d-flex justify-content-center align-items-center"
                          >
                            <label
                              className="illnessbox text-capitalize"
                              htmlFor={"rot12" + index}
                              value={item.value}
                            >
                              {labelText}
                              {/* <input type="radio" name="radio" />
                            <span className="illcheckmark"></span> */}
                            </label>
                          </li>
                        );
                      })}
                </ul>
              </div>

              <div className="d-flex final-step-inner-2-button gap-4">
                <PreviousButton onClick={handlePreviousStep} />
                <div class="rto-sub">
                  <button type="button" className="btn-primary">
                    Continue
                  </button>
                </div>
              </div>
            </div>
          )}
          {activeStep === 6 && (
            <div class="bike-brand-name">
              <div class="company-name-heading">
                <h2>Select Manufacturing Year</h2>
              </div>
              <div class="brand-logo d-flex flex-column">
                <ul className="brand-logo-list">
                  {motorRequest?.isVehicleNew
                    ? generateArrayOfYearsForNew().map((item, index) => (
                        <>
                          <li
                            onClick={() => selectVehicleYear(item)}
                            className="brand-logo-1 d-flex justify-content-center align-items-center"
                            value={item}
                            id={"yrr" + index}
                            name="CarYear"
                          >
                            <label
                              className="illnessbox"
                              htmlFor={"yrr" + index}
                            >
                              {item}
                              {/* <input type="radio" name="radio" />
                              <span className="illcheckmark"></span> */}
                            </label>
                          </li>
                        </>
                      ))
                    : generateArrayOfYears().map((item, index) => (
                        <>
                          <li
                            onClick={() => selectVehicleYear(item)}
                            className="brand-logo-1 d-flex justify-content-center align-items-center"
                            value={item}
                            id={"yrr" + index}
                            name="CarYear"
                          >
                            <label
                              className="illnessbox"
                              htmlFor={"yrr" + index}
                            >
                              {item}
                              {/* <input type="radio" name="radio" />
                              <span className="illcheckmark"></span> */}
                            </label>
                          </li>
                        </>
                      ))}
                </ul>
              </div>

              <div className="d-flex final-step-inner-2-button gap-4">
                <PreviousButton onClick={handlePreviousStep} />
                <div class="rto-sub">
                  <button type="button" className="btn-primary">
                    Continue
                  </button>
                </div>
              </div>
            </div>
          )}
          {activeStep === 7 && (
            <div class="bike-brand-name">
              <div class="company-name-heading">
                <h1>Fill Out the Required Details</h1>
              </div>
              {/* <h2 className="mb-3">Fill Out the Required Details</h2> */}
              <form className="" onSubmit={handleSubmit1(submitUserDetails)}>
                <div className="final-step-inner">
                  {isNewVehicle === false && (
                    <>
                      <div>
                        <label className="form-label">Vehicle Owned by?</label>
                        <div className="d-flex yesnoinput">
                          <div>
                            <input
                              type="radio"
                              className="d-none"
                              id="individual"
                              name="customerType"
                              // {...register1("Vehicleowner", {
                              //   required: "Vehicleowner is required",
                              // })}
                              value={"INDIVIDUAL"}
                              onChange={(e) => {
                                dispatchQuickQuote(
                                  "CustomerType",
                                  e.target.value
                                );
                                dispatchMotorQuote(
                                  "customerType",
                                  CUSTOMER_TYPE?.INDIVIDUAL
                                );
                                handleCheckboxChange("Vehicleowner");
                              }}
                              defaultChecked={
                                motorRequest?.customerType ===
                                CUSTOMER_TYPE?.INDIVIDUAL
                                  ? true
                                  : false
                              }
                            />
                            <label htmlFor="individual">Individual</label>
                          </div>
                          <div>
                            <input
                              type="radio"
                              className="d-none"
                              id="organization"
                              name="customerType"
                              // {...register1("Vehicleowner", {
                              //   required: "Vehicleowner is required",
                              // })}
                              value={"ORGANISATION"}
                              onChange={(e) => {
                                dispatchQuickQuote(
                                  "CustomerType",
                                  e.target.value
                                );
                                dispatchMotorQuote(
                                  "customerType",
                                  CUSTOMER_TYPE?.ORGANISATION
                                );
                                handleCheckboxChange("Vehicleowner");
                              }}
                              defaultChecked={
                                motorRequest?.customerType ===
                                CUSTOMER_TYPE?.ORGANISATION
                                  ? true
                                  : false
                              }
                            />
                            <label htmlFor="organization">Organization</label>
                          </div>
                        </div>
                        <p
                          id="error-Vehicleowner"
                          className="f-error text-danger mb-2"
                        >
                          {errors1?.Vehicleowner?.message}
                        </p>
                      </div>

                      {!motorRequest?.isVehicleNew && (
                        <div>
                          <label className="form-label">
                            Last Owner Change?
                          </label>
                          <div className="d-flex yesnoinput">
                            <div>
                              <input
                                type="radio"
                                className="d-none"
                                id="owneryes"
                                name="ownerYesOrNo"
                                // {...register1("ownerchange", {
                                //   required: "Lastownerchange is required",
                                // })}
                                onChange={(e) => {
                                  dispatchQuickQuote(
                                    "PreviousOwnerChanged",
                                    true
                                  );
                                  dispatchMotorQuote("isOwnerChanged", true);
                                  dispatchMotorQuote(
                                    "businessType",
                                    POLICY_STATUS.USED
                                  );
                                  handleCheckboxChange("ownerchange");
                                }}
                                value={"true"}
                                defaultChecked={
                                  motorRequest?.isOwnerChanged ? true : false
                                }
                              />
                              <label htmlFor="owneryes">Yes</label>
                            </div>
                            <div>
                              <input
                                type="radio"
                                className="d-none"
                                id="ownerno"
                                name="ownerYesOrNo"
                                // {...register1("ownerchange", {
                                //   required: "Lastownerchange is required",
                                // })}
                                value={"false"}
                                onChange={(e) => {
                                  dispatchQuickQuote(
                                    "PreviousOwnerChanged",
                                    false
                                  );
                                  dispatchMotorQuote("isOwnerChanged", false);
                                  handleCheckboxChange("ownerchange");
                                }}
                                defaultChecked={
                                  !motorRequest?.isOwnerChanged ? true : false
                                }
                              />
                              <label htmlFor="ownerno">No</label>
                            </div>
                          </div>
                          <p
                            id="error-ownerchange"
                            className="f-error text-danger mb-4"
                          >
                            {errors1?.ownerchange?.message}
                          </p>
                        </div>
                      )}
                    </>
                  )}
                </div>

                <div className="mt-3">
                  <div className="did-floating-label-content">
                    <input
                      type="text"
                      className="did-floating-input fullName"
                      {...register1("FullName", {
                        required: "Full name is required",
                        minLength: {
                          value: 3,
                          message: "Please enter at least 3 characters",
                        },
                      })}
                      maxLength={100}
                      onChange={(e) => {
                        clearErrors1("FullName");
                        e.target.value = e.target.value.replace(/[0-9]/g, "");
                      }}
                      id="Fname"
                      placeholder=" "
                    />
                    <label htmlFor="Fname" className="did-floating-label">
                      {`${
                        motorRequest?.customerType === CUSTOMER_TYPE?.INDIVIDUAL
                          ? "Full Name"
                          : "Organisation Name"
                      } `}
                      <span className="text-danger">*</span>
                    </label>
                    <p className="f-error text-danger">
                      {errors1?.FullName?.message}
                    </p>
                  </div>
                  <div className="did-floating-label-content">
                    <input
                      type="email"
                      className="did-floating-input fullName"
                      {...register1("Email", {
                        required: "Email is required",
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                          message: "Invalid email address",
                        },
                      })}
                      value={email}
                      // onChange={handleChangeEmail}
                      onInput={(e) => {
                        let inputValue = e.target.value;
                        // let domainIndex = inputValue.lastIndexOf(".");

                        if (inputValue.includes(".com")) {
                          inputValue = inputValue.replace(/\.com.*/, ".com");
                        } else if (inputValue.includes(".in")) {
                          inputValue = inputValue.replace(/\.in.*/, ".in");
                        }
                        // else {
                        //   inputValue = inputValue.replace(/[^ci]/g, "");
                        // }
                        e.target.value = inputValue;
                      }}
                      id="mail2"
                      placeholder=" "
                    />
                    <label htmlFor="mail2" className="did-floating-label">
                      Email
                      <span className="text-danger">*</span>
                    </label>
                    <p className="f-error text-danger">
                      {errors1?.Email?.message}
                    </p>
                  </div>
                  <div className="did-floating-label-content">
                    <input
                      type="text"
                      {...register1("MobileNumber", {
                        required: "Mobile is required",
                        pattern: {
                          value:
                            /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[6789]\d{9}$/,
                          message: "Invalid Mobile Number",
                        },
                      })}
                      onInput={(e) =>
                        (e.target.value = e.target.value.slice(0, 10))
                      }
                      onChange={(e) => {
                        let value = e.target.value.replace(/[^0-9]/g, "");
                        if (
                          value.length > 0 &&
                          (value.startsWith("0") ||
                            value.startsWith("1") ||
                            value.startsWith("2") ||
                            value.startsWith("3") ||
                            value.startsWith("4") ||
                            value.startsWith("5"))
                        ) {
                          value = value.substring(1);
                        }
                        e.target.value = value;
                        clearErrors1("MobileNumber");
                      }}
                      className="did-floating-input fullName"
                      id="number2"
                      placeholder=""
                    />
                    <label htmlFor="number2" className="did-floating-label">
                      Mobile No.
                      <span className="text-danger">*</span>
                    </label>
                    <p className="f-error text-danger">
                      {errors1?.MobileNumber?.message}
                    </p>
                  </div>
                </div>

                <div className="d-flex final-step-inner-2-button final-step-inner-2-button-center gap-4">
                  <PreviousButton onClick={handlePreviousStep} />
                  <div class="rto-sub final-step-inner">
                    <button type="submit" className="btn-primary">
                      Continue
                    </button>
                  </div>
                </div>
              </form>
            </div>
          )}
          {activeStep === 8 && (
            <div class="bike-brand-name">
              <div class="company-name-heading">
                <h1>Just One More Step to See Your Quotes</h1>
              </div>
              <form
                onSubmit={handleSubmit(submitPrevInsurernNCB)}
                className="w-100 d-flex flex-column justify-content-center"
              >
                <div className="d-flex gap-3 final-step-inner-2 justify-content-center">
                  {/* <div > */}
                  {motorRequest?.isVehicleNew ||
                  motorRequest?.isOwnerChanged ? (
                    <>
                      <div className="last-Setp-left">
                        {/* Registration Date */}
                        <div className="did-floating-label-content">
                          <DatePicker
                            {...register("registrationDate", {
                              required: "Field is required",
                            })}
                            selected={registrationDate}
                            onChange={handleRegistrationDateChange}
                            customInput={
                              <ReactInputMask
                                mask="99/99/9999"
                                placeholder="DD/MM/YYYY"
                                value={
                                  registrationDate
                                    ? moment(registrationDate).format(
                                        "DD/MM/YYYY"
                                      )
                                    : ""
                                }
                                onChange={handleInputRegistrationDateChange}
                                alwaysShowMask={false} // Avoid forcing the mask
                              />
                            }
                            id="idxx6"
                            className="did-floating-input"
                            minDate={moment(motorRequest?.manufaturingDate)
                              .startOf("year")
                              .toDate()}
                            maxDate={moment(maxRegDate).toDate()}
                            placeholderText="Select Registration Date"
                            dateFormat="dd/MM/yyyy"
                          />
                          <label htmlFor="idxx6" className="did-floating-label">
                            Registration Date
                            <span className="text-danger">*</span>
                          </label>
                          {errors?.registrationDate?.message && (
                            <p className="f-error text-danger">
                              {errors?.registrationDate?.message}
                            </p>
                          )}
                        </div>
                        {/* Previous Policy Type */}
                        {/* {apiRequestQQ?.IsVehicleNew === false &&
                          (watch("PolicyStatus") === "expired within 90 day" ||
                            watch("PolicyStatus") === "continue") && (
                            <div className="did-floating-label-content ">
                              <select
                                name="PreviousPolicyType"
                                {...register("PreviousPolicyType", {
                                  required: "Field is required",
                                })}
                                className="form-select"
                                id="idxx3"
                                onChange={() => {
                                  clearErrors("PreviousPolicyType");
                                }}
                              >
                                <option className="d-none" value="">
                                  Select Previous Policy Type
                                  <span className="text-danger">*</span>
                                </option>
                                {apiRequestQQ?.VehicleType === "4w" ? (
                                  differenceYear < 1.2 ? (
                                    <option value={"Bundled"}>Bundled</option>
                                  ) : differenceYear < 3 &&
                                    differenceYear > 1 ? (
                                    <option value={"ODOnly"}>Own Damage</option>
                                  ) : differenceYear < 4 &&
                                    differenceYear > 3 ? (
                                    <option value={"ThirdParty"}>
                                      Third Party
                                    </option>
                                  ) : (
                                    <>
                                      <option value={"Comprehensive"}>
                                        Comprehensive
                                      </option>
                                      <option value={"ThirdParty"}>
                                        Third Party
                                      </option>
                                    </>
                                  )
                                ) : apiRequestQQ?.VehicleType === "2w" ? (
                                  differenceYear < 1 ? (
                                    <option value={"Bundled"}>Bundled</option>
                                  ) : differenceYear < 5 &&
                                    differenceYear > 1 ? (
                                    <option value={"ODOnly"}>Own Damage</option>
                                  ) : differenceYear < 6 &&
                                    differenceYear > 5 ? (
                                    <option value={"ThirdParty"}>
                                      Third Party
                                    </option>
                                  ) : (
                                    <>
                                      <option value={"Comprehensive"}>
                                        Comprehensive
                                      </option>
                                      <option value={"ThirdParty"}>
                                        Third Party
                                      </option>
                                    </>
                                  )
                                ) : (
                                  ""
                                )}
                              </select>
                              <label
                                htmlFor="idxx6"
                                className="did-floating-label"
                              >
                                Previous Policy Type
                                <span className="text-danger">*</span>
                              </label>
                              <p className="f-error text-danger">
                                {errors?.PreviousPolicyType?.message}
                              </p>
                            </div>
                          )} */}
                        {/* Previous Policy End Date */}
                        {/* {apiRequestQQ?.IsVehicleNew === false &&
                          (watch("PolicyStatus") === "expired within 90 day" ||
                            watch("PolicyStatus") === "continue") && (
                            <div className="did-floating-label-content ">
                              <input
                                {...register("PrePolicyEndDate", {
                                  required: "Field is required",
                                })}
                                onChange={() => {
                                  clearErrors("PrePolicyEndDate");
                                }}
                                name="PrePolicyEndDate"
                                type="date"
                                id="idxx6"
                                className=" did-floating-input"
                                min={
                                  watch("PolicyStatus") ===
                                  "expired within 90 day"
                                    ? moment()
                                        .subtract(90, "days")
                                        .format("YYYY-MM-DD")
                                    : moment()
                                        .subtract(0, "days")
                                        .format("YYYY-MM-DD")
                                }
                                max={
                                  watch("PolicyStatus") ===
                                  "expired within 90 day"
                                    ? moment()
                                        .subtract(0, "days")
                                        .format("YYYY-MM-DD")
                                    : moment()
                                        .add(180, "days")
                                        .format("YYYY-MM-DD")
                                }
                              />
                              <label
                                htmlFor="idxx6"
                                className="did-floating-label"
                              >
                                Previous Policy Expiry Date
                                <span className="text-danger">*</span>
                              </label>
                              <p className="f-error text-danger">
                                {errors?.PrePolicyEndDate?.message}
                              </p>
                            </div>
                          )} */}
                      </div>
                      <div className="last-Setp-right">
                        {/* Policy Status */}
                        {/* {apiRequestQQ?.IsVehicleNew !== true && (
                          <div className=" formStep8PolicyInsurer">
                            <div className="did-floating-label-content ">
                              <select
                                name="PolicyStatus"
                                {...register("PolicyStatus", {
                                  required: "Field is required",
                                })}
                                className="form-select"
                                id="idxx3"
                              >
                                <option className="d-none" value="">
                                  Select Policy Status
                                  <span className="text-danger">*</span>
                                </option>

                                <option
                                  value="continue"
                                  onClick={() => {
                                    setExpiredStatus({
                                      ...expiredStatus,
                                      field: false,
                                    });
                                  }}
                                >
                                  Not Expired
                                </option>
                                <option value="expired within 90 day">
                                  Expired Within 90 days
                                </option>
                                <option value="expired above 90 day">
                                  Expired Above 90 days
                                </option>
                                <option value="false">
                                  No Information About Previous Policy
                                </option>
                              </select>
                              <label
                                htmlFor="idxx3"
                                className="did-floating-label"
                              >
                                Select Policy Status
                                <span className="text-danger">*</span>
                              </label>
                              <p className="f-error text-danger">
                                {errors?.PolicyStatus?.message}
                              </p>
                            </div>
                          </div>
                        )} */}
                        {/* New Policy Type */}
                        <div className=" formStep8PolicyInsurer">
                          <div
                            className="did-floating-label-content "
                            style={{ marginBottom: "0px" }}
                          >
                            <select
                              name="newPolicyType"
                              {...register("newPolicyType", {
                                required: "Field is required",
                              })}
                              className="form-select"
                              disabled={filledRegDate ? false : true}
                              id="idxx3"
                              onChange={() => {
                                clearErrors("newPolicyType");
                              }}
                              defaultValue={POLICY_TYPE.BUNDLED}
                            >
                              <option className="d-none" value="">
                                New Policy Type
                                <span className="text-danger">*</span>
                              </option>
                              {motorRequest?.isVehicleNew &&
                              (motorRequest?.vehicleType ===
                                VEHICLE_TYPE.FOUR_WHEELER ||
                                motorRequest?.vehicleType ===
                                  VEHICLE_TYPE.TWO_WHEELER) ? (
                                <>
                                  <option value={POLICY_TYPE.BUNDLED}>
                                    Bundled
                                  </option>
                                  <option value={POLICY_TYPE.THIRDPARTY}>
                                    Third Party
                                  </option>
                                </>
                              ) : motorRequest?.vehicleType ===
                                  VEHICLE_TYPE.FOUR_WHEELER &&
                                differenceYear < 3 &&
                                motorRequest?.isOwnerChanged === false ? (
                                <option value={POLICY_TYPE.ODONLY}>
                                  Own Damage
                                </option>
                              ) : motorRequest?.vehicleType ===
                                  VEHICLE_TYPE.FOUR_WHEELER &&
                                differenceYear < 3 &&
                                motorRequest?.isOwnerChanged === true ? (
                                <option value={POLICY_TYPE.COMPREHENSIVE}>
                                  Comprehensive
                                </option>
                              ) : motorRequest?.vehicleType ===
                                  VEHICLE_TYPE.FOUR_WHEELER &&
                                differenceYear > 3 ? (
                                <>
                                  <option value={POLICY_TYPE.COMPREHENSIVE}>
                                    Comprehensive
                                  </option>
                                  <option value={POLICY_TYPE.THIRDPARTY}>
                                    Third Party
                                  </option>
                                </>
                              ) : motorRequest?.vehicleType ===
                                  VEHICLE_TYPE.TWO_WHEELER &&
                                differenceYear < 5 &&
                                motorRequest?.isOwnerChanged === false ? (
                                <option value={POLICY_TYPE.ODONLY}>
                                  Own Damage
                                </option>
                              ) : motorRequest?.vehicleType ===
                                  VEHICLE_TYPE.TWO_WHEELER &&
                                differenceYear < 5 &&
                                motorRequest?.isOwnerChanged === true ? (
                                <option value={POLICY_TYPE.COMPREHENSIVE}>
                                  Comprehensive
                                </option>
                              ) : motorRequest?.vehicleType ===
                                  VEHICLE_TYPE.TWO_WHEELER &&
                                differenceYear > 5 ? (
                                <>
                                  <option value={POLICY_TYPE.COMPREHENSIVE}>
                                    Comprehensive
                                  </option>
                                  <option value={POLICY_TYPE.THIRDPARTY}>
                                    Third Party
                                  </option>
                                </>
                              ) : (
                                <>
                                  <option value={POLICY_TYPE.COMPREHENSIVE}>
                                    Comprehensive
                                  </option>
                                  <option value={POLICY_TYPE.THIRDPARTY}>
                                    Third Party
                                  </option>{" "}
                                  <option value={POLICY_TYPE.ODONLY}>
                                    Own Damage
                                  </option>{" "}
                                </>
                              )}

                              {/* {apiRequestQQ.IsVehicleNew == true ? (
                          <option selected value="Comprehensive">
                            Bundled
                          </option>
                        ) : (
                          <>
                            <option value="Comprehensive">Comprehensive</option>
                            <option value="ThirdParty">Third Party</option>
                            <option value="ODOnly">Own Damage</option>
                          </>
                        )} */}
                            </select>
                            <label
                              htmlFor="idxx3"
                              className="did-floating-label"
                            >
                              New Policy Type
                              <span className="text-danger">*</span>
                            </label>
                          </div>
                          <p className="f-error text-danger fs-6">
                            {errors?.newPolicyType?.message}
                          </p>
                        </div>

                        {/* Previous Policy Insurer */}
                        {/* {(watch("PolicyStatus") === "expired within 90 day" ||
                          watch("PolicyStatus") === "continue") && (
                          <>
                            <div className=" formStep8PolicyInsurer">
                              <div
                                className="did-floating-label-content "
                                style={{ marginBottom: "0px" }}
                              >
                                <select
                                  name="PreInsurerCode"
                                  {...register("PreInsurerCode", {
                                    required: "Field is required",
                                  })}
                                  className="form-select formStep8PolicyInsurerSelect"
                                  id="idxx3"
                                >
                                  <option className="d-none" value="" selected>
                                    Previous Policy Insurer
                                  </option>
                                  {insurerData &&
                                    insurerData.length > 0 &&
                                    insurerData.map((item, i) => (
                                      <option key={i} value={item.value}>
                                        {item.option}
                                      </option>
                                    ))}
                                </select>
                                <label
                                  htmlFor="idxx3"
                                  className="did-floating-label"
                                >
                                  Select Previous Policy Insurer
                                </label>
                              </div>
                              <p className="f-error text-danger">
                                {errors?.PreInsurerCode?.message}
                              </p>
                            </div>
                          </>
                        )} */}
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="last-Setp-right">
                        {/* Policy Status */}
                        {motorRequest?.isVehicleNew !== true && (
                          <div className=" formStep8PolicyInsurer">
                            <div className="did-floating-label-content ">
                              <select
                                name="businessType"
                                {...register("businessType", {
                                  required: "Field is required",
                                })}
                                className="form-select"
                                id="idxx3"
                              >
                                <option className="d-none" value="">
                                  Select Policy Status
                                  <span className="text-danger">*</span>
                                </option>

                                <option
                                  value={POLICY_STATUS.NOTEXPIRED}
                                  onClick={() => {
                                    setExpiredStatus({
                                      ...expiredStatus,
                                      field: false,
                                    });
                                  }}
                                >
                                  Not Expired
                                </option>
                                <option value={POLICY_STATUS.EXPIREDWITHIN90}>
                                  Expired Within 90 days
                                </option>
                                <option value={POLICY_STATUS.EXPIREDBEFORE90}>
                                  Expired Above 90 days
                                </option>
                                <option value={POLICY_STATUS.EXPIREDBEFORE90}>
                                  No Information About Previous Policy
                                </option>
                              </select>
                              <label
                                htmlFor="idxx3"
                                className="did-floating-label"
                              >
                                Select Policy Status
                                <span className="text-danger">*</span>
                              </label>
                              <p className="f-error text-danger">
                                {errors?.businessType?.message}
                              </p>
                            </div>
                          </div>
                        )}
                        {/* New Policy Type */}
                        <div className=" formStep8PolicyInsurer">
                          <div
                            className="did-floating-label-content "
                            style={{ marginBottom: "0px" }}
                          >
                            <select
                              name="newPolicyType"
                              {...register("newPolicyType", {
                                required: "Field is required",
                              })}
                              className="form-select"
                              disabled={filledRegDate ? false : true}
                              id="idxx3"
                              onChange={() => {
                                clearErrors("newPolicyType");
                              }}
                            >
                              <option className="d-none" value="">
                                New Policy Type
                                <span className="text-danger">*</span>
                              </option>
                              {motorRequest?.isVehicleNew &&
                              (motorRequest?.vehicleType ===
                                VEHICLE_TYPE.FOUR_WHEELER ||
                                motorRequest?.vehicleType ===
                                  VEHICLE_TYPE.TWO_WHEELER) ? (
                                <>
                                  <option value={POLICY_TYPE.BUNDLED}>
                                    Bundled
                                  </option>
                                  <option value={POLICY_TYPE.THIRDPARTY}>
                                    Third Party
                                  </option>
                                </>
                              ) : motorRequest?.vehicleType ===
                                  VEHICLE_TYPE.FOUR_WHEELER &&
                                differenceYear < 3 ? (
                                <option value={POLICY_TYPE.ODONLY}>
                                  Own Damage
                                </option>
                              ) : motorRequest?.vehicleType ===
                                  VEHICLE_TYPE.FOUR_WHEELER &&
                                differenceYear > 3 ? (
                                <>
                                  <option value={POLICY_TYPE.COMPREHENSIVE}>
                                    Comprehensive
                                  </option>
                                  <option value={POLICY_TYPE.THIRDPARTY}>
                                    Third Party
                                  </option>
                                </>
                              ) : motorRequest?.vehicleType ===
                                  VEHICLE_TYPE.TWO_WHEELER &&
                                differenceYear < 5 ? (
                                <option value={POLICY_TYPE.ODONLY}>
                                  Own Damage
                                </option>
                              ) : motorRequest?.vehicleType ===
                                  VEHICLE_TYPE.TWO_WHEELER &&
                                differenceYear > 5 ? (
                                <>
                                  <option value={POLICY_TYPE.COMPREHENSIVE}>
                                    Comprehensive
                                  </option>
                                  <option value={POLICY_TYPE.THIRDPARTY}>
                                    Third Party
                                  </option>
                                </>
                              ) : (
                                <>
                                  <option value={POLICY_TYPE.COMPREHENSIVE}>
                                    Comprehensive
                                  </option>
                                  <option value={POLICY_TYPE.THIRDPARTY}>
                                    Third Party
                                  </option>{" "}
                                  <option value={POLICY_TYPE.ODONLY}>
                                    Own Damage
                                  </option>{" "}
                                </>
                              )}
                            </select>
                            <label
                              htmlFor="idxx3"
                              className="did-floating-label"
                            >
                              New Policy Type
                              <span className="text-danger">*</span>
                            </label>
                          </div>
                          <p className="f-error text-danger fs-6">
                            {errors?.newPolicyType?.message}
                          </p>
                        </div>

                        {/* Previous Policy Insurer */}
                        {(watch("businessType") ===
                          POLICY_STATUS.EXPIREDWITHIN90 ||
                          watch("businessType") ===
                            POLICY_STATUS.NOTEXPIRED) && (
                          <>
                            <div className=" formStep8PolicyInsurer">
                              <div
                                className="did-floating-label-content "
                                style={{ marginBottom: "0px" }}
                              >
                                <select
                                  name="insurerCode"
                                  {...register("insurerCode", {
                                    required: "Field is required",
                                  })}
                                  className="form-select formStep8PolicyInsurerSelect"
                                  id="idxx3"
                                >
                                  <option className="d-none" value="" selected>
                                    Previous Policy Insurer
                                  </option>
                                  {insurerData &&
                                    insurerData.length > 0 &&
                                    insurerData.map((item, i) => (
                                      <option key={i} value={item.value}>
                                        {item.option}
                                      </option>
                                    ))}
                                </select>
                                <label
                                  htmlFor="idxx3"
                                  className="did-floating-label"
                                >
                                  Select Previous Policy Insurer
                                </label>
                              </div>
                              <p className="f-error text-danger">
                                {errors?.insurerCode?.message}
                              </p>
                            </div>
                          </>
                        )}
                      </div>
                      <div className="last-Setp-left">
                        {/* Registration Date */}
                        <div className="did-floating-label-content">
                          <DatePicker
                            {...register("registrationDate", {
                              required: "Field is required",
                            })}
                            selected={registrationDate}
                            onChange={handleRegistrationDateChange}
                            customInput={
                              <ReactInputMask
                                mask="99/99/9999"
                                placeholder="DD/MM/YYYY"
                                value={
                                  registrationDate
                                    ? moment(registrationDate).format(
                                        "DD/MM/YYYY"
                                      )
                                    : ""
                                }
                                onChange={handleInputRegistrationDateChange}
                                alwaysShowMask={false} // Avoid forcing the mask
                              />
                            }
                            id="idxx6"
                            className="did-floating-input"
                            minDate={moment(motorRequest?.manufaturingDate)
                              .startOf("year")
                              .toDate()}
                            maxDate={moment(maxRegDate).toDate()}
                            placeholderText="Select Registration Date"
                            dateFormat="dd/MM/yyyy"
                          />

                          <label htmlFor="idxx6" className="did-floating-label">
                            Registration Date
                            <span className="text-danger">*</span>
                          </label>
                          {errors?.registrationDate?.message && (
                            <p className="f-error text-danger">
                              {errors?.registrationDate?.message}
                            </p>
                          )}
                        </div>
                        {/* Previous Policy Type */}
                        {motorRequest?.isVehicleNew === false &&
                          (watch("businessType") ===
                            POLICY_STATUS.EXPIREDWITHIN90 ||
                            watch("businessType") ===
                              POLICY_STATUS.NOTEXPIRED) && (
                            <div className="did-floating-label-content ">
                              <select
                                name="prevPolicyType"
                                {...register("prevPolicyType", {
                                  required: "Field is required",
                                })}
                                className="form-select"
                                id="idxx3"
                                onChange={() => {
                                  clearErrors("prevPolicyType");
                                }}
                              >
                                <option className="d-none" value="">
                                  Select Previous Policy Type
                                  <span className="text-danger">*</span>
                                </option>
                                {motorRequest?.vehicleType ===
                                VEHICLE_TYPE.FOUR_WHEELER ? (
                                  differenceYear < 1.2 ? (
                                    <option value={POLICY_TYPE.PREVIOUSBUNDLED}>
                                      Bundled
                                    </option>
                                  ) : differenceYear < 3 &&
                                    differenceYear > 1 ? (
                                    <option value={POLICY_TYPE.ODONLY}>
                                      Own Damage
                                    </option>
                                  ) : differenceYear < 4 &&
                                    differenceYear > 3 ? (
                                    <>
                                      <option value={POLICY_TYPE.COMPREHENSIVE}>
                                        Comprehensive
                                      </option>
                                      <option value={POLICY_TYPE.THIRDPARTY}>
                                        Third Party
                                      </option>
                                      <option value={POLICY_TYPE.ODONLY}>
                                        Own Damage
                                      </option>
                                    </>
                                  ) : (
                                    <>
                                      <option value={POLICY_TYPE.COMPREHENSIVE}>
                                        Comprehensive
                                      </option>
                                      <option value={POLICY_TYPE.THIRDPARTY}>
                                        Third Party
                                      </option>
                                    </>
                                  )
                                ) : motorRequest?.vehicleType ===
                                  VEHICLE_TYPE.TWO_WHEELER ? (
                                  differenceYear < 1.2 ? (
                                    <option value={POLICY_TYPE.PREVIOUSBUNDLED}>
                                      Bundled
                                    </option>
                                  ) : differenceYear < 5 &&
                                    differenceYear > 1 ? (
                                    <option value={POLICY_TYPE.ODONLY}>
                                      Own Damage
                                    </option>
                                  ) : differenceYear < 6 &&
                                    differenceYear > 5 ? (
                                    <>
                                      <option value={POLICY_TYPE.COMPREHENSIVE}>
                                        Comprehensive
                                      </option>
                                      <option value={POLICY_TYPE.THIRDPARTY}>
                                        Third Party
                                      </option>
                                      <option value={POLICY_TYPE.ODONLY}>
                                        Own Damage
                                      </option>
                                    </>
                                  ) : (
                                    <>
                                      <option value={POLICY_TYPE.COMPREHENSIVE}>
                                        Comprehensive
                                      </option>
                                      <option value={POLICY_TYPE.THIRDPARTY}>
                                        Third Party
                                      </option>
                                    </>
                                  )
                                ) : (
                                  ""
                                )}
                              </select>
                              <label
                                htmlFor="idxx6"
                                className="did-floating-label"
                              >
                                Previous Policy Type
                                <span className="text-danger">*</span>
                              </label>
                              <p className="f-error text-danger">
                                {errors?.prevPolicyType?.message}
                              </p>
                            </div>
                          )}
                        {/* Previous Policy End Date */}
                        {motorRequest?.isVehicleNew === false &&
                          (watch("businessType") ===
                            POLICY_STATUS.EXPIREDWITHIN90 ||
                            watch("businessType") ===
                              POLICY_STATUS.NOTEXPIRED) && (
                            <div className="did-floating-label-content ">
                              <DatePicker
                                {...register("prevPolicyEndDate", {
                                  required: "Field is required",
                                })}
                                selected={prevPolicyEndDate}
                                onChange={handlePrevPolicyEndDate}
                                customInput={
                                  <ReactInputMask
                                    mask="99/99/9999"
                                    placeholder="DD/MM/YYYY"
                                    value={
                                      prevPolicyEndDate
                                        ? moment(prevPolicyEndDate).format(
                                            "DD/MM/YYYY"
                                          )
                                        : ""
                                    }
                                    onChange={handleInputChange}
                                    alwaysShowMask={false} // Avoid forcing the mask
                                  />
                                }
                                name="prevPolicyEndDate"
                                id="idxx6"
                                className="did-floating-input"
                                minDate={
                                  watch("businessType") ===
                                  POLICY_STATUS.EXPIREDWITHIN90
                                    ? moment().subtract(90, "days").toDate()
                                    : moment().toDate()
                                }
                                maxDate={
                                  watch("businessType") ===
                                  POLICY_STATUS.EXPIREDWITHIN90
                                    ? moment().toDate()
                                    : // : watch("businessType") ===
                                      // POLICY_STATUS.NOTEXPIRED
                                      // ? moment().add(44, "days").toDate()
                                      moment().add(180, "days").toDate()
                                }
                                dateFormat="dd/MM/yyyy"
                                placeholderText="Select Previous Policy Expiry Date"
                              />
                              <label
                                htmlFor="idxx6"
                                className="did-floating-label"
                              >
                                Previous Policy Expiry Date
                                <span className="text-danger">*</span>
                              </label>
                              <p className="f-error text-danger">
                                {errors?.prevPolicyEndDate?.message}
                              </p>
                            </div>
                          )}
                      </div>
                    </>
                  )}
                  {/* </div> */}
                </div>
                <div className="d-flex final-step-inner-2-button justify-content-center gap-3 mt-3">
                  <PreviousButton onClick={handlePreviousStep} />
                  <div class="rto-sub final-step-inner">
                    <button type="submit" className="btn-primary">
                      Continue
                    </button>
                  </div>
                </div>
              </form>
            </div>
          )}
          <div className="bike-rto-img">
            <img
              src={
                motorRequest?.vehicleType === VEHICLE_TYPE.FOUR_WHEELER
                  ? "/assets/images/car1.png"
                  : motorRequest?.vehicleType === "2w"
                  ? "/assets/images/BIKE.jpg"
                  : ""
              }
              alt=""
            />
          </div>
        </div>
      </div>
      <WhyChoose />
      <FastestInsurance />
      <WhyUs />

      {apiRequestQQ.VehicleType === "2w" ? (
        <AboutBike />
      ) : apiRequestQQ.VehicleType === "4w" ? (
        <AboutCar />
      ) : (
        ""
      )}
    </Layout>
  );
};

export default MotorProcess;

const checkIfDateIsIn3Month = (dateToCheck) => {
  return (
    moment().diff(dateToCheck, "days") > -90 &&
    moment().diff(dateToCheck, "days") <= 0
  );
};
