import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const InsuranceSlider = () => {
  var settingsInsuranceSlider = {
    dots: true,
    infinite: true,
    speed: 700,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 1800,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className="container insuranceslider slider">
      <Slider {...settingsInsuranceSlider}>
        <div className="homeInsuranceSlider mb-5">
          <div className="sliderText">
            <h3>
              Is your <br /> <span className="text-bold">Home Insurance</span>{" "}
              <br />
              Policy giving you
              <br /> the right cover?
            </h3>
            <div className="">
              <img src={"/assets/images/arrowicon.png"} alt="" />
            </div>
          </div>
          <div className="SilderImage">
            <img
              src={"/assets/images/sliderImage1.png"}
              alt="img"
              className=""
            />
          </div>
        </div>
        <div className="healthInsuranceSlider mb-5">
          <div className="sliderText">
            <h3>
              Is your <br /> <span className="text-bold">Health Insurance</span>{" "}
              <br />
              Policy giving you
              <br /> the right cover?
            </h3>
            <div className="">
              <img src={"/assets/images/arrowGreen.png"} alt="" />
            </div>
          </div>
          <div className="SilderImage">
            <img
              src={"/assets/images/sliderImage2.png"}
              alt="img"
              className=""
            />
          </div>
        </div>
        <div className="travelInsuranceSlider mb-5">
          <div className="sliderText">
            <h3>
              Is your <br /> <span className="text-bold">Travel Insurance</span>{" "}
              <br />
              Policy giving you
              <br /> the right cover?
            </h3>
            <div className="">
              <img src={"/assets/images/arrowBlue.png"} alt="" />
            </div>
          </div>
          <div className="SilderImage">
            <img
              src={"/assets/images/sliderImage3.png"}
              alt="img"
              className=""
            />
          </div>
        </div>
      </Slider>
    </div>
  );
};

export default InsuranceSlider;
