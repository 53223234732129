import React from "react";
import Slider from "react-slick";

const Sliders = () => {
  var settingsInsuranceSlider = {
    dots: true,
    infinite: true,
    speed: 700,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: false,
    autoplaySpeed: 1800,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          dots: true,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className="insuranceslider slider container">
      <Slider className="insuranceslider-inner" {...settingsInsuranceSlider}>
        <div className="homeInsuranceSlider mb-5">
          <div className="sliderText">
            <h3>
              Is your <br /> <span className="text-bold">Home Insurance</span>{" "}
              <br />
              Policy giving you
              <br /> the right cover?
            </h3>
            <div className="">
              <img src={"/assets/images/arrowicon.png"} alt="" />
            </div>
          </div>
          <img
            src={"/assets/images/sliderImage1.png"}
            alt="img"
            className="homeInsuranceSlider-image"
          />
        </div>
        <div className="healthInsuranceSlider mb-5">
          <div className="sliderText">
            <h3>
              Is your <br /> <span className="text-bold">Health Insurance</span>{" "}
              <br />
              Policy giving you
              <br /> the right cover?
            </h3>
            <div className="">
              <img src={"/assets/images/arrowGreen.png"} alt="" />
            </div>
          </div>
          <img
            src={"/assets/images/sliderImage2.png"}
            alt="img"
            className="homeInsuranceSlider-image"
          />
        </div>
        <div className="travelInsuranceSlider mb-5">
          <div className="sliderText">
            <h3>
              Is your <br /> <span className="text-bold">Travel Insurance</span>{" "}
              <br />
              Policy giving you
              <br /> the right cover?
            </h3>
            <div className="">
              <img src={"/assets/images/arrowBlue.png"} alt="" />
            </div>
          </div>
          <img
            src={"/assets/images/sliderImage3.png"}
            alt="img"
            className="homeInsuranceSlider-image"
          />
        </div>
      </Slider>
    </div>
  );
};

export default Sliders;
