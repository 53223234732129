import React, { useState } from "react";

const FAQ = () => {
  const [tab, setTab] = useState("Health");

  // 9765678654678965467897656

  const list = [
    "Health",
    "Travel",
    "Pet",
    "Two-Wheeler",
    "PVT Car",
    "Taxi",
    // "Term",
  ];
  return (
    <div className="accordion-footer container">
      {/* Header */}
      <div className="thought-process">
        <div className="thought-process-top thought-process-top-etxra-margin text-center mt-4 py-3">
          <h2>Frequently Asked Questions for Insurance</h2>
          <img src="/assets/images/Rectangle-bar.svg" />
          {/* <p className="text-center">
            Popular belief, Lorem Ipsum is not simply random text. It has roots
            in a piece of classical Latin literature from 45 BC, making it over
            2000 years old. Richard McClintock, a Latin professor at
            Hampden-Sydney College in Virgin
          </p> */}
        </div>
      </div>

      {/* Sections */}
      <div className="accordion-footer-sections">
        <ul className="container accordion-footer-sections-list">
          {list?.map((res) => {
            return (
              <li
                onClick={() => setTab(res)}
                className={`accordion-footer-sections-list-items ${
                  res == tab ? "active" : ""
                }`}
              >
                {res}
              </li>
            );
          })}
        </ul>
      </div>

      {/*Health Accordion */}
      <div style={{ width: "100%" }}>
        {tab === "Health" && (
          <div className="accordion accordion-flush" id="accordionFlushExample">
            <div className="accordion-item accordions">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseOne"
                aria-expanded="false"
                aria-controls="flush-collapseOne"
              >
                1. What is health insurance?
              </button>
              <div
                id="flush-collapseOne"
                className="accordion-collapse collapse"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body">
                  Health insurance is a type of coverage that helps individuals
                  pay for medical expenses incurred due to illnesses, injuries,
                  or preventive care.
                </div>
              </div>
            </div>
            <div className="accordion-item accordions">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseTwo"
                aria-expanded="false"
                aria-controls="flush-collapseTwo"
              >
                2. How does health insurance work?
              </button>
              <div
                id="flush-collapseTwo"
                className="accordion-collapse collapse"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body">
                  Health insurance policies typically involve paying a premium
                  to an insurer in exchange for coverage. When medical expenses
                  arise, the insurer may cover a portion or all of the costs as
                  per the policy terms.
                </div>
              </div>
            </div>
            <div className="accordion-item accordions">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseThree"
                aria-expanded="false"
                aria-controls="flush-collapseThree"
              >
                3. What types of health insurance plans are available on
                InsureFast?
              </button>
              <div
                id="flush-collapseThree"
                className="accordion-collapse collapse"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body">
                  InsureFast offers a range of health insurance plans, including
                  individual health plans, family floater plans, senior citizen
                  plans, critical illness plans, and more.
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      {/* TRAVEL ACCORDION */}
      <div style={{ width: "100%" }}>
        {tab === "Travel" && (
          <div className="accordion accordion-flush" id="accordionFlushExample">
            <div className="accordion-item accordions">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseOne"
                aria-expanded="false"
                aria-controls="flush-collapseOne"
              >
                1. What does travel insurance cover?
              </button>
              <div
                id="flush-collapseOne"
                className="accordion-collapse collapse"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body">
                  Travel insurance typically covers trip cancellations, medical
                  emergencies abroad, lost luggage, trip interruptions, and
                  other unforeseen events depending on the policy.
                </div>
              </div>
            </div>
            <div className="accordion-item accordions">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseTwo"
                aria-expanded="false"
                aria-controls="flush-collapseTwo"
              >
                2. Do I need travel insurance if I have health insurance?
              </button>
              <div
                id="flush-collapseTwo"
                className="accordion-collapse collapse"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body">
                  While some health insurance plans offer limited coverage
                  abroad, travel insurance provides additional coverage specific
                  to travel-related incidents such as trip cancellations, lost
                  baggage, and emergency medical evacuation.
                </div>
              </div>
            </div>
            <div className="accordion-item accordions">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseThree"
                aria-expanded="false"
                aria-controls="flush-collapseThree"
              >
                3. Can I purchase travel insurance after booking my trip?
              </button>
              <div
                id="flush-collapseThree"
                className="accordion-collapse collapse"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body">
                  Yes, travel insurance can often be purchased even after
                  booking a trip, but certain coverages might have restrictions
                  or limitations if not purchased within a specific timeframe
                  from the trip booking date.
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      {/* PET ACCORDIANS */}
      <div style={{ width: "100%" }}>
        {tab === "Pet" && (
          <div className="accordion accordion-flush" id="accordionFlushExample">
            <div className="accordion-item accordions">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseOne"
                aria-expanded="false"
                aria-controls="flush-collapseOne"
              >
                1. What does pet insurance cover?
              </button>
              <div
                id="flush-collapseOne"
                className="accordion-collapse collapse"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body">
                  Pet insurance typically covers veterinary bills for illnesses,
                  accidents, and sometimes routine care. It may also include
                  coverage for medications, surgeries, and diagnostic tests.
                </div>
              </div>
            </div>
            <div className="accordion-item accordions">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseTwo"
                aria-expanded="false"
                aria-controls="flush-collapseTwo"
              >
                2. Can I insure any type of pet?
              </button>
              <div
                id="flush-collapseTwo"
                className="accordion-collapse collapse"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body">
                  Most pet insurance plans cater to dogs and cats, but some
                  insurers offer coverage for exotic pets or birds. Check the
                  available options on the InsureFast platform.
                </div>
              </div>
            </div>
            <div className="accordion-item accordions">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseThree"
                aria-expanded="false"
                aria-controls="flush-collapseThree"
              >
                3. Are there age restrictions for enrolling my pet in insurance?
              </button>
              <div
                id="flush-collapseThree"
                className="accordion-collapse collapse"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body">
                  Some policies have age restrictions, with coverage being
                  easier to secure for younger pets. InsureFast can provide
                  details on policies suitable for different age groups.
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      {/* Two wheeler */}
      <div style={{ width: "100%" }}>
        {tab === "Two-Wheeler" && (
          <div className="accordion accordion-flush" id="accordionFlushExample">
            <div className="accordion-item accordions">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseOne"
                aria-expanded="false"
                aria-controls="flush-collapseOne"
              >
                1. What is two-wheeler insurance?
              </button>
              <div
                id="flush-collapseOne"
                className="accordion-collapse collapse"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body">
                  Two-wheeler insurance provides financial protection against
                  damages to your bike due to accidents, theft, natural
                  calamities, or third-party liabilities.
                </div>
              </div>
            </div>
            <div className="accordion-item accordions">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseTwo"
                aria-expanded="false"
                aria-controls="flush-collapseTwo"
              >
                1. What types of two-wheeler insurance plans are available on
                InsureFast?
              </button>
              <div
                id="flush-collapseTwo"
                className="accordion-collapse collapse"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body">
                  InsureFast offers comprehensive, third-party, and standalone
                  own-damage policies for two-wheelers, catering to different
                  coverage needs.
                </div>
              </div>
            </div>
            <div className="accordion-item accordions">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseThree"
                aria-expanded="false"
                aria-controls="flush-collapseThree"
              >
                3. How can I renew my existing two-wheeler insurance policy
                through InsureFast?
              </button>
              <div
                id="flush-collapseThree"
                className="accordion-collapse collapse"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body">
                  You can easily renew your two-wheeler insurance policy by
                  providing your policy details on the website and following the
                  renewal process offered by InsureFast.
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      {/*4w accordians */}
      <div style={{ width: "100%" }}>
        {tab === "PVT Car" && (
          <div className="accordion accordion-flush" id="accordionFlushExample">
            <div className="accordion-item accordions">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseOne"
                aria-expanded="false"
                aria-controls="flush-collapseOne"
              >
                1. What is Private Car insurance?
              </button>
              <div
                id="flush-collapseOne"
                className="accordion-collapse collapse"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body">
                  Four-wheeler insurance provides financial protection against
                  damages caused to or by your vehicle. It includes coverage for
                  accidents, theft, third-party liabilities, and more.
                </div>
              </div>
            </div>
            <div className="accordion-item accordions">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseTwo"
                aria-expanded="false"
                aria-controls="flush-collapseTwo"
              >
                2. What types of four-wheeler insurance plans are available on
                InsureFast?
              </button>
              <div
                id="flush-collapseTwo"
                className="accordion-collapse collapse"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body">
                  InsureFast typically offers options for comprehensive
                  insurance, third-party liability coverage, and add-on coverage
                  plans for four-wheelers.
                </div>
              </div>
            </div>
            <div className="accordion-item accordions">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseThree"
                aria-expanded="false"
                aria-controls="flush-collapseThree"
              >
                3. How can I buy four-wheeler insurance on InsureFast?
              </button>
              <div
                id="flush-collapseThree"
                className="accordion-collapse collapse"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body">
                  Users can easily purchase four-wheeler insurance by entering
                  vehicle details, selecting desired coverage, and making secure
                  online payments through the InsureFast platform.
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      {/* texi accordians */}
      <div style={{ width: "100%" }}>
        {tab === "Taxi" && (
          <div className="accordion accordion-flush" id="accordionFlushExample">
            <div className="accordion-item accordions">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseOne"
                aria-expanded="false"
                aria-controls="flush-collapseOne"
              >
                1. What is taxi insurance, and why is it necessary?
              </button>
              <div
                id="flush-collapseOne"
                className="accordion-collapse collapse"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body">
                  Taxi insurance provides coverage specifically designed for
                  vehicles used in the transportation of passengers for hire.
                  It's necessary to protect both drivers and passengers in case
                  of accidents or unforeseen events.
                </div>
              </div>
            </div>
            <div className="accordion-item accordions">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseTwo"
                aria-expanded="false"
                aria-controls="flush-collapseTwo"
              >
                2. What types of taxi insurance policies are available on
                InsureFast?
              </button>
              <div
                id="flush-collapseTwo"
                className="accordion-collapse collapse"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body">
                  InsureFast offers various types of taxi insurance policies,
                  including public hire insurance, private hire insurance, black
                  cab insurance, and fleet insurance, catering to different taxi
                  service needs.
                </div>
              </div>
            </div>
            <div className="accordion-item accordions">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseThree"
                aria-expanded="false"
                aria-controls="flush-collapseThree"
              >
                3. How can I get a quote for taxi insurance on InsureFast?
              </button>
              <div
                id="flush-collapseThree"
                className="accordion-collapse collapse"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body">
                  Getting a quote is simple on InsureFast. You can enter your
                  taxi details, driving history, and coverage preferences to
                  receive quotes from multiple insurers instantly.
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      {/* term accordians */}
      <div style={{ width: "100%" }}>
        {tab === "Term" && (
          <div className="accordion accordion-flush" id="accordionFlushExample">
            <div className="accordion-item accordions">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseOne"
                aria-expanded="false"
                aria-controls="flush-collapseOne"
              >
                1. What is a term plan insurance?
              </button>
              <div
                id="flush-collapseOne"
                className="accordion-collapse collapse"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body">
                  A term plan insurance is a type of life insurance that
                  provides coverage for a specified period (term) and pays a
                  death benefit to the nominee if the insured person passes away
                  during the policy term.
                </div>
              </div>
            </div>
            <div className="accordion-item accordions">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseTwo"
                aria-expanded="false"
                aria-controls="flush-collapseTwo"
              >
                2. How do I choose the right term plan on InsureFast?
              </button>
              <div
                id="flush-collapseTwo"
                className="accordion-collapse collapse"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body">
                  InsureFast simplifies the process by allowing you to compare
                  multiple term plans from various insurers based on coverage,
                  premiums, riders, and other features. Use the platform's
                  filters and comparison tools to find the most suitable plan
                  for your needs.
                </div>
              </div>
            </div>
            <div className="accordion-item accordions">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseThree"
                aria-expanded="false"
                aria-controls="flush-collapseThree"
              >
                3. What factors should I consider while selecting a term plan?
              </button>
              <div
                id="flush-collapseThree"
                className="accordion-collapse collapse"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body">
                  Factors like coverage amount, policy duration, premium
                  affordability, riders (like critical illness cover), insurer's
                  claim settlement ratio, and the reputation of the insurance
                  company should be considered before choosing a term plan.
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default FAQ;
