import React from "react";

function WhyChoose() {
  return (
    <>
      <div className="why-choose">
        <div className="why-choose-heading">
          <h4>Why Choose us</h4>
          <h1>
            Why Buy From <b>Insurefast</b>
          </h1>
        </div>
        <div className="why-choose-plans">
          <div className="plan-1-card">
            <img src="/assets/images/Platinum-img1.png" alt="" />
            <h3>Platinum insurance partner</h3>
            <p>
              Secure your future with our comprehensive insurance solutions that
              ensure you're set for life.
            </p>
          </div>
          <div className="plan-1-card">
            <img src="/assets/images/rupee1.png" alt="" />
            <h3>Affordable options</h3>
            <p>
              Secure your future with our comprehensive insurance solutions that
              ensure you're set for life.
            </p>
          </div>
          <div className="plan-1-card">
            <img src="/assets/images/Claims-img.png" alt="" />
            <h3>Free Claims Assistancer</h3>
            <p>
              Secure your future with our comprehensive insurance solutions that
              ensure you're set for life.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default WhyChoose;
