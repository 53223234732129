import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import {
  getVehicleMake,
  getVehicleModel,
  getVehicleVariant,
} from "../services/masterServices";
import { useDispatch, useSelector } from "react-redux";
// import ReactSelect from "react-select";
import ReactSelect from "../Tags/ReactSelectEditDetails";
import {
  dispatchMotorQuote,
  dispatchQuickQuote,
  resetQuickQuoteResults,
} from "../../store/action/userActions";
import {
  KotakAPICall,
  digitAPICall,
  sompoAPICall,
} from "../services/TPApiCall";
import { POLICY_STATUS, POLICY_TYPE } from "../utility/constants";
import moment from "moment";
import DatePicker from "react-datepicker";
import ReactInputMask from "react-input-mask";

const EditDetailsModal = ({
  show,
  setModal,
  filterPlanTypeList,
  handleFilteredResult,
  motorQuotation,
}) => {
  const toggle = () => setModal(!show);
  const [makeData, setMakeData] = useState([]);
  const [newFilter, setNewFilter] = useState({});
  const [modelData, setModelData] = useState([]);
  const [variantData, setVariantData] = useState([]);
  const [fuelTypes, setFuelTypes] = useState([]);

  const apiRequestQQ = useSelector((state) => state.root.apiRequestQQ);
  const motorRequest = useSelector((state) => state?.root?.motorRequest);

  const [manufacturingDate, setManufacturingDate] = useState(
    newFilter?.manufaturingDate
      ? new Date(newFilter.manufaturingDate)
      : motorRequest?.manufaturingDate
      ? new Date(motorRequest.manufaturingDate)
      : null
  );
  const [registrationDate, setRegistrationDate] = useState(
    newFilter?.registrationDate
      ? new Date(newFilter.registrationDate)
      : motorRequest?.registrationDate
      ? new Date(motorRequest.registrationDate)
      : null
  );
  const [prevPolicyEndDate, setPrevPolicyEndDate] = useState(
    newFilter?.prevPolicyEndDate
      ? new Date(newFilter.prevPolicyEndDate)
      : motorRequest?.prevPolicyEndDate
      ? new Date(motorRequest.prevPolicyEndDate)
      : null
  );

  const dispatch = useDispatch();
  const vehicleModel =
    apiRequestQQ.VehicleType === "4w"
      ? "Pvt Car"
      : apiRequestQQ.VehicleType === "2w"
      ? "MotorBike"
      : apiRequestQQ.VehicleType === "gcv"
      ? "Goods Carrying"
      : apiRequestQQ.VehicleType === "pcv" && "Passenger Carrying";

  useEffect(() => {
    let arr = [];
    getVehicleMake({ vehicleType: motorRequest?.vehicleType }).then(
      (response) => {
        if (response.status === "success") {
          let data = response.data;
          let i = 0;
          while (i < data.length) {
            let item = data[i];
            arr.push({
              label: item.make,
              value: item.makeId,
            });
            i++;
          }
          setMakeData(arr);
        }
      }
    );
    getVehicleModel({
      makeId: motorRequest?.makeId,
      vehicleType: motorRequest?.vehicleType,
    }).then((response) => {
      if (response.status === "success") {
        // handleStepSelection(activeStep + 1);
        let data = response.data;
        let i = 0;
        let arr = [];
        while (i < data.length) {
          let item = data[i];
          arr.push({
            label: item?.model,
            value: item?.modelId,
            makeId: item?.makeId,
          });
          i++;
        }

        setModelData(arr);
      }
    });
    getVehicleVariant({
      makeId: motorRequest.makeId,
      modelId: motorRequest?.modelId,
      vehicleType: motorRequest?.vehicleType,
    }).then((response) => {
      if (response.status === "success") {
        let data = response.data;
        let fuelTypeArr = data.filter(
          (v, i, a) => a.findIndex((v2) => v2.fuelType === v.fuelType) === i
        );
        let j = 0;
        let fuelarr = [];
        while (j < fuelTypeArr.length) {
          fuelarr.push(fuelTypeArr[j].fuelType);
          j++;
        }
        setFuelTypes(fuelarr);
        let i = 0;
        let arr = [];
        while (i < data.length) {
          let item = data[i];
          arr.push({
            make: item?.make,
            makeId: item?.makeId,
            model: item?.model,
            modelId: item?.modelId,
            label: `${item?.variant}(${item?.cc}cc)`,
            value: item?.variantId,
            fuelType: item.fuelType,
            cubicCapacity: item.cc,
            seatingCapacity: item.seats,
          });
          i++;
        }

        setVariantData(arr);
      }
    });
  }, []);

  const handleSelectMake = (make) => {
    setNewFilter((prevState) => ({
      ...prevState,
      makeName: make?.label,
      makeId: make?.value,
    }));
    getVehicleModel({
      makeId: make?.value,
      vehicleType: motorRequest?.vehicleType,
    }).then((response) => {
      if (response.status === "success") {
        let data = response.data;
        let i = 0;
        let arr = [];
        while (i < data.length) {
          let item = data[i];
          arr.push({
            label: item?.model,
            value: item?.modelId,
            makeId: item?.makeId,
          });
          i++;
        }
        setModelData(arr);
      }
    });
  };

  const handleSelectModel = (model) => {
    setNewFilter((prevState) => ({
      ...prevState,
      modelName: model?.label,
      modelId: model?.value,
    }));
    getVehicleVariant({
      makeId: newFilter.makeId ? newFilter.makeId : motorRequest?.makeId,
      modelId: model?.value,
      vehicleType: motorRequest?.vehicleType,
    }).then((response) => {
      if (response.status === "success") {
        let data = response.data;
        let fuelTypeArr = data.filter(
          (v, i, a) => a.findIndex((v2) => v2.fuelType === v.fuelType) === i
        );
        let j = 0;
        let fuelarr = [];
        while (j < fuelTypeArr.length) {
          fuelarr.push(fuelTypeArr[j].fuelType);
          j++;
        }
        setFuelTypes(fuelarr);
        let i = 0;
        let arr = [];
        while (i < data.length) {
          let item = data[i];
          arr.push({
            make: item?.make,
            makeId: item?.makeId,
            model: item?.model,
            modelId: item?.modelId,
            label: `${item.variant}(${item.cc}cc)`,
            value: item.variantId,
            fuelType: item.fuelType,
            cubicCapacity: item.cc,
            seatingCapacity: item.seats,
          });
          i++;
        }
        setVariantData(arr);
      }
    });
  };

  const handleSelectPetrolType = (val) => {
    // dispatchQuickQuote("FuelType", val);
    setNewFilter((prevState) => ({ ...prevState, fuelType: val }));
  };

  const handleSelectVariant = (variant) => {
    setNewFilter((prevState) => ({
      ...prevState,
      variantId: variant?.value,
      fuelType: variant?.fuelType,
      variantName: variant?.label,
    }));
  };

  const handleUpdateMMV = () => {
    let newStructureData = { ...motorRequest };
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + 60);
    const regDate = new Date(
      newFilter?.registrationDate
        ? newFilter?.registrationDate
        : motorRequest?.registrationDate
    );
    const difference = Math.abs(currentDate - regDate);
    const diffInYears = parseFloat(
      (difference / (1000 * 60 * 60 * 24 * 365.25)).toFixed(3)
    );

    dispatchQuickQuote("differenceInYears", diffInYears);
    dispatchMotorQuote("differenceInYears", diffInYears);
    dispatchMotorQuote(
      "variantId",
      newFilter?.variantId ? newFilter?.variantId : newStructureData?.variantId
    );

    for (let key in newFilter) {
      if (newStructureData.hasOwnProperty(key)) {
        newStructureData[key] = newFilter[key];
        dispatchMotorQuote(`${key}`, newFilter[key]);
      }
    }

    if (motorRequest?.vehicleType === "4w") {
      if (diffInYears > 3) {
        dispatchMotorQuote("newPolicyType", POLICY_TYPE?.COMPREHENSIVE);
        newStructureData = {
          ...newStructureData,
          newPolicyType: POLICY_TYPE?.COMPREHENSIVE,
        };
        if (diffInYears > 4) {
          newStructureData = {
            ...newStructureData,
            prevPolicyType: POLICY_TYPE?.COMPREHENSIVE,
          };
        } else if (diffInYears < 4 && diffInYears > 3) {
          newStructureData = {
            ...newStructureData,
            prevPolicyType: POLICY_TYPE?.ODONLY,
          };
        }
      } else if (motorRequest?.isVehicleNew) {
        dispatchMotorQuote("newPolicyType", POLICY_TYPE?.COMPREHENSIVE);
        newStructureData = {
          ...newStructureData,
          newPolicyType: POLICY_TYPE?.COMPREHENSIVE,
        };
      } else {
        dispatchMotorQuote("newPolicyType", POLICY_TYPE?.ODONLY);
        newStructureData = {
          ...newStructureData,
          newPolicyType: POLICY_TYPE?.ODONLY,
        };
        if (diffInYears < 1) {
          newStructureData = {
            ...newStructureData,
            prevPolicyType: POLICY_TYPE?.COMPREHENSIVE,
          };
        } else if (diffInYears < 3 && diffInYears > 1) {
          newStructureData = {
            ...newStructureData,
            prevPolicyType: POLICY_TYPE?.ODONLY,
          };
        }
      }
    } else if (motorRequest?.vehicleType === "2w") {
      if (diffInYears > 5) {
        dispatchMotorQuote("newPolicyType", POLICY_TYPE?.COMPREHENSIVE);
        newStructureData = {
          ...newStructureData,
          newPolicyType: POLICY_TYPE?.COMPREHENSIVE,
        };
        if (diffInYears > 6) {
          newStructureData = {
            ...newStructureData,
            prevPolicyType: POLICY_TYPE?.COMPREHENSIVE,
          };
        } else if (diffInYears < 6 && diffInYears > 5) {
          newStructureData = {
            ...newStructureData,
            prevPolicyType: POLICY_TYPE?.ODONLY,
          };
        }
      } else if (motorRequest?.isVehicleNew) {
        dispatchMotorQuote("newPolicyType", POLICY_TYPE?.COMPREHENSIVE);
        newStructureData = {
          ...newStructureData,
          newPolicyType: POLICY_TYPE?.COMPREHENSIVE,
        };
      } else {
        dispatchMotorQuote("newPolicyType", POLICY_TYPE?.ODONLY);
        newStructureData = {
          ...newStructureData,
          newPolicyType: POLICY_TYPE?.ODONLY,
        };
        if (diffInYears < 1) {
          newStructureData = {
            ...newStructureData,
            prevPolicyType: POLICY_TYPE?.COMPREHENSIVE,
          };
        } else if (diffInYears < 5 && diffInYears > 1) {
          newStructureData = {
            ...newStructureData,
            prevPolicyType: POLICY_TYPE?.ODONLY,
          };
        }
      }
    }

    dispatchMotorQuote("idv", 0);

    newStructureData = { ...newStructureData, idv: 0 };

    filterPlanTypeList();
    dispatch(resetQuickQuoteResults());

    dispatchQuickQuote("initialIdvSet", false);

    motorQuotation(newStructureData);
    // handleFilteredResult(newData);
    // updateMMV();
    setModal(!show);
  };

  const handleMfgDate = (val) => {
    setNewFilter((prevState) => ({ ...prevState, manufaturingDate: val }));
    // dispatchQuickQuote("ManufaturingDate", val);
  };

  const handleMfgDateChange = (date) => {
    if (date) {
      const formattedDate = moment(date).format("YYYY-MM-DD");
      setManufacturingDate(date);
      handleMfgDate(formattedDate);
    } else {
      setManufacturingDate(null);
      handleMfgDate("");
    }
  };

  // Handle manual input change with mask
  const handleMfgInputChange = (event) => {
    const inputValue = event.target.value;

    if (/^\d{2}\/\d{2}\/\d{4}$/.test(inputValue)) {
      const [day, month, year] = inputValue.split("/");
      const date = new Date(`${year}-${month}-${day}`);

      if (!isNaN(date.getTime())) {
        const formattedDate = moment(date).format("YYYY-MM-DD");
        setManufacturingDate(date);
        handleMfgDate(formattedDate);
      }
    } else {
      setManufacturingDate(null);
      handleMfgDate("");
    }
  };

  // const handleRegDate = (val) => {
  //   const regYear = new Date(val).getFullYear();

  //   setNewFilter((prevState) => ({ ...prevState, registrationDate: val }));
  // };

  const handleRegDateChange = (date) => {
    if (date) {
      const formattedDate = moment(date).format("YYYY-MM-DD");
      setRegistrationDate(date);
      setNewFilter((prevState) => ({
        ...prevState,
        registrationDate: formattedDate,
      }));
    } else {
      setRegistrationDate(null);
      setNewFilter((prevState) => ({ ...prevState, registrationDate: "" }));
    }
  };

  // Handle manual input change with mask
  const handleRegInputChange = (event) => {
    const inputValue = event.target.value;

    if (/^\d{2}\/\d{2}\/\d{4}$/.test(inputValue)) {
      const [day, month, year] = inputValue.split("/");
      const date = new Date(`${year}-${month}-${day}`);

      if (!isNaN(date.getTime())) {
        const formattedDate = moment(date).format("YYYY-MM-DD");
        setRegistrationDate(date);
        setNewFilter((prevState) => ({
          ...prevState,
          registrationDate: formattedDate,
        }));
      }
    } else {
      setRegistrationDate(null);
      setNewFilter((prevState) => ({ ...prevState, registrationDate: "" }));
    }
  };

  const handlePrevPolicyEndDate = (val) => {
    setNewFilter((prevState) => ({ ...prevState, prevPolicyEndDate: val }));
  };

  const handlePrevPolicyEndDateChange = (date) => {
    if (date) {
      const formattedDate = moment(date).format("YYYY-MM-DD");
      setPrevPolicyEndDate(date);
      setNewFilter((prevState) => ({
        ...prevState,
        prevPolicyEndDate: formattedDate,
      }));
    } else {
      setPrevPolicyEndDate(null);
      setNewFilter((prevState) => ({ ...prevState, prevPolicyEndDate: "" }));
    }
  };

  // Handle manual input change with mask
  const handlePrevPolicyEndInputChange = (event) => {
    const inputValue = event.target.value;

    if (/^\d{2}\/\d{2}\/\d{4}$/.test(inputValue)) {
      const [day, month, year] = inputValue.split("/");
      const date = new Date(`${year}-${month}-${day}`);

      if (!isNaN(date.getTime())) {
        const formattedDate = moment(date).format("YYYY-MM-DD");
        setPrevPolicyEndDate(date);
        setNewFilter((prevState) => ({
          ...prevState,
          prevPolicyEndDate: formattedDate,
        }));
      }
    } else {
      setPrevPolicyEndDate(null);
      setNewFilter((prevState) => ({ ...prevState, prevPolicyEndDate: "" }));
    }
  };

  useEffect(() => {
    console.log("makeData", makeData);
  }, [makeData]);

  return (
    <>
      <Modal
        isOpen={show}
        toggle={toggle}
        centered
        size="md"
        backdrop="static"
        keyboard={false}
      >
        {/* <ModalHeader className="d-flex justify-content-between">
          
        </ModalHeader> */}
        <ModalBody>
          <div className="p-3">
            <div className="modal_heading w-100 mb-5">
              <h4 className="text-center fs-3">Edit Details</h4>
              <div onClick={toggle} class="v2_close false">
                <img
                  src="/assets/images/delete_icon.svg"
                  title="Close"
                  alt="close"
                />
              </div>
            </div>
            <div>
              <div className="row">
                <div className="did-floating-label-content">
                  <ReactSelect
                    value={makeData?.filter(
                      (option) => option.label === newFilter?.makeName
                    )}
                    options={makeData}
                    name="make"
                    placeholder="Select Manufacturer"
                    onChange={(val) => handleSelectMake(val)}
                    defaultValue={
                      newFilter?.makeName
                        ? newFilter?.makeName
                        : motorRequest?.makeName
                    }
                  />{" "}
                  <label htmlFor="idxx4" className="did-floating-label">
                    Manufacture
                  </label>
                </div>
                <div className="did-floating-label-content">
                  <ReactSelect
                    options={modelData}
                    name="model"
                    value={modelData?.filter(
                      (option) => option.label === newFilter?.modelName
                    )}
                    placeholder="Select vehicle modal"
                    onChange={(val) => handleSelectModel(val)}
                    defaultValue={
                      newFilter?.modelName
                        ? newFilter?.modelName
                        : motorRequest?.modelName
                    }
                  />
                  <label htmlFor="idxx4" className="did-floating-label">
                    Model
                  </label>
                </div>

                <div className="did-floating-label-content">
                  <ReactSelect
                    value={variantData?.filter(
                      (option) => option?.label === newFilter?.variantName
                    )}
                    options={variantData}
                    name="variant"
                    // options={variantData.filter(
                    //   (item) => item.Fuel_Type == newFilter.FuelType
                    // )}
                    placeholder="Select vehicle variant"
                    onChange={(val) => handleSelectVariant(val)}
                    defaultValue={
                      newFilter?.variantName
                        ? newFilter?.variantName
                        : motorRequest?.variantName
                    }
                  />
                  <label htmlFor="idxx4" className="did-floating-label">
                    Variant
                  </label>
                </div>
                <div className="did-floating-label-content">
                  <select
                    className="form-select text-capitalize"
                    defaultValue={
                      newFilter.fuelType
                        ? newFilter.fuelType
                        : motorRequest?.fuelType
                    }
                    onChange={(e) => handleSelectPetrolType(e.target.value)}
                  >
                    <option>Select Fuel Type</option>
                    {fuelTypes.map((fuel) => (
                      <option
                        selected={
                          newFilter?.fuelType === fuel
                            ? true
                            : motorRequest?.fuelType === fuel
                            ? true
                            : false
                        }
                        // className="text-capitalize"
                        value={fuel}
                      >
                        {fuel}
                      </option>
                    ))}
                  </select>
                  <label htmlFor="idxx4" className="did-floating-label">
                    Fuel Type
                  </label>
                </div>

                <div className="">
                  <div className="">
                    <div className="did-floating-label-content">
                      <DatePicker
                        className="did-floating-input w-100"
                        selected={manufacturingDate}
                        onChange={handleMfgDateChange}
                        customInput={
                          <ReactInputMask
                            mask="99/99/9999"
                            placeholder="DD/MM/YYYY"
                            value={
                              manufacturingDate
                                ? moment(manufacturingDate).format("DD/MM/YYYY")
                                : ""
                            }
                            onChange={handleMfgInputChange}
                            alwaysShowMask={false}
                          />
                        }
                        minDate={
                          motorRequest?.isVehicleNew
                            ? moment()
                                .subtract(1, "years")
                                .startOf("year")
                                .toDate()
                            : moment()
                                .subtract(24, "years")
                                .startOf("year")
                                .toDate()
                        }
                        maxDate={moment().toDate()}
                        dateFormat="dd/MM/yyyy"
                        placeholderText="Select Manufacture Date"
                      />
                      <label className="did-floating-label">
                        Manufacture Date
                      </label>
                    </div>
                  </div>
                  <div className="">
                    <div className="did-floating-label-content">
                      <DatePicker
                        selected={registrationDate}
                        onChange={handleRegDateChange}
                        customInput={
                          <ReactInputMask
                            mask="99/99/9999"
                            placeholder="DD/MM/YYYY"
                            value={
                              registrationDate
                                ? moment(registrationDate).format("DD/MM/YYYY")
                                : ""
                            }
                            onChange={handleRegInputChange}
                            alwaysShowMask={false}
                          />
                        }
                        className="w-100 did-floating-input"
                        minDate={
                          newFilter?.manufaturingDate
                            ? new Date(newFilter.manufaturingDate)
                            : null
                        }
                        maxDate={moment().toDate()}
                        dateFormat="dd/MM/yyyy"
                        placeholderText="Select Registration Date"
                      />
                      <label className="did-floating-label">
                        Registration Date
                      </label>
                    </div>
                  </div>
                  {motorRequest?.isVehicleNew === false &&
                    (motorRequest?.businessType ===
                      POLICY_STATUS.EXPIREDWITHIN90 ||
                      motorRequest?.businessType ===
                        POLICY_STATUS.NOTEXPIRED) && (
                      <div className="">
                        <div className="did-floating-label-content">
                          <DatePicker
                            selected={prevPolicyEndDate}
                            onChange={handlePrevPolicyEndDateChange}
                            customInput={
                              <ReactInputMask
                                mask="99/99/9999"
                                placeholder="DD/MM/YYYY"
                                value={
                                  prevPolicyEndDate
                                    ? moment(prevPolicyEndDate).format(
                                        "DD/MM/YYYY"
                                      )
                                    : ""
                                }
                                onChange={handlePrevPolicyEndInputChange}
                                alwaysShowMask={false}
                              />
                            }
                            className="w-100 did-floating-input"
                            minDate={
                              motorRequest?.businessType ===
                              POLICY_STATUS.EXPIREDWITHIN90
                                ? moment().subtract(90, "days").toDate()
                                : moment().subtract(0, "days").toDate()
                            }
                            maxDate={
                              motorRequest?.businessType ===
                              POLICY_STATUS.EXPIREDWITHIN90
                                ? moment().subtract(0, "days").toDate()
                                : moment().add(180, "days").toDate()
                            }
                            dateFormat="dd/MM/yyyy"
                            placeholderText="Select Previous Policy End Date"
                          />
                          <label className="did-floating-label">
                            Previous Policy End Date
                          </label>
                        </div>
                      </div>
                    )}

                  <div className="text-center">
                    <button
                      type="button"
                      onClick={() => handleUpdateMMV()}
                      className="btn btn-primary text-center"
                    >
                      Update
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default EditDetailsModal;
