import moment from "moment";

export const twoWheelerMake = [
  {
    image: "assets/images/logos/bikes/hero.png",
    label: "Hero MotoCorp",
    value: 106,
  },
  {
    image: "assets/images/logos/bikes/honda.png",
    label: "HONDA MOTORCYCLE AND SCOOTER INDIA LTD",
    value: 102,
  },
  {
    image: "assets/images/logos/bikes/bajaj.png",
    label: "Bajaj Auto",
    value: 112,
  },
  {
    image: "assets/images/logos/bikes/tvs.png",
    label: "TVS MOTOR",
    value: 105,
  },
  {
    image: "assets/images/logos/bikes/ROYAL_ENFIELD.png",
    label: "ROYAL ENFIELD",
    value: 107,
  },
  {
    image: "assets/images/logos/bikes/MAHINDRA.png",
    label: "Mahindra Two Wheelers Limited",
    value: 114,
  },
  // {
  //   image: "assets/images/logos/bikes/HEROHONDA.png",
  //   label: "HERO HONDA",
  //   value: 202,
  // },
  {
    image: "assets/images/logos/bikes/yamaha.png",
    label: "YAMAHA MOTOR PVT LTD",
    value: 111,
  },
  {
    image: "assets/images/logos/bikes/KTM.png",
    label: "KTM",
    value: 129,
  },
  {
    image: "assets/images/logos/bikes/jawa.png",
    label: "JAWA MOTORS",
    value: 143,
  },
  {
    image: "assets/images/logos/bikes/lml.png",
    label: "LML",
    value: 215,
  },
  {
    image: "assets/images/logos/bikes/HYOSUNG.png",
    label: "HYOSUNG",
    value: 118,
  },
  {
    image: "assets/images/logos/cars/bmw.png",
    value: 119,
    label: "BMW",
  },
  {
    image: "/assets/images/Ducati_red_logo.svg",
    value: 109,
    label: "DUCATI",
  },
  {
    image: "/assets/images/harley-davidson-logo.svg",
    value: 213,
    label: "HARLEY DAVIDSON",
  },
  {
    image: "/assets/images/beneli-logo.jpeg",
    value: 121,
    label: "BENELLI",
  },
];

export const fourWheelerModel = [
  {
    image: "assets/images/logos/cars/suzuki.png",
    value: 47,
    label: "MARUTI SUZUKI",
  },
  {
    image: "assets/images/logos/cars/tata.png",
    value: 77,
    label: "Tata Motors Limited",
  },
  {
    image: "assets/images/logos/cars/honda.png",
    value: 28,
    label: "HONDA",
  },
  {
    image: "assets/images/logos/cars/ford.png",
    value: 24,
    label: "FORD",
  },
  {
    image: "assets/images/logos/cars/hyundai.png",
    value: 30,
    label: "HYUNDAI MOTOR INDIA LTD.",
  },
  {
    image: "assets/images/logos/cars/kia.png",
    value: 36,
    label: "KIA MOTORS",
  },
  {
    image: "/assets/images/mahindra new logo.svg",
    value: 42,
    label: "Mahindra AND Mahindra Limited",
  },
  {
    image: "assets/images/logos/cars/mg.png",
    value: 57,
    label: "MORRIS GARAGES",
  },
  // {
  //   image: "assets/images/logos/cars/nissan.png",
  //   value: 441,
  //   label: "NISSAN",
  // },
  {
    image: "assets/images/logos/cars/renault.png",
    value: 67,
    label: "RENAULT",
  },
  {
    image: "assets/images/logos/cars/skoda.png",
    value: 72,
    label: "SKODA",
  },
  {
    image: "assets/images/logos/cars/toyota.png",
    value: 79,
    label: "TOYOTA",
  },
  {
    image: "assets/images/logos/cars/wv.png",
    value: 81,
    label: "VOLKSWAGEN",
  },
  {
    image: "assets/images/logos/cars/volvo.png",
    value: 82,
    label: "VOLVO",
  },
  {
    image: "assets/images/logos/cars/audi.png",
    value: 3,
    label: "AUDI",
  },
  {
    image: "assets/images/logos/cars/bmw.png",
    value: 7,
    label: "BMW",
  },
];

export function isWithinThreeYears(dateStr) {
  // Parse the given date string into a moment object
  const date = moment(dateStr, "YYYY-MM-DD");

  // Calculate the current date and the date three years ago
  const now = moment();
  const threeYearsAgo = moment().subtract(3, "years");

  // Check if the given date is between three years ago and now
  if (date.isBetween(threeYearsAgo, now)) {
    return true;
  } else {
    return false;
  }
}

export function isWithinFiveYears(dateStr) {
  // Parse the given date string into a moment object
  const date = moment(dateStr, "YYYY-MM-DD");

  // Calculate the current date and the date five years ago
  const now = moment();
  const fiveYearsAgo = moment().subtract(5, "years");

  // Check if the given date is between five years ago and now
  if (date.isBetween(fiveYearsAgo, now)) {
    return true;
  } else {
    return false;
  }
}

export const pvtCarPolicyInfoArr = [
  {
    label: "Select Policy Type",
    name: "PolicyStatus",
    type: "select",
    placeholder: "Policy Type",
    validation: {
      required: false,
    },
    data: [
      { value: "continue", option: "Continue" },
      { value: "expired within 90 day", option: "Expired Within 90 days" },
      { value: "expired above 90 day", option: "Expired Above 90 days" },
      { value: "false", option: "No Information About Previous Policy" },
    ],
  },
  {
    label: "New policy type?",
    name: "NewPolicyType",
    type: "select",
    placeholder: "New Policy Type",
    validation: {
      required: "Field is required",
    },
    data: [
      { value: "Comprehensive", option: "Comprehensive" },
      { value: "ThirdParty", option: "Third Party" },
      { value: "ODOnly", option: "Own Damage" },
    ],
  },
  {
    label: "Previous policy type?",
    name: "PreviousPolicyType",
    type: "select",
    placeholder: "Previous Policy Type",
    validation: {
      required: false,
    },
    data: [
      { value: "Comprehensive", option: "Comprehensive" },
      { value: "ThirdParty", option: "Third Party" },
    ],
  },
  {
    label: "Previous Policy Insurer",
    name: "PreInsurerCode",
    type: "select",
    placeholder: "Previous Policy Insurer",
    validation: {
      required: "Field is required",
    },
  },
  {
    label: "Mfg. month of the year",
    name: "ManufaturingDate",
    type: "date",
    validation: {
      require: "Field is required",
    },
  },
  {
    label: "Reg. date of your car",
    name: "RegistrationDate",
    type: "date",
    validation: {
      required: "Field is required",
    },
  },
  {
    label: "Prev. year policy expiry date",
    name: "PrePolicyEndDate",
    type: "date",
    validation: {
      required: "Field is required",
    },
  },
  {
    label: "Vehicle Owned By",
    name: "CustomerType",
    type: "select",
    data: [
      { value: "INDIVIDUAL", option: "Individual" },
      { value: "COMPANY", option: "Organization" },
    ],
    validation: {
      required: false,
    },
  },
  {
    label: "Previous No Claim Bonus (NCB)",
    name: "PreviousNoClaimBonus",
    type: "select",
    placeholder: "Previous No Claim Bonus (NCB)",
    validation: {
      required: false,
    },
    data: [
      { value: "0", option: "0%" },
      { value: "20", option: "20%" },
      { value: "25", option: "25%" },
      { value: "35", option: "35%" },
      { value: "45", option: "45%" },
      { value: "50", option: "50%" },
    ],
  },
  {
    label: "Claim made in expiring policy?",
    name: "PreviousInsuranceClaimed",
    type: "select",
    data: [
      { value: true, option: "Yes" },
      { value: false, option: "No" },
    ],
    validation: {
      required: false,
    },
  },
];

export const bikePolicyInfoArr = [
  {
    label: "Select Policy Type",
    name: "PolicyStatus",
    type: "select",
    placeholder: "Policy Type",
    validation: {
      required: false,
    },
    data: [
      { value: "continue", option: "Continue" },
      { value: "expired within 90 day", option: "Expired Within 90 days" },
      { value: "expired above 90 day", option: "Expired Above 90 days" },
      { value: "false", option: "No Information About Previous Policy" },
    ],
  },
  {
    label: "New policy type?",
    name: "NewPolicyType",
    type: "select",
    placeholder: "New Policy Type",
    validation: {
      required: "Field is required",
    },
    data: [
      { value: "Comprehensive", option: "Comprehensive" },
      { value: "ThirdParty", option: "Third Party" },
      { value: "ODOnly", option: "Own Damage" },
    ],
  },
  {
    label: "Previous policy type?",
    name: "PreviousPolicyType",
    type: "select",
    placeholder: "Previous Policy Type",
    validation: {
      required: false,
    },
    data: [
      { value: "Comprehensive", option: "Comprehensive" },
      { value: "ThirdParty", option: "Third Party" },
    ],
  },
  {
    label: "Previous Policy Insurer",
    name: "PreInsurerCode",
    type: "select",
    placeholder: "Previous Policy Insurer",
    validation: {
      required: "Field is required",
    },
  },
  {
    label: "Mfg. month of the year",
    name: "ManufaturingDate",
    type: "date",
    validation: {
      require: "Field is required",
    },
  },
  {
    label: "Reg. date of your car",
    name: "RegistrationDate",
    type: "date",
    validation: {
      required: "Field is required",
    },
  },
  {
    label: "Prev. year policy expiry date",
    name: "PrePolicyEndDate",
    type: "date",
    validation: {
      required: "Field is required",
    },
  },
  {
    label: "Previous No Claim Bonus (NCB)",
    name: "PreviousNoClaimBonus",
    type: "select",
    placeholder: "Previous No Claim Bonus (NCB)",
    validation: {
      required: false,
    },
    data: [
      { value: "0", option: "0%" },
      { value: "20", option: "20%" },
      { value: "25", option: "25%" },
      { value: "35", option: "35%" },
      { value: "45", option: "45%" },
      { value: "50", option: "50%" },
    ],
  },
  {
    label: "Vehicle Owned By",
    name: "CustomerType",
    type: "select",
    data: [
      { value: "INDIVIDUAL", option: "Individual" },
      { value: "COMPANY", option: "Organization" },
    ],
    validation: {
      required: false,
    },
  },
  {
    label: "Claim made in expiring policy?",
    name: "PreviousInsuranceClaimed",
    type: "select",
    data: [
      { value: true, option: "Yes" },
      { value: false, option: "No" },
    ],
    validation: {
      required: false,
    },
  },
];

export const pcvVehiclePolicyInfoArr = [
  {
    label: "Select Policy Type",
    name: "PolicyStatus",
    type: "select",
    placeholder: "Policy Type",
    validation: {
      required: false,
    },
    data: [
      { value: "continue", option: "Continue" },
      { value: "expired within 90 day", option: "Expired Within 90 days" },
      { value: "expired above 90 day", option: "Expired Above 90 days" },
      { value: "false", option: "No Information About Previous Policy" },
    ],
  },
  {
    label: "New policy type?",
    name: "NewPolicyType",
    type: "select",
    placeholder: "New Policy Type",
    validation: {
      required: "Field is required",
    },
    data: [
      { value: "Comprehensive", option: "Comprehensive" },
      { value: "ThirdParty", option: "Third Party" },
      { value: "ODOnly", option: "Own Damage" },
    ],
  },
  {
    label: "Previous policy type?",
    name: "PreviousPolicyType",
    type: "select",
    placeholder: "Previous Policy Type",
    validation: {
      required: false,
    },
    data: [
      { value: "Comprehensive", option: "Comprehensive" },
      { value: "ThirdParty", option: "Third Party" },
    ],
  },
  {
    label: "Previous Policy Insurer",
    name: "PreInsurerCode",
    type: "select",
    placeholder: "Previous Policy Insurer",
    validation: {
      required: "Field is required",
    },
  },
  {
    label: "Mfg. month of the year",
    name: "ManufaturingDate",
    type: "date",
    validation: {
      require: "Field is required",
    },
  },
  {
    label: "Reg. date of your car",
    name: "RegistrationDate",
    type: "date",
    validation: {
      required: "Field is required",
    },
  },
  {
    label: "Prev. year policy expiry date",
    name: "PrePolicyEndDate",
    type: "date",
    validation: {
      required: "Field is required",
    },
  },
  {
    label: "Previous No Claim Bonus (NCB)",
    name: "PreviousNoClaimBonus",
    type: "select",
    placeholder: "Previous No Claim Bonus (NCB)",
    validation: {
      required: false,
    },
    data: [
      { value: "0", option: "0%" },
      { value: "20", option: "20%" },
      { value: "25", option: "25%" },
      { value: "35", option: "35%" },
      { value: "45", option: "45%" },
      { value: "50", option: "50%" },
    ],
  },
  {
    label: "Claim made in expiring policy?",
    name: "PreviousInsuranceClaimed",
    type: "radio",
    option1: "Yes",
    option2: "No",
    value1: "Yes",
    value2: "No",
    data: [
      { value: true, option: "Yes" },
      { value: false, option: "No" },
    ],
    validation: {
      required: false,
    },
  },

  {
    label: "Number Of Wheels",
    name: "number_of_wheels",
    type: "number",
    validation: {
      required: "Field is required",
    },
  },
  {
    label: "Seating Capacity",
    name: "seating_capacity",
    type: "number",
    validation: {
      required: "Field is required",
    },
  },
];

export const gcvVehiclePolicyInfoArr = [
  {
    label: "Select Policy Type",
    name: "PolicyStatus",
    type: "select",
    placeholder: "Policy Type",
    validation: {
      required: false,
    },
    data: [
      { value: "continue", option: "Continue" },
      { value: "expired within 90 day", option: "Expired Within 90 days" },
      { value: "expired above 90 day", option: "Expired Above 90 days" },
      { value: "false", option: "No Information About Previous Policy" },
    ],
  },
  {
    label: "New policy type?",
    name: "NewPolicyType",
    type: "select",
    placeholder: "New Policy Type",
    validation: {
      required: "Field is required",
    },
    data: [
      { value: "Comprehensive", option: "Comprehensive" },
      { value: "ThirdParty", option: "Third Party" },
      { value: "ODOnly", option: "Own Damage" },
    ],
  },
  {
    label: "Previous policy type?",
    name: "PreviousPolicyType",
    type: "select",
    placeholder: "Previous Policy Type",
    validation: {
      required: false,
    },
    data: [
      { value: "Comprehensive", option: "Comprehensive" },
      { value: "ThirdParty", option: "Third Party" },
    ],
  },
  {
    label: "Previous Policy Insurer",
    name: "PreInsurerCode",
    type: "select",
    placeholder: "Previous Policy Insurer",
    validation: {
      required: false,
    },
  },
  {
    label: "Mfg. month of the year",
    name: "ManufaturingDate",
    type: "date",
    validation: {
      require: "Field is required",
    },
  },
  {
    label: "Reg. date of your car",
    name: "RegistrationDate",
    type: "date",
    validation: {
      required: "Field is required",
    },
  },
  {
    label: "Prev. year policy expiry date",
    name: "PrePolicyEndDate",
    type: "date",
    validation: {
      required: false,
    },
  },
  {
    label: "Previous No Claim Bonus (NCB)",
    name: "PreviousNoClaimBonus",
    type: "select",
    placeholder: "Previous No Claim Bonus (NCB)",
    validation: {
      required: false,
    },
    data: [
      { value: "0", option: "0%" },
      { value: "20", option: "20%" },
      { value: "25", option: "25%" },
      { value: "35", option: "35%" },
      { value: "45", option: "45%" },
      { value: "50", option: "50%" },
    ],
  },
  {
    label: "Vehicle Owned By",
    name: "CustomerType",
    type: "select",
    data: [
      { value: "INDIVIDUAL", option: "Individual" },
      { value: "COMPANY", option: "Organization" },
    ],
    validation: {
      required: false,
    },
  },
  {
    label: "Claim made in expiring policy?",
    name: "PreviousInsuranceClaimed",
    type: "select",
    data: [
      { value: true, option: "Yes" },
      { value: false, option: "No" },
    ],
    validation: {
      required: false,
    },
  },

  {
    label: "Number Of Wheels",
    name: "number_of_wheels",
    type: "number",
    validation: {
      required: "Field is required",
    },
  },
  {
    label: "Gross Weight",
    name: "seating_capacity",
    type: "number",
    validation: {
      required: "Field is required",
    },
  },
  {
    label: "Carrier Type",
    name: "carrier_type",
    type: "select",
    placeholder: "Carrier Type",
    validation: {
      required: "Field is required",
    },
    data: [
      { value: "Public", option: "Puclic" },
      { value: "Private", option: "Private" },
    ],
  },
];

export const Documents = [
  {
    id: 1,
    option: "RC-Front",
    name: "rc_front",
    type: "file",
  },
  {
    id: 2,
    option: "RC-Back",
    name: "rc_back",
    type: "file",
  },
  {
    id: 3,
    option: "Insurance-01",
    name: "insurance_01",
    type: "file",
  },
  {
    id: 4,
    option: "Insurance-02",
    name: "insurance_02",
    type: "file",
  },
  {
    id: 5,
    option: "29/30 Sales Latter",
    name: "sales_letter",
    type: "file",
  },
];

export const policyResultArr = (
  Logo,
  Api_name,
  PolicyId,
  ApiId,
  RegisterNumber,
  Make,
  Model,
  variant,
  BasicODPremium,
  BasicTPPremium,
  NetPremium,
  GST,
  FinalPremium,
  MinIdv,
  MaxIdv,
  idv,
  StartDate,
  EndDate,
  discount,
  insurer,
  policyType,
  RoadSideAssistance,
  RoadSideAssistanceAmount,
  EngineProtection,
  EngineProtectionAmount,
  TyreProtection,
  TyreProtectionAmount,
  RimProtection,
  RimProtectionAmount,
  Consumables,
  ConsumablesAmount,
  IsElectricalAccessories,
  ElectricAmount,
  IsNonElectricalAccessories,
  nonElectricalAmount,
  InvoiceCover,
  InvoiceCoverAmount,
  NCBProtection,
  NCBProtectionAmount,
  VoluntaryDeductive,
  VoluntaryAmount,
  PassengerCover,
  PassengerCoverAmount,
  PAForOwner,
  PaCoverAmount,
  LossOfPersonalBelongings,
  LossOfPersonalBelongingsAmount,
  ZeroDepriciationCover,
  ZeroDepriciationCoverAmount,
  KeyReplacement,
  KeyReplacementAmount,
  NCBDiscount,
  BiFuelOd,
  BiFuelOdAmount,
  BiFuelTp,
  BiFuelTpAmount,
  liabilitiesToPaidDriver,
  liabilitiesToPaidDriverAmount,
  caseId,
  insurerId,
  kycAdditionalData,
  planId,
  tppdDiscount,
  loadingPremium,
  isAddonQuesAsk,
  addonQuesArray,
  isAdharRequiredInProposal,
  isPanRequiredInProposal,
  totalODAddons,
  isGstNumberRequiredInProposal,
  llToEmployeeCover,
  llToEmployeeCoverAmount
  // SompoDiscountRate,
  // SompoLoadingRate
) => {
  let start = moment(StartDate).format("yyyy");
  let end = moment(EndDate).format("yyyy");
  // debugger;
  return {
    Logo: Logo,
    Api_name: Api_name,
    PolicyId: PolicyId,
    ApiId: ApiId,
    RegisterNumber: RegisterNumber,
    Make: Make,
    Model: Model,
    variant: variant,
    BasicODPremium: BasicODPremium,
    BasicTPPremium: BasicTPPremium,
    NetPremium: NetPremium,
    GST: GST,
    FinalPremium: FinalPremium,
    MinIdv: MinIdv,
    MaxIdv: MaxIdv,
    idv: idv,
    StartDate: StartDate,
    EndDate: EndDate,
    discount: discount,
    insurer: insurer,
    policyType: policyType,
    RoadSideAssistance: RoadSideAssistance,
    RoadSideAssistanceAmount: RoadSideAssistanceAmount,
    EngineProtection: EngineProtection,
    EngineProtectionAmount: EngineProtectionAmount,
    TyreProtection: TyreProtection,
    TyreProtectionAmount: TyreProtectionAmount,
    RimProtection: RimProtection,
    RimProtectionAmount: RimProtectionAmount,
    Consumables: Consumables,
    ConsumablesAmount: ConsumablesAmount,
    IsElectricalAccessories: IsElectricalAccessories,
    ElectricAmount: ElectricAmount,
    IsNonElectricalAccessories: IsNonElectricalAccessories,
    nonElectricalAmount: nonElectricalAmount,
    InvoiceCover: InvoiceCover,
    InvoiceCoverAmount: InvoiceCoverAmount,
    NCBProtection: NCBProtection,
    NCBProtectionAmount: NCBProtectionAmount,
    VoluntaryDeductive: VoluntaryDeductive,
    VoluntaryAmount: VoluntaryAmount,
    PassengerCover: PassengerCover,
    PassengerCoverAmount: PassengerCoverAmount,
    PersonalAccident: PAForOwner,
    PaCoverAmount: PaCoverAmount,
    LossOfPersonalBelongings: LossOfPersonalBelongings,
    LossOfPersonalBelongingsAmount: LossOfPersonalBelongingsAmount,
    ZeroDepriciationCover: ZeroDepriciationCover,
    ZeroDepriciationCoverAmount: ZeroDepriciationCoverAmount,
    KeyReplacement: KeyReplacement,
    KeyReplacementAmount: KeyReplacementAmount,
    NCBDiscount: NCBDiscount,
    BiFuelOd: BiFuelOd,
    BiFuelOdAmount: BiFuelOdAmount,
    BiFuelTp: BiFuelTp,
    BiFuelTpAmount: BiFuelTpAmount,
    liabilitiesToPaidDriver: liabilitiesToPaidDriver,
    liabilitiesToPaidDriverAmount: liabilitiesToPaidDriverAmount,
    caseId: caseId,
    insurerId: insurerId,
    kycAdditionalData: kycAdditionalData,
    planId: planId,
    tppdDiscount: tppdDiscount,
    loadingPremium: loadingPremium,
    isAddonQuesAsk: isAddonQuesAsk,
    addonQuesArray: addonQuesArray,
    isAdharRequiredInProposal: isAdharRequiredInProposal,
    isPanRequiredInProposal: isPanRequiredInProposal,
    TotalODAddons: totalODAddons,
    isGstNumberRequiredInProposal: isGstNumberRequiredInProposal,
    LlToEmployeeCover: llToEmployeeCover,
    LlToEmployeeCoverAmount: llToEmployeeCoverAmount
  };
};

export const failureCaseArr = (Api_name, logo, displayMessage, insurerId) => {
  return {
    Api_name: Api_name,
    logo: logo,
    displayMessage: displayMessage,
    insurerId: insurerId,
  };
};
