export const mastersEndPoint = {
  make: "admin/getAllMakeData",
  model: "admin/getAllModelData",
  variant: "admin/getAllVariantData",
  rto: "motor/rto",
  previousInsurer: "motor/previous-insurer",
  pincode: "motor/pincode",
  pincodeHealth: "health/health_pincode",
  state: "motor/states",
  bajajMMV: "motor/getBajajMMV",
  hdfcChecksum: "motor/hdfcChecksum",
  getFiancierBanks: "motor/getFiancierBanks",
  getRtoDetails: "motor/getRC",
};
