import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Header from "./common/Header";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import ReactSelect from "./Tags/ReactSelectProposal";
import { getVehiclePincodeHealth } from "./services/masterServices";
import {
  dispatchHealthQuickQuote,
  dispatchProcessFlowHealth,
  selectedPlanAction,
} from "../store/action/userActions";
import {
  careKyc,
  carePayment,
  createCustomerHealth,
  issueHealthPolicy,
  processFlow,
  sompoHealthIssuePolicy,
  sompoKYC,
  sompoPaymentApiHealth,
} from "./services/TPApiCall";
import { PostDataWithToken } from "../api/apiHelper";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { Remarkable } from "remarkable";
import Layout from "./common/Layout";
import moment from "moment";
import Care from "./HealthQuestions/Care";

const HealthProposal = () => {
  const {
    handleSubmit: handleSubmit1,
    register: register1,
    formState: { errors: errors1 },
    reset: reset1,
    setValue,
    getValues,
    watch,
    clearErrors: clearErrors1,
  } = useForm({ mode: "onBlur" });
  const {
    handleSubmit: handleSubmit2,
    register: register2,
    formState: { errors: errors2 },
    watch: watch1,
    setValue: setValue1,
    getValues: getValues1,
    reset: reset2,
    clearErrors: clearErrors2,
  } = useForm({ mode: "onBlur" });
  const {
    handleSubmit: handleSubmit3,
    register: register3,
    formState: { errors: errors3 },
    watch: watch3,
    reset: reset3,
    clearErrors: clearErrors3,
  } = useForm({ mode: "onBlur" });
  const {
    handleSubmit: handleSubmit4,
    register: register4,
    formState: { errors4 },
    reset: reset4,
    clearErrors: clearErrors4,
  } = useForm({ mode: "onBlur" });
  const healthRequestQQ = useSelector((state) => state.root.healthRequestQQ);
  const selectedPlan = useSelector((state) => state.root.selectedPlan);
  const processFlowObjHealth = useSelector(
    (state) => state?.root?.processFlowObjHealth
  );
  const [currentStep, setCurrentStep] = useState(1);
  const [pincodeData, setPincodeData] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState({});
  const [noneChecked, setNoneChecked] = useState({});
  const [policyOverview, setPolicyOverview] = useState(false);
  const [modal, setModal] = useState(false);
  const [modalKyc, setModalKyc] = useState(false);
  const [modalKYCError, setModalKYCError] = useState(false);
  const [updatedPremium, setUpdatedPremium] = useState(false);
  const [modalDisease, setModalDisease] = useState(false);
  const [kycSpinner, setKycSpinner] = useState(false);
  const [showIssuePolicy, setShowIssuePolicy] = useState(true);
  const [kycMessage, setKycMessage] = useState(false);
  const [kycResponse, setKycResponse] = useState(null);
  const [careKycResponse, setCareKycResponse] = useState(null);
  const [activeStep2, setActiveStep2] = useState(false);
  const [activeStep3, setActiveStep3] = useState(false);
  const [proceedPayment, setProceedPayment] = useState(false);
  const [pincodeDetails, setPincodeDetails] = useState(null);
  const [minDate, setMinDate] = useState(null);
  const [maxDate, setMaxDate] = useState(null);
  const [selectPlan, setSelectPlan] = useState(null);
  const [previousPremium, setPreviousPremium] = useState({});
  const [manualKycReferenceNumber, setManualKycReferenceNumber] =
    useState(false);
  const [someStateValue, setSomeStateValue] = useState("");
  const [dobError, setDobError] = useState(false);
  const [resultedPlan, setResultedPlan] = useState([]);

  const [step1Proceed, setStep1Proceed] = useState(false);
  const [height, setHeight] = useState("");
  const [weight, setWeight] = useState("");
  const [bmi, setBMI] = useState("");
  const [bmiLimit, setBmiLimit] = useState(false);
  const [dateOFBirth, setDateOfBirth] = useState(false);
  const [healthIsnurer, setHealthInsurer] = useState();
  const location = useLocation();

  useEffect(() => {
    setHealthInsurer(location?.state);
  }, [location?.state]);

  const dispatch = useDispatch();

  useEffect(() => {
    getVehiclePincodeHealth()
      .then((response) => {
        if (response.status === true) {
          let i = 0;
          let arr = [];
          let arr1 = [];
          let j = 0;
          while (i < response.data.length) {
            arr.push({
              value: response.data[i].Pincode,
              label:
                response.data[i].Pincode + " - " + response.data[i].District,
              District: response.data[i].District,
              City: response.data[i].City,
              State: response.data[i].State,
            });

            i++;
          }
          while (j < response.data.length) {
            arr1.push({
              value: response.data[j].Pin_Code,
              label: response.data[j].District,
              District: response.data[j].District,
              City: response.data[j].City,
            });

            j++;
          }
          setPincodeData(arr);
          // setCityData(arr1);
        }
      })
      .catch((err) => console.log(err));

    const currentDate = new Date();
    const minnDate = new Date(
      currentDate.getFullYear() - 18,
      currentDate.getMonth(),
      currentDate.getDate()
    );

    setPreviousPremium(selectedPlan?.PremiumAmount);
    setSelectPlan(selectedPlan?.planType);

    setMinDate(minnDate);

    const maxxDate = new Date(
      currentDate.getFullYear() - 55,
      currentDate.getMonth(),
      currentDate.getDate()
    );
    setMaxDate(maxxDate);
  }, []);

  useEffect(() => {
    // Set initial values when the component mounts
    const sameAddress = watch("sameAddress");
    if (sameAddress) {
      setValue(
        "PermanentAddress.address_1",
        watch("CommunicationAddress.address_1")
      );
      setValue(
        "PermanentAddress.address_2",
        watch("CommunicationAddress.address_2")
      );
      setValue("PermanentAddress.city", watch("CommunicationAddress.city"));
      setValue("PermanentAddress.state", watch("CommunicationAddress.state"));
      clearErrors1("PermanentAddress.city");
      clearErrors1("PermanentAddress.state");
    }
  }, []);

  const handlePincodeChange = (val) => {
    setPincodeDetails(val);
    setValue("PermanentAddress.city", val?.City);
    setValue("PermanentAddress.state", val?.State);
    clearErrors1("PermanentAddress.city");
    clearErrors1("PermanentAddress.state");
    dispatchHealthQuickQuote(
      "CustomerDetails.customerAddress.permanentAddress.pincode",
      val.value
    );
    dispatchHealthQuickQuote(
      "CustomerDetails.customerAddress.permanentAddress.state",
      val.State
    );
    dispatchHealthQuickQuote(
      "CustomerDetails.customerAddress.permanentAddress.city",
      val.City
    );
  };

  const handleNextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const handlePreviousStep = () => {
    setCurrentStep(currentStep - 1);
  };

  const handleCheckboxChange = () => {
    const sameAddress = !watch("sameAddress");

    setValue("sameAddress", sameAddress);

    if (sameAddress) {
      setValue(
        "PermanentAddress.address_1",
        watch("CommunicationAddress.address_1")
      );
      setValue(
        "PermanentAddress.address_2",
        watch("CommunicationAddress.address_2")
      );

      dispatchHealthQuickQuote(
        "CustomerDetails.customerAddress.permanentAddress.city",
        healthRequestQQ?.CustomerDetails?.customerAddress?.communicationAddress
          ?.city
      );
      dispatchHealthQuickQuote(
        "CustomerDetails.customerAddress.permanentAddress.state",
        healthRequestQQ?.CustomerDetails?.customerAddress?.communicationAddress
          ?.state
      );
      clearErrors1("PermanentAddress.address_1");
      clearErrors1("PermanentAddress.address_2");
      clearErrors1("PermanentAddress.city");
      clearErrors1("PermanentAddress.state");
      clearErrors1("PermanentAddress.pincode");
    } else {
      setValue("PermanentAddress.address_1", "");
      setValue("PermanentAddress.address_2", "");
      setValue("PermanentAddress.city", "");
      setValue("PermanentAddress.state", "");
    }
  };

  const handleCommunicationChange = (field) => (e) => {
    clearErrors1("CommunicationAddress.address_1");
    clearErrors1("CommunicationAddress.address_2");
    const sameAddress = watch("sameAddress");

    if (sameAddress) {
      setValue(`PermanentAddress.${field}`, e.target.value);
      clearErrors1("PermanentAddress.city");
      clearErrors1("PermanentAddress.state");
    }
  };

  const calculateAge = (dateOfBirth) => {
    const dob = new Date(dateOfBirth);
    const current = new Date();

    let age = current.getFullYear() - dob.getFullYear();
    return age;
  };

  useEffect(() => {
    const plan = resultedPlan[0];
    const newData = { ...plan, planType: selectPlan };
    if (plan !== undefined) {
      dispatch(selectedPlanAction(newData));
    }
  }, [resultedPlan, selectPlan]);

  const submitPersonalDetails = async (data) => {
    setStep1Proceed(true);
    console.log(getValues());

    const Age = calculateAge(data?.customerDOB);

    // if (Age > 55) {
    //   // Display an error message for age exceeding the limit
    //   console.error("Age exceeds the maximum allowed age");
    //   setDateOfBirth(true);

    //   setStep1Proceed(false); // Stop the form submission
    //   window.scrollTo({
    //     top: 80,
    //     behavior: "smooth",
    //   });
    //   return;
    // }

    if (bmiLimit === false) {
      if (Age !== healthRequestQQ?.CustomerDetails?.customerAge) {
        dispatchHealthQuickQuote("CustomerDetails.customerAge", Age);
        const createCustomer = await createCustomerHealth(healthRequestQQ);
        let healthQuoteResults = createCustomer?.data?.successQuotes;

        if (selectPlan === "A Plus Silver") {
          setResultedPlan(
            healthQuoteResults.filter(
              (item) => item.planType === "A Plus Silver"
            )
          );
          setStep1Proceed(false);
          setUpdatedPremium(true);
        } else if (selectPlan === "A Plus Gold") {
          setResultedPlan(
            healthQuoteResults.filter((item) => item.planType === "A Plus Gold")
          );
          setStep1Proceed(false);
          setUpdatedPremium(true);
        } else if (selectPlan === "A Plus Diamond") {
          setResultedPlan(
            healthQuoteResults.filter(
              (item) => item.planType === "A Plus Diamond"
            )
          );
          setStep1Proceed(false);
          setUpdatedPremium(true);
        }
      }
    }

    const communicationAddress = data.CommunicationAddress;
    const permanentAddress = data.PermanentAddress;
    const otherDetails = { ...data };
    delete otherDetails.CommunicationAddress;
    delete otherDetails.PermanentAddress;
    for (let key in otherDetails) {
      dispatchHealthQuickQuote(`CustomerDetails.${key}`, otherDetails[key]);
    }
    for (let key in communicationAddress) {
      dispatchHealthQuickQuote(
        `CustomerDetails.customerAddress.communicationAddress.${key}`,
        communicationAddress[key]
      );
    }

    dispatchHealthQuickQuote(
      `CustomerDetails.customerAddress.permanentAddress.address_1`,
      permanentAddress.address_1
    );
    dispatchHealthQuickQuote(
      `CustomerDetails.customerAddress.permanentAddress.address_2`,
      permanentAddress.address_2
    );
    dispatchHealthQuickQuote(
      `CustomerDetails.customerAddress.communicationAddress.pincode`,
      healthRequestQQ?.CustomerDetails?.customerPincode
    );

    const formattedDate = new Date(data?.customerDOB).toLocaleDateString(
      "en-GB"
    );
    dispatchHealthQuickQuote("CustomerDetails.customerDOB", formattedDate);
    // for (let key in permanentAddress) {
    //   dispatchHealthQuickQuote(
    //     `CustomerDetails.customerAddress.permanentAddress.${key}`,
    //     communicationAddress[key]
    //   );
    //   console.log(
    //     `CustomerDetails.customerAddress.permanentAddress.${key}`,
    //     communicationAddress[key]
    //   );
    // }
    // handleNextStep();
    setStep1Proceed(false);
    if (bmiLimit === false) {
      setStep1Proceed(false);
      setCurrentStep(2);
      setActiveStep2(true);
    }
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    const postData = {
      ...processFlowObjHealth,
      step: "step5",
      step5: { ...healthRequestQQ?.CustomerDetails },
    };
    processFlow({ processFlow: postData });
    dispatchProcessFlowHealth("step5", postData.step5);
  };

  const userHeight = (value) => {
    const heightInCm = value * 30.48;
    setHeight(heightInCm);
  };

  const userWeight = (value) => {
    setWeight(value);
  };

  const calculateBMI = () => {
    setBmiLimit(false);
    if (height && weight) {
      const BMII = (weight / Math.pow(height / 100, 2)).toFixed(2);
      dispatchHealthQuickQuote("CustomerDetails.customerBMI", BMII);
      setBMI(BMII);
      if (BMII >= 33 || BMII <= 16) {
        setBmiLimit(true);
      } else {
        setBmiLimit(false);
      }
    }
  };

  useEffect(() => {
    calculateBMI();
  }, [height, weight]);

  // Step 2

  const submitMedicalHistory = (data) => {
    if (healthIsnurer === "Sompo") {
      for (let key in data) {
        dispatchHealthQuickQuote(
          `CustomerDetails.medicalHistory.${key}`,
          data[key]
        );

        dispatchProcessFlowHealth(`step6.medicalHistory.${key}`, data[key]);
      }
    }
    const postDataaa = {
      ...processFlowObjHealth,
      step: "step6",
      step6: { medicalHistory: { ...data } },
    };

    processFlow({ processFlow: postDataaa });
    const disease = healthRequestQQ?.CustomerDetails?.medicalHistory;
    if (healthIsnurer === "Sompo") {
      if (
        disease?.IllnessOrDisease === "true" ||
        disease?.TreatmentOrMedicine === "true" ||
        disease?.VisitedDoctor === "true" ||
        disease?.Hospitalized === "true" ||
        disease?.Narcotics === "true" ||
        disease?.TakenAnyTreatment === "true" ||
        (disease?.Alcohol === "true" &&
          (disease?.AlcoholQuantity === "greater than 500 ml" ||
            disease?.AlcoholDuration === "greater than 5 years")) ||
        (disease?.Smoker === "true" &&
          (disease?.SmokerQuantity === "greater than 5 per day" ||
            disease?.SmokerDuration === "greater than 5 years)")) ||
        (disease?.Tobacco === "true" &&
          (disease?.TobaccoQuantity === "greater than 10 gms - 1 Pouse/day" ||
            disease?.TobaccoDuration === "greater than 5 years"))
      ) {
        setModalDisease(true);
      }
    }

    handleNextStep();
    setActiveStep3(true);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  // STEP 3

  const handleKycDetails = async (data) => {
    // console.log("DOB ENTERED", healthRequestQQ?.CustomerDetails?.customerDOB);
    // const currentDateOfBirth = new Date(data?.customerDOB).toLocaleDateString(
    //   "en-GB"
    // );
    // console.log("currentDateOfBirth", currentDateOfBirth);
    // const previousDateOfBirth = healthRequestQQ?.CustomerDetails?.customerDOB;
    // console.log("previousDateOfBirth", previousDateOfBirth);

    // if (currentDateOfBirth !== previousDateOfBirth) {
    //   setDobError(true);
    //   return;
    // }
    setKycSpinner(true);
    const inputDate = data.customerDOB;
    const formatedDate = new Date(inputDate).toLocaleDateString("en-GB");

    dispatchHealthQuickQuote(
      `CustomerDetails.customerKYCIDType`,
      data?.KYCIDType
    );
    // dispatchHealthQuickQuote(`CustomerDetails.customerDOB`, formatedDate);
    dispatchHealthQuickQuote(
      `CustomerDetails.customerKYCIDNumber`,
      data?.customerKYCIDNumber
    );

    if (selectedPlan.Company === "care Health") {
      const response = await careKyc(healthRequestQQ);
      if (
        response?.success === true &&
        response?.message === "ckyc done for care"
      ) {
        const ckycNUmberCare =
          response?.data?.getCkycEkycInputIO?.kycDetails
            ?.personalIdentifiableData?.personalDetails?.ckycNo;

        dispatchHealthQuickQuote("ckycNumberCare", ckycNUmberCare);
        setCareKycResponse(ckycNUmberCare);
        setKycSpinner(false);
        setShowIssuePolicy(false);
      }
    } else if (
      selectedPlan.Company === "Universal Sompo General Health Insurance"
    ) {
      const response = await sompoKYC(healthRequestQQ);

      if (
        response?.success === true &&
        response?.message === "data fetched in ckyc response"
      ) {
        setKycResponse(response?.data?.result);
        setKycMessage(true);
        setKycSpinner(false);
        setShowIssuePolicy(false);
        // compareKycName();
      } else if (
        response?.success === true &&
        response?.message === "Redirect to Manual KYC"
      ) {
        window.open(response?.data, "_blank");
        setKycSpinner(false);
        setManualKycReferenceNumber(true);
        reset3();
        dispatchHealthQuickQuote("CustomerDetails.customerKYCIDNumber", "");
      } else if (
        response?.message === "Status fetched for kyc" &&
        response?.data?.status === "success"
      ) {
        setShowIssuePolicy(false);
        setKycResponse(response?.data?.result);
        setKycSpinner(false);

        setKycMessage(true);
        // compareKycName();
        // setModalKyc(true);
      } else {
        setKycSpinner(false);
        setModalKYCError(true);
      }
    }
    const postData = {
      ...processFlowObjHealth,
      step: "step7",
      step7: { ...healthRequestQQ?.CustomerDetails },
    };
    delete postData.customerDetails;
    processFlow({ processFlow: postData });
    dispatchProcessFlowHealth("step7", healthRequestQQ?.CustomerDetails);
  };

  useEffect(() => {
    if (kycResponse) {
      compareKycName();
    }
  }, [kycResponse]);

  const compareKycName = () => {
    if (kycResponse?.firstName) {
      if (
        (healthRequestQQ?.CustomerDetails?.customerFirstName).toLowerCase() !==
        (kycResponse?.firstName).toLowerCase()
      ) {
        setModalDisease(true);
      }
    } else {
      const firstNameeee = kycResponse?.name.split(" ")[0];

      if (
        healthRequestQQ?.CustomerDetails?.customerFirstName !== firstNameeee
      ) {
        setModalDisease(true);
      }
    }
  };

  const handleIssuePolicy = async (data) => {
    setProceedPayment(true);
    const NomineeDateOfBirth = data?.NomineeDOB;
    const formatedNomineeDOB = new Date(NomineeDateOfBirth).toLocaleDateString(
      "en-GB"
    );

    dispatchHealthQuickQuote(`NomineeDetails.NomineeDOB`, formatedNomineeDOB);
    dispatchHealthQuickQuote(`NomineeDetails.NomineeName`, data?.NomineeName);
    dispatchHealthQuickQuote(
      `NomineeDetails.NomineeGender`,
      data?.NomineeGender
    );
    dispatchHealthQuickQuote(
      `NomineeDetails.NomineeRelation`,
      data?.NomineeRelation
    );

    const postData = {
      ...processFlowObjHealth,
      step: "step8",
      step8: {
        NomineeName: data?.NomineeName,
        NomineeDOB: formatedNomineeDOB,
        NomineeGender: data?.NomineeGender,
        NomineeRelation: data?.NomineeRelation,
      },
    };
    dispatchProcessFlowHealth("step8.NomineeDetails", postData.step8);
    processFlow({ processFlow: postData });

    if (selectedPlan.Company === "care Health") {
      const response = issueHealthPolicy(healthRequestQQ);
    } else if (
      selectedPlan.Company === "Universal Sompo General Health Insurance"
    ) {
      const response = await sompoHealthIssuePolicy(
        healthRequestQQ,
        selectedPlan.planType
      );

      if (response.message === "Data fetched in sompo proposal api") {
        const postData = {
          ...processFlowObjHealth,
          step: "step9",

          step9: {
            SompoCreatedPolicyCustomer: response?.data?.Root?.Customer,
            SompoCreatedPolicyPremium:
              response?.data?.Root?.Product?.PremiumCalculation,
            SompoCreatedPolicyInsuredDetails:
              response?.data.Root.Product.Risks.Risk.RisksData.InsuredDetails
                .InsuredDetailsGroup[0],
          },
        };
        processFlow({ processFlow: postData });
        dispatchProcessFlowHealth("step9", postData?.step9);
        setPolicyOverview(true);
        setProceedPayment(false);
      }
    }
  };

  const [paymentPage, setPaymentPage] = useState({});
  const navigate = useNavigate();

  const handlePayment = () => {
    if (selectedPlan.Company === "Universal Sompo General Health Insurance") {
      sompoPaymentApiHealth(
        selectedPlan.enquiryID,
        selectedPlan.planType,
        healthRequestQQ.quotationNumber
      );
    } else {
      carePayment(healthRequestQQ?.enquiryId);
      navigate("/care_payment");
    }
    // setModal(true)
  };

  const handleChange = (event) => {
    setSomeStateValue(event.target.value);
  };

  const [date, setDate] = useState("");

  const handleDateChange = (event) => {
    setDate(event.target.value);
  };

  return (
    <Layout>
      <div className="main-owner-page">
        {/* <div className="owner-bike-details d-flex flex-column pb-2">
          <div className="d-flex align-items-center">
            <img src={"/assets/images/cardiogram.png"} alt="" />
            <h2 className="fs-5">
              {
                <>
                  <span className="ms-4">
                    {healthRequestQQ?.CustomerDetails?.customerFirstName}
                  </span>
                  <img className="px-2" src="/assets/images/Ellipse 232.png" />
                </>
              }
              {
                <>
                  {healthRequestQQ?.CustomerDetails?.customerRelation}
                  <img className="px-2" src="/assets/images/Ellipse 232.png" />
                </>
              }
              {
                <>
                  <span>
                    {" "}
                    {healthRequestQQ?.CustomerDetails?.customerAge} Years
                  </span>
                </>
              }
            </h2>
            <h2
              className="fs-5"
              style={{
                color: "green",
                cursor: "pointer",
                border: "0.5px solid green",
                borderRadius: "5px",
                padding: "1px 3px",
                marginLeft: "10px",
              }}
            >
              Edit
            </h2>
          </div>
        </div> */}
        <div className="main-vehicle-owner-outer">
          <div className="main-vehicle-owner">
            <div className="vehicle-owner-details">
              <div className="vehicle-owner">
                <div className="owmer-div-1">
                  <div className="owner-img active">
                    <img src="/assets/images/owner-img.png" alt="" />
                  </div>
                  <div className="owner-text active-text">
                    <h2>Policy Holder Details</h2>
                  </div>
                </div>
                <div className="owmer-div-1">
                  <div
                    className={`owner-img ${
                      activeStep2 === true ? "active" : ""
                    }`}
                  >
                    <img
                      src={
                        activeStep2
                          ? "/assets/images/disability white (1).png"
                          : "/assets/images/disability (1).png"
                      }
                      alt=""
                    />
                  </div>
                  <div
                    className={`owner-text ${
                      activeStep2 === true ? "active-text" : ""
                    }`}
                  >
                    <h2>Medical Details</h2>
                  </div>
                </div>
                <div className="owmer-div-1">
                  <div
                    className={`owner-img ${
                      activeStep3 === true ? "active" : ""
                    }`}
                  >
                    <img
                      src={
                        activeStep3 === true
                          ? "/assets/images/health icon white.png"
                          : "/assets/images/health icon (1).png"
                      }
                      alt=""
                    />
                  </div>
                  <div
                    className={`owner-text ${
                      activeStep3 === true ? "active-text" : ""
                    }`}
                  >
                    <h2>Addditional Details</h2>
                  </div>
                </div>
              </div>
              {/* Step 1 */}
              {currentStep === 1 && (
                <form onSubmit={handleSubmit1(submitPersonalDetails)}>
                  <div className="onwer-personal-details">
                    <div className="row">
                      <div className="col-md-6 mb-2">
                        <div className="onwer-details">
                          <div className="did-floating-label-content">
                            <select
                              name="salutaion"
                              style={{ width: "100%" }}
                              placeholder="Salutation"
                              className="form-select"
                              {...register1("customerSaluation", {
                                required: "Field is required",
                              })}
                            >
                              <option value="">Select Salutation</option>
                              <option>MR</option>
                              <option>MS</option>
                            </select>
                            <label
                              htmlFor="idxx4"
                              className="did-floating-label"
                            >
                              Salutation
                            </label>
                            {errors1?.customerSaluation?.message && (
                              <span className="text-danger">
                                {errors1?.customerSaluation?.message}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 mb-2">
                        <div className="onwer-details">
                          <div className="did-floating-label-content">
                            <input
                              type="text"
                              className=" did-floating-input"
                              defaultValue={
                                healthRequestQQ?.CustomerDetails
                                  ?.customerFirstName
                              }
                              placeholder=""
                            />
                            <label
                              htmlFor="idxx4"
                              className="did-floating-label"
                            >
                              Enter First Name
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 mb-2">
                        <div className="onwer-details">
                          <div className="did-floating-label-content">
                            <input
                              type="text"
                              className=" did-floating-input"
                              defaultValue={
                                healthRequestQQ?.CustomerDetails
                                  ?.customerLastName
                              }
                              placeholder=""
                            />
                            <label
                              htmlFor="idxx4"
                              className="did-floating-label"
                            >
                              Enter Last Name
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 mb-2">
                        <div className="onwer-details">
                          <div className="did-floating-label-content">
                            <input
                              type="text"
                              className=" did-floating-input"
                              disabled
                              value={
                                healthRequestQQ.CustomerDetails.customerGender
                              }
                              placeholder=""
                            />
                            <label
                              htmlFor="idxx4"
                              className="did-floating-label"
                            >
                              Gender
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 mb-2">
                        <div className="onwer-details">
                          <div className="did-floating-label-content">
                            <input
                              type="text"
                              className=" did-floating-input"
                              disabled
                              value={
                                healthRequestQQ.CustomerDetails
                                  .customerMobileNumber
                              }
                              placeholder=""
                            />
                            <label
                              htmlFor="idxx4"
                              className="did-floating-label"
                            >
                              Enter Mobile Number
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 mb-2">
                        <div className="onwer-details">
                          <div className="did-floating-label-content">
                            <input
                              type="text"
                              className=" did-floating-input"
                              {...register1("customerEmailAddress", {
                                required: "Email is required",
                              })}
                              placeholder=""
                            />
                            <label
                              htmlFor="idxx4"
                              className="did-floating-label"
                            >
                              Enter Email ID
                            </label>
                            {errors1?.customerEmailAddress?.message && (
                              <span className="text-danger ms-3">
                                {errors1?.customerEmailAddress?.message}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 mb-2">
                        <div className="onwer-details">
                          <div className="did-floating-label-content">
                            <input
                              type="date"
                              className=" did-floating-input"
                              max={moment(minDate).format("YYYY-MM-DD")}
                              // min={moment(maxDate).format("YYYY-MM-DD")}
                              {...register1("customerDOB", {
                                required: "Date of Birth is required",
                              })}
                              placeholder=""
                            />
                            <label
                              htmlFor="idxx4"
                              className="did-floating-label"
                            >
                              Enter Your DOB
                            </label>
                            {errors1?.customerDOB?.message ? (
                              <span className="text-danger ms-3">
                                {errors1?.customerDOB?.message}
                              </span>
                            ) : dateOFBirth ? (
                              <span className="text-danger ms-3">
                                Age exceeds the maximum allowed limit i.e. 55
                                years
                              </span>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 mb-2">
                        <div className="onwer-details">
                          <div className="did-floating-label-content">
                            <input
                              id="height"
                              type="text"
                              name="height"
                              className=" did-floating-input"
                              placeholder=""
                              {...register1("customerHeight", {
                                required: "Height is required",
                              })}
                              onChange={(e) => {
                                userHeight(e.target.value);
                                clearErrors1("customerHeight");
                              }}
                            />
                            <label
                              htmlFor="idxx4"
                              className="did-floating-label"
                            >
                              Enter height in feet
                            </label>
                            {errors1?.customerHeight?.message && (
                              <span className="text-danger ms-3">
                                {errors1?.customerHeight?.message}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 mb-2">
                        <div className="onwer-details">
                          <div className="did-floating-label-content">
                            <input
                              id="weight"
                              type="number"
                              name="weight"
                              placeholder=""
                              {...register1("customerWeight", {
                                required: "Weight is required",
                              })}
                              onChange={(e) => {
                                userWeight(e.target.value);
                                clearErrors1("customerWeight");
                              }}
                              className=" did-floating-input"
                            />
                            <label
                              htmlFor="idxx4"
                              className="did-floating-label"
                            >
                              Enter weight in kilograms
                            </label>
                            {errors1?.customerWeight?.message && (
                              <span className="text-danger ms-3">
                                {errors1?.customerWeight?.message}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 mb-2">
                        <div className="onwer-details">
                          <div className="did-floating-label-content">
                            <input
                              id="bmi"
                              type="number"
                              name="bmi"
                              value={bmi}
                              placeholder=""
                              disabled
                              className="did-floating-input"
                            />
                            <label
                              htmlFor="idxx4"
                              className="did-floating-label"
                            >
                              BMI
                            </label>
                            {bmiLimit === true && (
                              <span className="text-danger ms-3">
                                BMI out of healthy range
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 mb-2">
                        <div className="onwer-details">
                          <div className="did-floating-label-content">
                            <select
                              type="text"
                              name="occupation-1"
                              maxlength="50"
                              id="occupation-1-self"
                              placeholder=""
                              className="form-select"
                              {...register1("customerOccupation", {
                                required: "Occupation is required",
                              })}
                            >
                              <option value="" className="d-none">
                                Occupation
                              </option>
                              <option value="Doctors">Doctors</option>
                              <option value="Lawyers">Lawyers</option>
                              <option value="Accountants">Accountants</option>
                              <option value="Consulting Engineers">
                                Consulting Engineers
                              </option>
                              <option value="Teachers">Teachers</option>
                              <option value="Administrative Functions and persons primarily engaged in occupation of similar Hazard">
                                {" "}
                                Administrative Functions and persons primarily
                                engaged in occupation of similar Hazard
                              </option>
                              <option value="Bankers Persons engaged in clerical function ">
                                Bankers Persons engaged in clerical function{" "}
                              </option>
                              <option value="Bureaucrats">Bureaucrats</option>
                              <option value="Student">Student </option>
                              <option value="Housewife">Housewife </option>
                              <option value="Shopkeeper">Shopkeeper </option>
                              <option value="Writer">Writer </option>
                              <option value="Fashion Designer">
                                Fashion Designer{" "}
                              </option>
                              <option value="	Desk job"> Desk job </option>
                              <option value="Salaried">Salaried </option>
                              <option value="Others">Others </option>
                            </select>
                            <label
                              htmlFor="idxx4"
                              className="did-floating-label"
                            >
                              Occupation
                            </label>
                            {errors1?.customerOccupation?.message && (
                              <span className="text-danger ms-3">
                                {errors1?.customerOccupation?.message}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                      {/* <div className="col-md-12 mb-2">
                        <p className="text-start">Communication Address</p>
                      </div>
                      <div className="col-md-6 mb-2">
                        <div className="onwer-details">
                          <div className="did-floating-label-content">
                            <input
                              type="text"
                              placeholder=""
                              {...register1("CommunicationAddress.address_1", {
                                // required: "Field is required",
                              })}
                              onChange={handleCommunicationChange("address_1")}
                              className=" did-floating-input"
                            />
                            <label
                              htmlFor="idxx4"
                              className="did-floating-label"
                            >
                              Address 1
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 mb-2">
                        <div className="onwer-details">
                          <div className="did-floating-label-content">
                            <input
                              type="text"
                              placeholder=""
                              {...register1("CommunicationAddress.address_2", {
                                // required: "Field is required",
                              })}
                              onChange={handleCommunicationChange("address_2")}
                              className=" did-floating-input"
                            />
                            <label
                              htmlFor="idxx4"
                              className="did-floating-label"
                            >
                              Address 2
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 mb-2">
                        <div className="onwer-details">
                          <div className="did-floating-label-content">
                            <input
                              type="text"
                              placeholder=""
                              {...register1("CommunicationAddress.city", {
                                // required: "Field is required",
                              })}
                              // disabled

                              onChange={handleCommunicationChange("city")}
                              defaultValue={
                                healthRequestQQ?.CustomerDetails
                                  ?.customerAddress?.communicationAddress?.city
                              }
                              className=" did-floating-input"
                            />
                            <label
                              htmlFor="idxx4"
                              className="did-floating-label"
                            >
                              City
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 mb-2">
                        <div className="onwer-details">
                          <div className="did-floating-label-content">
                            <input
                              type="text"
                              placeholder=""
                              {...register1("CommunicationAddress.state", {
                                // required: "Field is required",
                              })}
                              // value={
                              //   healthRequestQQ?.CustomerDetails?.customerAddress
                              //     ?.communicationAddress?.state
                              // }
                              onChange={handleCommunicationChange("state")}
                              defaultValue={
                                healthRequestQQ?.CustomerDetails
                                  ?.customerAddress?.communicationAddress?.state
                              }
                              className=" did-floating-input"
                            />
                            <label
                              htmlFor="idxx4"
                              className="did-floating-label"
                            >
                              State
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 mb-2">
                        <div className="onwer-details">
                          <div className="did-floating-label-content">
                            <input
                              type="text"
                              placeholder=""
                              disabled
                              {...register1("CommunicationAddress.pincode", {
                                // required: "Field is required",
                              })}
                              value={
                                healthRequestQQ?.CustomerDetails
                                  ?.customerPincode
                              }
                              onChange={handleCommunicationChange("state")}
                              className=" did-floating-input"
                            />
                            <label
                              htmlFor="idxx4"
                              className="did-floating-label"
                            >
                              Enter Pincode
                            </label>
                          </div>
                        </div>
                      </div> */}
                      {/* <div className="col-md-12 mb-2">
                        <div className="addressCheckboxTitle">
                          <p className="text-start">Permanent Address</p>
                          <div className="d-flex align-items-center">
                            <input
                              className="addressCheckboxInput"
                              type="checkbox"
                              id="sameAddress"
                              {...register1("sameAddress")}
                              onChange={handleCheckboxChange}
                            />
                            <label htmlFor="sameAddress">
                              Same as Communication Address
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 mb-2">
                        <div className="onwer-details">
                          <div className="did-floating-label-content">
                            <input
                              type="text"
                              placeholder=""
                              {...register1("PermanentAddress.address_1", {
                                // required: "Field is required",
                              })}
                              disabled={watch("sameAddress")}
                              className=" did-floating-input"
                            />
                            <label
                              htmlFor="idxx4"
                              className="did-floating-label"
                            >
                              Address 1
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 mb-2">
                        <div className="onwer-details">
                          <div className="did-floating-label-content">
                            <input
                              type="text"
                              placeholder=""
                              {...register1("PermanentAddress.address_2", {
                                // required: "Field is required",
                              })}
                              disabled={watch("sameAddress")}
                              className=" did-floating-input"
                            />
                            <label
                              htmlFor="idxx4"
                              className="did-floating-label"
                            >
                              Address 2
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 mb-2">
                        <div className="onwer-details">
                          <div className="did-floating-label-content">
                            <ReactSelect
                              options={pincodeData}
                              onChange={(val) => handlePincodeChange(val)}
                              placeholder={
                                watch("sameAddress")
                                  ? healthRequestQQ?.CustomerDetails
                                      ?.customerPincode
                                  : "Select Pincode"
                              }

                              // {...register1("PermanentAddress.pincode", {
                              //   required: "Field is required",
                              // })}
                              // defaultValue={apiRequestQQ.Pincode}
                            />
                            <label
                              htmlFor="idxx4"
                              className="did-floating-label"
                            >
                              Enter Pincode
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 mb-2">
                        <div className="onwer-details">
                          <div className="did-floating-label-content">
                            <input
                              type="text"
                              placeholder=""
                              {...register1("PermanentAddress.city", {
                                // required: "Field is required",
                              })}
                              value={pincodeDetails?.City}
                              disabled={watch("sameAddress")}
                              className=" did-floating-input"
                            />
                            <label
                              htmlFor="idxx4"
                              className="did-floating-label"
                            >
                              Enter City
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 mb-2">
                        <div className="onwer-details">
                          <div className="did-floating-label-content">
                            <input
                              type="text"
                              placeholder=""
                              {...register1("PermanentAddress.state", {
                                // required: "Field is required",
                              })}
                              value={pincodeDetails?.State}
                              disabled={watch("sameAddress")}
                              className=" did-floating-input"
                            />
                            <label
                              htmlFor="idxx4"
                              className="did-floating-label"
                            >
                              Enter State
                            </label>
                          </div>
                        </div>
                      </div> */}

                      {/* <div>
                        <select
                          name="salutaion"
                          style={{ width: "100%" }}
                          placeholder="Salutation"
                          {...register1("customerSaluation", {
                            required: "Field is required",
                          })}
                        >
                          <option>Select Salutation</option>
                          <option>MR</option>
                          <option>MS</option>
                        </select>
                        {errors1?.customerSaluation?.message && (
                          <span className="text-danger">
                            {errors1?.customerSaluation?.message}
                          </span>
                        )}
                      </div>
                      <div>
                        <input
                          style={{ width: "100%" }}
                          type="text"
                          defaultValue={
                            healthRequestQQ?.CustomerDetails?.customerFirstName
                          }
                          placeholder="Enter First Name"
                        />
                      </div>
                      <div style={{ display: "inline-block", width: "45%" }}>
                        <input
                          type="text"
                          style={{ width: "100%" }}
                          defaultValue={
                            healthRequestQQ?.CustomerDetails?.customerLastName
                          }
                          placeholder="Enter Last Name"
                        />
                      </div>
                      <div style={{ display: "inline-block", width: "45%" }}>
                        <input
                          type="text"
                          style={{ width: "100%" }}
                          disabled
                          placeholder="Gender"
                          value={healthRequestQQ.CustomerDetails.customerGender}
                        />
                      </div>
                      <div style={{ display: "inline-block", width: "45%" }}>
                        <input
                          type="text"
                          style={{ width: "100%" }}
                          disabled
                          placeholder="Enter Mobile Number"
                          value={
                            healthRequestQQ.CustomerDetails.customerMobileNumber
                          }
                        />
                      </div>
                      <div style={{ display: "inline-block", width: "45%" }}>
                        <input
                          type="text"
                          style={{ width: "100%" }}
                          placeholder="Enter Email ID"
                          {...register1("customerEmailAddress", {
                            required: "Email is required",
                          })}
                        />
                        {errors1?.customerEmailAddress?.message && (
                          <span className="text-danger ms-3">
                            {errors1?.customerEmailAddress?.message}
                          </span>
                        )}
                      </div>

                      <div style={{ display: "inline-block", width: "45%" }}>
                        <input
                          type="date"
                          style={{ width: "100%" }}
                          placeholder="Enter your DOB"
                          max={moment(minDate).format("YYYY-MM-DD")}
                          // min={moment(maxDate).format("YYYY-MM-DD")}
                          {...register1("customerDOB", {
                            required: "Date of Birth is required",
                          })}
                        />
                        {errors1?.customerDOB?.message ? (
                          <span className="text-danger ms-3">
                            {errors1?.customerDOB?.message}
                          </span>
                        ) : dateOFBirth ? (
                          <span className="text-danger ms-3">
                            Age exceeds the maximum allowed limit i.e. 55 years
                          </span>
                        ) : (
                          ""
                        )}
                      </div>

                      <div style={{ display: "inline-block", width: "45%" }}>
                        <input
                          style={{ width: "100%" }}
                          id="height"
                          type="text"
                          name="height"
                          placeholder="Enter height in feet"
                          {...register1("customerHeight", {
                            required: "Height is required",
                          })}
                          onChange={(e) => userHeight(e.target.value)}
                        />
                        {errors1?.customerHeight?.message && (
                          <span className="text-danger ms-3">
                            {errors1?.customerHeight?.message}
                          </span>
                        )}
                      </div>

                      <div style={{ display: "inline-block", width: "45%" }}>
                        <input
                          style={{ width: "100%" }}
                          id="weight"
                          type="number"
                          name="weight"
                          placeholder="Enter weight in kilograms"
                          {...register1("customerWeight", {
                            required: "Weight is required",
                          })}
                          onChange={(e) => userWeight(e.target.value)}
                        />
                        {errors1?.customerWeight?.message && (
                          <span className="text-danger ms-3">
                            {errors1?.customerWeight?.message}
                          </span>
                        )}
                      </div> */}

                      {/* BMI */}
                      {
                        // <div style={{ display: "inline-block", width: "45%" }}>
                        //   <input
                        //     style={{ width: "100%" }}
                        //     id="bmi"
                        //     type="number"
                        //     name="bmi"
                        //     value={bmi}
                        //     placeholder="BMI"
                        //     disabled
                        //   />
                        //   {bmiLimit === true && (
                        //     <span className="text-danger ms-3">
                        //       BMI out of healthy range
                        //     </span>
                        //   )}
                        // </div>
                      }

                      {/* <div style={{ display: "inline-block", width: "45%" }}>
                        <select
                          style={{ width: "100%" }}
                          type="text"
                          name="occupation-1"
                          maxlength="50"
                          id="occupation-1-self"
                          placeholder="Occupation"
                          {...register1("customerOccupation", {
                            required: "Occupation is required",
                          })}
                        >
                          <option value="" className="d-none">
                            Occupation
                          </option>
                          <option value="Doctors">Doctors</option>
                          <option value="Lawyers">Lawyers</option>
                          <option value="Accountants">Accountants</option>
                          <option value="Consulting Engineers">
                            Consulting Engineers
                          </option>
                          <option value="Teachers">Teachers</option>
                          <option value="	Administrative Functions and persons primarily engaged in occupation of similar Hazard">
                            {" "}
                            Administrative Functions and persons primarily
                            engaged in occupation of similar Hazard
                          </option>
                          <option value="Bankers Persons engaged in clerical function ">
                            Bankers Persons engaged in clerical function{" "}
                          </option>
                          <option value="Bureaucrats">Bureaucrats</option>
                          <option value="Student">Student </option>
                          <option value="Housewife">Housewife </option>
                          <option value="Shopkeeper">Shopkeeper </option>
                          <option value="Writer">Writer </option>
                          <option value="Fashion Designer">
                            Fashion Designer{" "}
                          </option>
                          <option value="	Desk job"> Desk job </option>
                          <option value="Salaried">Salaried </option>
                          <option value="Others">Others </option>
                        </select>
                        {errors1?.customerOccupation?.message && (
                          <span className="text-danger ms-3">
                            {errors1?.customerOccupation?.message}
                          </span>
                        )}
                      </div> */}
                      <div className="col-md-12 mt-2 mb-4">
                        <p className="text-start">Communication Address</p>
                      </div>
                      <div className="col-md-6 mb-2">
                        <div class="did-floating-label-content">
                          <input
                            type="text"
                            className="did-floating-input"
                            placeholder=""
                            {...register1("CommunicationAddress.address_1", {
                              required: "Field is required",
                            })}
                            onChange={handleCommunicationChange("address_1")}
                          />
                          {errors1?.CommunicationAddress?.address_1
                            ?.message && (
                            <p className="text-danger ms-2">
                              {
                                errors1?.CommunicationAddress?.address_1
                                  ?.message
                              }
                            </p>
                          )}
                          <label for="idxx4" class="did-floating-label">
                            Address 1
                          </label>
                        </div>
                      </div>
                      <div className="col-md-6 mb-2">
                        <div class="did-floating-label-content">
                          <input
                            style={{ width: "100%" }}
                            type="text"
                            className="did-floating-input"
                            placeholder=""
                            {...register1("CommunicationAddress.address_2", {
                              required: "Field is required",
                            })}
                            onChange={handleCommunicationChange("address_2")}
                          />
                          {errors1?.CommunicationAddress?.address_2
                            ?.message && (
                            <p className="text-danger ms-2">
                              {
                                errors1?.CommunicationAddress?.address_2
                                  ?.message
                              }
                            </p>
                          )}
                          <label for="idxx4" class="did-floating-label">
                            Address 2
                          </label>
                        </div>
                      </div>
                      <div className="col-md-6 mb-2">
                        <div class="did-floating-label-content">
                          <input
                            style={{ width: "100%" }}
                            type="text"
                            className="did-floating-input"
                            // {...register1("CommunicationAddress.city", {
                            //   // required: "Field is required",
                            // })}
                            disabled
                            onChange={handleCommunicationChange("city")}
                            defaultValue={
                              healthRequestQQ?.CustomerDetails?.customerAddress
                                ?.communicationAddress?.city
                            }
                          />
                          <label for="idxx4" class="did-floating-label">
                            Enter City
                          </label>
                        </div>
                      </div>
                      <div className="col-md-6 mb-2">
                        <div class="did-floating-label-content">
                          <input
                            style={{ width: "100%" }}
                            type="text"
                            className="did-floating-input"
                            // placeholder={{}}
                            // {...register1("CommunicationAddress.state", {
                            //   // required: "Field is required",
                            // })}
                            // value={
                            //   healthRequestQQ?.CustomerDetails?.customerAddress
                            //     ?.communicationAddress?.state
                            // }
                            onChange={handleCommunicationChange("state")}
                            defaultValue={
                              healthRequestQQ?.CustomerDetails?.customerAddress
                                ?.communicationAddress?.state
                            }
                            disabled
                          />
                          <label for="idxx4" class="did-floating-label">
                            Enter State
                          </label>
                        </div>
                      </div>
                      <div className="col-md-6 mb-2">
                        <div class="did-floating-label-content">
                          {/* <ReactSelect
                            className=""
                            options={pincodeData}
                            onChange={(val) => handlePincodeChange(val)}
                            value={
                              healthRequestQQ?.CustomerDetails?.customerPincode
                            }
                            placeholder="Enter Pincode"
                          /> */}
                          <input
                            style={{ width: "100%" }}
                            type="text"
                            className="did-floating-input"
                            disabled
                            // placeholder={{}}
                            // {...register1("CommunicationAddress.pincode", {
                            //   // required: "Field is required",
                            // })}
                            value={
                              healthRequestQQ?.CustomerDetails?.customerPincode
                            }
                            // onChange={handleCommunicationChange("state")}
                          />
                          <label for="idxx4" class="did-floating-label">
                            Enter Pincode
                          </label>
                        </div>
                      </div>
                      {/* <div className="pin-data">
                        <ReactSelect
                          className=""
                          options={pincodeData}
                          onChange={(val) => handlePincodeChange(val)}
                          value={
                            healthRequestQQ?.CustomerDetails?.customerPincode
                          }
                          placeholder="Enter Pincode"
                        />
                      </div> */}

                      {
                        <div
                          className="addressCheckboxTitle col-md-12"
                          style={{ width: "100%" }}
                        >
                          <p className="text-start">Permanent Address</p>
                          <div className="d-flex align-items-center">
                            <input
                              className="addressCheckboxInput"
                              type="checkbox"
                              id="sameAddress"
                              {...register1("sameAddress")}
                              onChange={handleCheckboxChange}
                            />
                            <label className="ms-2" htmlFor="sameAddress">
                              Same as Communication Address
                            </label>
                          </div>
                        </div>
                      }
                      <div className="col-md-6 mb-2">
                        <div class="did-floating-label-content">
                          <input
                            style={{ width: "100%" }}
                            type="text"
                            className="did-floating-input"
                            placeholder=""
                            {...register1("PermanentAddress.address_1", {
                              required: "Field is required",
                            })}
                            disabled={watch("sameAddress")}
                          />
                          {errors1?.PermanentAddress?.address_1?.message && (
                            <p className="text-danger ms-2">
                              {errors1?.PermanentAddress?.address_1?.message}
                            </p>
                          )}
                          <label for="idxx4" class="did-floating-label">
                            Address 1
                          </label>
                        </div>
                      </div>
                      <div className="col-md-6 mb-2">
                        <div class="did-floating-label-content">
                          <input
                            style={{ width: "100%" }}
                            type="text"
                            className="did-floating-input"
                            placeholder=""
                            {...register1("PermanentAddress.address_2", {
                              required: "Field is required",
                            })}
                            disabled={watch("sameAddress")}
                          />
                          {errors1?.PermanentAddress?.address_2?.message && (
                            <p className="text-danger ms-2">
                              {errors1?.PermanentAddress?.address_2?.message}
                            </p>
                          )}
                          <label for="idxx4" class="did-floating-label">
                            Address 2
                          </label>
                        </div>
                      </div>
                      {watch("sameAddress") === true ? (
                        <div className="col-md-6 mb-2">
                          <input
                            style={{ width: "100%" }}
                            className="did-floating-input"
                            type="text"
                            {...register1("PermanentAddress.pincode", {
                              // required: "Field is required",
                            })}
                            placeholder={
                              healthRequestQQ?.CustomerDetails?.customerPincode
                            }
                            disabled={watch("sameAddress")}
                          />
                        </div>
                      ) : (
                        <div className="col-md-6 mb-2">
                          <div class="did-floating-label-content">
                            <ReactSelect
                              options={pincodeData}
                              onChange={(val) => handlePincodeChange(val)}
                              placeholder={
                                watch("sameAddress")
                                  ? healthRequestQQ?.CustomerDetails
                                      ?.customerPincode
                                  : "Select Pincode"
                              }
                              // {...register1("PermanentAddress.pincode", {
                              //   required: "Field is required",
                              // })}
                              // defaultValue={apiRequestQQ.Pincode}
                            />
                            {/* {errors1?.PermanentAddress?.pincode?.message && (
                              <p className="text-danger ms-2">
                                {errors1?.PermanentAddress?.pincode?.message}
                              </p>
                            )} */}
                            <label for="idxx4" class="did-floating-label">
                              Select Pincode
                            </label>
                          </div>
                        </div>
                      )}
                      <div className="col-md-6 mb-2">
                        <div class="did-floating-label-content">
                          <input
                            type="text"
                            className="did-floating-input"
                            placeholder=""
                            {...register1("PermanentAddress.city", {
                              // required: "Field is required",
                            })}
                            // value={watch(
                            //   "sameAddress"
                            //     ? healthRequestQQ?.CustomerDetails
                            //         ?.customerAddress?.communicationAddress
                            //         ?.city
                            //     : pincodeDetails?.City
                            // )}
                            value={
                              watch("sameAddress")
                                ? healthRequestQQ?.CustomerDetails
                                    ?.customerAddress?.communicationAddress
                                    ?.city
                                : pincodeDetails?.City
                            }
                            disabled={watch("sameAddress")}
                          />
                          {errors1?.PermanentAddress?.city?.message && (
                            <p className="text-danger ms-2">
                              {errors1?.PermanentAddress?.city?.message}
                            </p>
                          )}
                          <label for="idxx4" class="did-floating-label">
                            Enter City
                          </label>
                        </div>
                      </div>
                      <div className="col-md-6 mb-2">
                        <div class="did-floating-label-content">
                          <input
                            type="text"
                            className="did-floating-input"
                            placeholder=""
                            {...register1("PermanentAddress.state", {
                              // required: "Field is required",
                            })}
                            value={
                              watch("sameAddress")
                                ? healthRequestQQ?.CustomerDetails
                                    ?.customerAddress?.communicationAddress
                                    ?.state
                                : pincodeDetails?.State
                            }
                            disabled={watch("sameAddress")}
                          />
                          {errors1?.PermanentAddress?.state?.message && (
                            <p className="text-danger ms-2">
                              {errors1?.PermanentAddress?.state?.message}
                            </p>
                          )}
                          <label for="idxx4" class="did-floating-label">
                            Enter State
                          </label>
                        </div>
                      </div>
                      <div className="next-btn-btn">
                        <button type="button" className="proceedBtn1" disabled>
                          Previous
                        </button>
                        <button
                          type="submit"
                          className="proceedBtn1"
                          // onClick={() => handleNextStep()}
                        >
                          {step1Proceed ? (
                            <div
                              class="spinner-border text-light"
                              role="status"
                            >
                              <span class="sr-only"></span>
                            </div>
                          ) : bmiLimit ? (
                            <div>
                              <span
                                className=""
                                onClick={() => {
                                  window.scrollTo({
                                    top: 0,
                                    behavior: "smooth",
                                  });
                                }}
                              >
                                Proceed
                              </span>
                            </div>
                          ) : (
                            "Proceed"
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              )}

              {/* proposal cantan------------ */}

              {currentStep === 2 &&
                (healthIsnurer === "Sompo" ? (
                  <form onSubmit={handleSubmit2(submitMedicalHistory)}>
                    <div className="onwer-personal-details-questions">
                      {/* -----------------Question 0------------- */}

                      {/* -----------------Question 1------------- */}
                      <div className="proposal-cantainer">
                        <div className="text-box">
                          <h4>
                            Q. Are you or the proposed Insured Member suffering
                            from any illness or disease at present or in the
                            recent past?
                          </h4>
                          <div className="proposal-text">
                            <div className="proposal-text">
                              <div className="proposal-text-self">
                                <input
                                  type="radio"
                                  name="IllnessOrDisease"
                                  id="ques1option1"
                                  {...register2("IllnessOrDisease", {
                                    required: "Field is mandatory",
                                  })}
                                  value={false}
                                />
                                <label for="ques1option1">No</label>
                              </div>
                            </div>

                            {
                              <div className="proposal-text-self">
                                <input
                                  type="radio"
                                  name="IllnessOrDisease"
                                  {...register2("IllnessOrDisease", {
                                    required: "Field is mandatory",
                                  })}
                                  value={true}
                                  id="Q1"
                                />
                                <label for="Q1">Yes</label>
                              </div>
                            }
                            {errors2 && (
                              <p className="text-danger">
                                {errors2?.IllnessOrDisease?.message}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                      {/* -----------------Question 2------------- */}
                      <div className="proposal-cantainer">
                        <div className="text-box">
                          <h4>
                            Q. Are you or the proposed insured member taking any
                            type of treatment or medicine?
                          </h4>
                          <div className="proposal-text">
                            <div className="proposal-text">
                              <div className="proposal-text-self">
                                <input
                                  type="radio"
                                  name="TreatmentOrMedicine"
                                  id="ques2option1"
                                  {...register2("TreatmentOrMedicine", {
                                    required: "Field is mandatory",
                                  })}
                                  value={false}
                                />
                                <label for="ques2option1">No</label>
                              </div>
                            </div>

                            {
                              <div className="proposal-text-self">
                                <input
                                  type="radio"
                                  name="TreatmentOrMedicine"
                                  {...register2("TreatmentOrMedicine", {
                                    required: "Field is mandatory",
                                  })}
                                  value={true}
                                  id="Q2"
                                />
                                <label for="Q2">Yes</label>
                              </div>
                            }
                            {errors2 && (
                              <p className="text-danger">
                                {errors2?.TreatmentOrMedicine?.message}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                      {/* -----------------Question 3------------- */}
                      <div className="proposal-cantainer">
                        <div className="text-box">
                          <h4>
                            Q. Have you or the proposed insured member visited a
                            doctor or medical professional for any health
                            problem in the last 4 years?
                          </h4>
                          <div className="proposal-text">
                            <div className="proposal-text">
                              <div className="proposal-text-self">
                                <input
                                  type="radio"
                                  name="VisitedDoctor"
                                  id="ques3option1"
                                  {...register2("VisitedDoctor", {
                                    required: "Field is mandatory",
                                  })}
                                  value={false}
                                />
                                <label for="ques3option1">No</label>
                              </div>
                            </div>

                            {
                              <div className="proposal-text-self">
                                <input
                                  type="radio"
                                  name="VisitedDoctor"
                                  {...register2("VisitedDoctor", {
                                    required: "Field is mandatory",
                                  })}
                                  value={true}
                                  id="Q3"
                                />
                                <label for="Q3">Yes</label>
                              </div>
                            }
                            {errors2 && (
                              <p className="text-danger">
                                {errors2?.VisitedDoctor?.message}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                      {/* -----------------Question 4------------- */}
                      <div className="proposal-cantainer">
                        <div className="text-box">
                          <h4>
                            Q. Have you or the proposed insured member been
                            hospitalized for operation/medical treatment in the
                            last 4 years?
                          </h4>
                          <div className="proposal-text">
                            <div className="proposal-text">
                              <div className="proposal-text-self">
                                <input
                                  type="radio"
                                  name="Hospitalized"
                                  id="ques4option1"
                                  {...register2("Hospitalized", {
                                    required: "Field is mandatory",
                                  })}
                                  value={false}
                                />
                                <label for="ques4option1">No</label>
                              </div>
                            </div>

                            {
                              <div className="proposal-text-self">
                                <input
                                  type="radio"
                                  name="Hospitalized"
                                  {...register2("Hospitalized", {
                                    required: "Field is mandatory",
                                  })}
                                  value={true}
                                  id="Q4"
                                />
                                <label for="Q4">Yes</label>
                              </div>
                            }
                            {errors2 && (
                              <p className="text-danger">
                                {errors2?.Hospitalized?.message}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                      {/* -----------------Question 5------------- */}
                      <div className="proposal-cantainer">
                        <div className="text-box">
                          <h4>
                            Q. Have any of the person proposed to be insured
                            ever suﬀered from or taken treatment, or
                            hospitalized for or have been recommended to take
                            investigations/ medication/surgery or undergone a
                            surgery for any of the following – Diabetes;
                            Hypertension; Ulcer/Cyst/Cancer; Cardiac Disorder;
                            Kidney or Urinary Tract Disorder; Disorder of
                            muscle/bone/joint; Respiratory disorder; Digestive
                            tract or gastrointestinal disorder; Nervous System
                            disorder; Mental Illness or disorder, HIV or AIDS?
                          </h4>
                          <div className="proposal-text">
                            <div className="proposal-text">
                              <div className="proposal-text-self">
                                <input
                                  type="radio"
                                  name="TakenAnyTreatment"
                                  id="ques5option1"
                                  {...register2("TakenAnyTreatment", {
                                    required: "Field is mandatory",
                                  })}
                                  value={false}
                                />
                                <label for="ques5option1">No</label>
                              </div>
                            </div>

                            {
                              <div className="proposal-text-self">
                                <input
                                  type="radio"
                                  name="TakenAnyTreatment"
                                  {...register2("TakenAnyTreatment", {
                                    required: "Field is mandatory",
                                  })}
                                  value={true}
                                  id="Q5"
                                />
                                <label for="Q5">Yes</label>
                              </div>
                            }
                            {errors2 && (
                              <p className="text-danger">
                                {errors2?.TakenAnyTreatment?.message}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                      {/* -----------------Question 6------------- */}
                      <div className="proposal-cantainer">
                        <div className="text-box">
                          <h4>Q. Are you consuming Alcohol?</h4>
                          <div className="proposal-text">
                            <div className="proposal-text">
                              <div className="proposal-text-self">
                                <input
                                  type="radio"
                                  name="Alcohol"
                                  id="ques6option1"
                                  {...register2(
                                    "Alcohol",
                                    { value: "false" },
                                    {
                                      required: "Field is mandatory",
                                    }
                                  )}
                                  value={false}
                                />
                                <label htmlFor="ques6option1">No</label>
                              </div>
                            </div>

                            {
                              <div className="proposal-text-self">
                                <div className="div-proposal-box d-block">
                                  <div className="d-flex">
                                    <input
                                      type="radio"
                                      name="Alcohol"
                                      id="ques6option2customer"
                                      {...register2(
                                        "Alcohol",
                                        { value: "true" },
                                        {
                                          required: "Field is mandatory",
                                        }
                                      )}
                                      value={true}
                                    />
                                    <label htmlFor="ques6option2customer">
                                      Yes
                                    </label>
                                  </div>
                                  {watch1("Alcohol") === "true" && (
                                    <div className="d-flex mb-4">
                                      <div className="d-flex flex-column div-proposal-box ms-5">
                                        <select
                                          name="AlcoholType"
                                          className="form-select"
                                          {...register2("AlcoholType", {
                                            required: "Field is mandatory",
                                          })}
                                        >
                                          <option value="">
                                            Select Alcohol Type
                                          </option>
                                          <option value="Beer">Beer</option>
                                          <option value="Whisky">Whisky</option>
                                          <option value="Vodka">Vodka</option>
                                          <option value="Wine">Wine</option>
                                          <option value="Rum">Rum</option>
                                        </select>
                                        {errors2 && (
                                          <span
                                            className="text-danger"
                                            style={{ fontSize: "0.8rem" }}
                                          >
                                            {errors2?.AlcoholType?.message}
                                          </span>
                                        )}
                                      </div>
                                      <div className="d-flex flex-column div-proposal-box ms-5">
                                        <select
                                          name="AlcoholQuantity"
                                          className="form-select"
                                          {...register2("AlcoholQuantity", {
                                            required: "Field is mandatory",
                                          })}
                                        >
                                          <option value="">
                                            Select Alcohol Quantity
                                          </option>
                                          {watch1("AlcoholType") === "Beer" ? (
                                            <>
                                              <option value="less than 500 ml">
                                                less than 500 ml
                                              </option>
                                              <option value="greater than 500 ml">
                                                Greater than 500 ml
                                              </option>
                                            </>
                                          ) : (
                                            <>
                                              <option value="less than 30ml">
                                                Less than 30 ml
                                              </option>
                                              <option value="greater than 30 ml">
                                                Greater than 30 ml
                                              </option>
                                            </>
                                          )}
                                        </select>
                                        {errors2 && (
                                          <span
                                            className="text-danger"
                                            style={{ fontSize: "0.8rem" }}
                                          >
                                            {errors2?.AlcoholQuantity?.message}
                                          </span>
                                        )}
                                      </div>
                                      <div className="d-flex flex-column div-proposal-box ms-5">
                                        <select
                                          className="form-select"
                                          name="AlcoholDuration"
                                          {...register2("AlcoholDuration", {
                                            required: "Field is mandatory",
                                          })}
                                        >
                                          <option value="">
                                            Select Alcohol Duration
                                          </option>
                                          <option value="less than 5 years">
                                            Less than 5 years
                                          </option>
                                          <option value="greater than 5 years">
                                            Greater than 5 years
                                          </option>
                                        </select>
                                        {errors2 && (
                                          <span
                                            className="text-danger"
                                            style={{ fontSize: "0.8rem" }}
                                          >
                                            {errors2?.AlcoholDuration?.message}
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            }
                          </div>
                        </div>
                      </div>
                      {/* -----------------Question 7------------- */}
                      <div className="proposal-cantainer">
                        <div className="text-box">
                          <h4>Q. Are you smoker?</h4>
                          <div className="proposal-text">
                            <div className="proposal-text">
                              <div className="proposal-text-self">
                                <input
                                  type="radio"
                                  name="Smoker"
                                  id="ques7option1"
                                  {...register2(
                                    "Smoker",
                                    { value: "false" },
                                    {
                                      required: "Field is mandatory",
                                    }
                                  )}
                                  value={false}
                                />
                                <label for="ques7option1">No</label>
                              </div>
                            </div>

                            {
                              <div className="proposal-text-self">
                                <div className="div-proposal-box d-block">
                                  <div className="d-flex">
                                    <input
                                      type="radio"
                                      name="Smoker"
                                      id="ques7option2customer"
                                      {...register2(
                                        "Smoker",
                                        { value: "true" },
                                        {
                                          required: "Field is mandatory",
                                        }
                                      )}
                                      value={true}
                                    />
                                    <label htmlFor="ques7option2customer">
                                      Yes
                                    </label>
                                  </div>
                                  {watch1("Smoker") === "true" && (
                                    <div className="d-flex mb-4">
                                      <div className="d-flex flex-column div-proposal-box ms-5">
                                        <select
                                          name="SmokerType"
                                          className="form-select"
                                          {...register2("SmokerType", {
                                            required: "Field is mandatory",
                                          })}
                                        >
                                          <option value="">
                                            Select Smoker Type
                                          </option>
                                          <option value="Bidi">Bidi</option>
                                          <option value="Cigarette">
                                            Cigarette
                                          </option>
                                        </select>
                                        {errors2 && (
                                          <span
                                            className="text-danger"
                                            style={{ fontSize: "0.8rem" }}
                                          >
                                            {errors2?.SmokerType?.message}
                                          </span>
                                        )}
                                      </div>
                                      <div className="d-flex flex-column div-proposal-box ms-5">
                                        <select
                                          name="SmokerQuantity"
                                          className="form-select"
                                          {...register2("SmokerQuantity", {
                                            required: "Field is mandatory",
                                          })}
                                        >
                                          <option value="">
                                            Select Smoker Quantity
                                          </option>

                                          <option value="less than 5 per day">
                                            Less than 5 per day
                                          </option>
                                          <option value="greater than 5 per day">
                                            Greater than 5 per day
                                          </option>
                                        </select>
                                        {errors2 && (
                                          <span
                                            className="text-danger"
                                            style={{ fontSize: "0.8rem" }}
                                          >
                                            {errors2?.SmokerQuantity?.message}
                                          </span>
                                        )}
                                      </div>
                                      <div className="d-flex flex-column div-proposal-box ms-5">
                                        <select
                                          className="form-select"
                                          name="SmokerDuration"
                                          {...register2("SmokerDuration", {
                                            required: "Field is mandatory",
                                          })}
                                        >
                                          <option value="">
                                            Select Smoker Duration
                                          </option>
                                          <option value="less than 5 years">
                                            Less than 5 years
                                          </option>
                                          <option value="greater than 5 years">
                                            Greater than 5 years
                                          </option>
                                        </select>
                                        {errors2 && (
                                          <span
                                            className="text-danger"
                                            style={{ fontSize: "0.8rem" }}
                                          >
                                            {errors2?.SmokerDuration?.message}
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            }
                          </div>
                        </div>
                      </div>
                      {/* -----------------Question 8------------- */}
                      <div className="proposal-cantainer">
                        <div className="text-box">
                          <h4>
                            Q. Are you consuming Tobacco/Gutkha/Pan Masal, etc?
                          </h4>
                          <div className="proposal-text">
                            <div className="proposal-text">
                              <div className="proposal-text-self">
                                <input
                                  type="radio"
                                  name="Tobacco"
                                  id="ques8option1"
                                  {...register2(
                                    "Tobacco",
                                    { value: "false" },
                                    {
                                      required: "Field is mandatory",
                                    }
                                  )}
                                  value={false}
                                />
                                <label for="ques8option1">No</label>
                              </div>
                            </div>

                            {
                              <div className="proposal-text-self">
                                <div className="div-proposal-box d-block">
                                  <div className="d-flex">
                                    <input
                                      type="radio"
                                      name="Tobacco"
                                      id="ques8option2customer"
                                      {...register2(
                                        "Tobacco",
                                        { value: "true" },
                                        {
                                          required: "Field is mandatory",
                                        }
                                      )}
                                      value={true}
                                    />
                                    <label for="ques8option2customer">
                                      Yes
                                    </label>
                                  </div>
                                  {watch1("Tobacco") === "true" && (
                                    <div className="d-flex mb-4">
                                      <div className="d-flex flex-column div-proposal-box ms-5">
                                        <select
                                          name="TobaccoType"
                                          className="form-select"
                                          {...register2("TobaccoType", {
                                            required: "Field is mandatory",
                                          })}
                                        >
                                          <option value="">
                                            Select Tobacco Type
                                          </option>
                                          <option value="Tobacco">
                                            Tobacco
                                          </option>
                                          <option value="Gutkha">Gutkha</option>
                                          <option value="Panmasala">
                                            Panmasala
                                          </option>
                                        </select>
                                        {errors2 && (
                                          <span
                                            className="text-danger"
                                            style={{ fontSize: "0.8rem" }}
                                          >
                                            {errors2?.TobaccoType?.message}
                                          </span>
                                        )}
                                      </div>
                                      <div className="d-flex flex-column div-proposal-box ms-5">
                                        <select
                                          name="TobaccoQuantity"
                                          className="form-select"
                                          {...register2("TobaccoQuantity", {
                                            required: "Field is mandatory",
                                          })}
                                        >
                                          <option value="">
                                            Select Tobacco Quantity
                                          </option>
                                          <option value="less than 10 gms - 1 Pouse/day">
                                            less than 10 gms - 1 Pouse/day
                                          </option>
                                          <option value="greater than 10 gms - 1 Pouse/day">
                                            Greater than 10 gms - 1 Pouse/day
                                          </option>
                                        </select>
                                        {errors2 && (
                                          <span
                                            className="text-danger"
                                            style={{ fontSize: "0.8rem" }}
                                          >
                                            {errors2?.TobaccoQuantity?.message}
                                          </span>
                                        )}
                                      </div>
                                      <div className="d-flex flex-column div-proposal-box ms-5">
                                        <select
                                          className="form-select"
                                          name="TobaccoDuration"
                                          {...register2("TobaccoDuration", {
                                            required: "Field is mandatory",
                                          })}
                                        >
                                          <option value="">
                                            Select Tobacco Duration
                                          </option>
                                          <option value="less than 5 years">
                                            Less than 5 years
                                          </option>
                                          <option value="greater than 5 years">
                                            Greater than 5 years
                                          </option>
                                        </select>
                                        {errors2 && (
                                          <span
                                            className="text-danger"
                                            style={{ fontSize: "0.8rem" }}
                                          >
                                            {errors2?.TobaccoDuration?.message}
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            }
                          </div>
                        </div>
                      </div>
                      {/* -----------------Question 9------------- */}
                      <div className="proposal-cantainer">
                        <div className="text-box">
                          <h4>Q. Are you consuming Narcotic Substances?</h4>
                          <div className="proposal-text">
                            <div className="proposal-text">
                              <div className="proposal-text-self">
                                <input
                                  type="radio"
                                  name="Narcotics"
                                  id="ques9option1"
                                  {...register2("Narcotics", {
                                    required: "Field is mandatory",
                                  })}
                                  value={false}
                                />
                                <label for="ques9option1">No</label>
                              </div>
                            </div>

                            {
                              <div className="proposal-text-self">
                                <input
                                  type="radio"
                                  name="Narcotics"
                                  {...register2("Narcotics", {
                                    required: "Field is mandatory",
                                  })}
                                  id="Q9"
                                  value={true}
                                />
                                <label for="Q9">Yes</label>
                              </div>
                            }
                            {errors2 && (
                              <p className="text-danger">
                                {errors2?.Narcotics?.message}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="next-btn-btn">
                        <button
                          className="proceedBtn1"
                          onClick={() => handlePreviousStep()}
                        >
                          Previous
                        </button>

                        {policyOverview ? (
                          <button
                            type="submit"
                            className="proceedBtn1"
                            onClick={() => handleNextStep()}
                          >
                            Proceed
                          </button>
                        ) : (
                          <button
                            type="submit"
                            className="proceedBtn1"
                            // onClick={() => handleNextStep()}
                          >
                            Proceed
                          </button>
                        )}
                      </div>
                    </div>
                  </form>
                ) : healthIsnurer === "Care" ? (
                  <Care
                    setActiveStep3={setActiveStep3}
                    handleNextStep={handleNextStep}
                    policyOverview={policyOverview}
                    handlePreviousStep={handlePreviousStep}
                  />
                ) : (
                  ""
                ))}

              {/* Next page */}

              {currentStep === 3 && (
                <div className="onwer-personal-details">
                  <div className="">
                    <form onSubmit={handleSubmit3(handleKycDetails)}>
                      <div className="pan-kyc">
                        <p>
                          As per new IRDAI guidelines KYC verification is
                          mandatory for policy issuance. <span> Know more</span>
                        </p>
                        <div className="kyc-details">
                          <h4>KYC Details</h4>
                        </div>
                        <div className="pan-input-kyc row">
                          {showIssuePolicy === false ? (
                            <>
                              <div className="col-md-6">
                                <div className="did-floating-label-content mt-4">
                                  <input
                                    id="pan_number"
                                    type="text"
                                    className="did-floating-input text-uppercase"
                                    name="customerPanNumber"
                                    placeholder={
                                      healthRequestQQ?.CustomerDetails
                                        ?.customerKYCIDNumber
                                    }
                                    disabled
                                  />
                                  <label
                                    htmlFor="idxx4"
                                    className="did-floating-label"
                                  >
                                    ID Number
                                  </label>
                                  {/* <input
                                type="date"
                                // maxlength="10"
                                placeholder={
                                  healthRequestQQ?.CustomerDetails?.customerDOB
                                }
                                disabled
                              /> */}
                                </div>
                              </div>
                            </>
                          ) : manualKycReferenceNumber === true ? (
                            <>
                              <p>
                                Please save the Kyc Reference Number after
                                Manual KYC for successful KYC Verification
                              </p>
                              <div className="col-md-6">
                                <div className="did-floating-label-content mt-4">
                                  <select
                                    name="KYCID"
                                    className="form-select"
                                    placeholder="KYC Id Type"
                                    {...register3("KYCIDType", {
                                      required: "Field is required",
                                    })}
                                    // defaultValue="ManualKycReferenceNumber"
                                  >
                                    <option>KYC Id Type</option>
                                    <option
                                      value="ManualKycReferenceNumber"
                                      selected
                                    >
                                      Manual Kyc Reference Number
                                    </option>
                                  </select>
                                </div>
                              </div>

                              {watch3("KYCIDType") ===
                                "ManualKycReferenceNumber" && (
                                <div className="col-md-6">
                                  <div className="did-floating-label-content mt-4">
                                    <input
                                      // id="pan_number"
                                      type="text"
                                      className="did-floating-input"
                                      name="customeManualKycReferenceNumber"
                                      {...register3("customerKYCIDNumber", {
                                        required: "Field is required",
                                        // pattern: "[A-Z]{5}[0-9]{4}[A-Z]{1}",
                                      })}
                                      value={someStateValue}
                                      onChange={handleChange}
                                      placeholder=""
                                      // maxLength={10}
                                    />
                                    <label
                                      htmlFor="idxx4"
                                      className="did-floating-label"
                                    >
                                      Manual Kyc Reference Number*
                                    </label>
                                  </div>
                                </div>
                              )}
                            </>
                          ) : (
                            <>
                              {healthIsnurer === "Sompo" && (
                                <>
                                  <div className="col-md-6 mb-3">
                                    <select
                                      name="KYCIDType"
                                      className="form-select"
                                      placeholder="KYC Id Type"
                                      {...register3("KYCIDType", {
                                        required: "Field is required",
                                      })}
                                    >
                                      <option>KYC Id Type</option>
                                      <option value="VoterId">Voter Id</option>
                                      <option value="DrivingLicense">
                                        Driving License
                                      </option>
                                      <option value="Passport">Passport</option>
                                      <option value="AadharNumber">
                                        Aadhar Number
                                      </option>
                                      <option value="Pancard">Pan Card</option>
                                      <option value="CkycNmber">
                                        CKYC Number
                                      </option>
                                      <option value="ManualKycReferenceNumber">
                                        Manual Kyc Reference Number
                                      </option>
                                    </select>
                                  </div>
                                  {watch3("KYCIDType") === "Pancard" ? (
                                    <div className="col-md-6 mb-3">
                                      <div className="did-floating-label-content">
                                        <input
                                          id="pan_number"
                                          type="text"
                                          className="did-floating-input"
                                          name="customerPanNumber"
                                          {...register3("customerKYCIDNumber", {
                                            required: "Field is required",
                                            pattern: "[A-Z]{5}[0-9]{4}[A-Z]{1}",
                                          })}
                                          placeholder=""
                                          maxLength={10}
                                        />
                                        <label
                                          htmlFor="idxx4"
                                          className="did-floating-label"
                                        >
                                          PAN Number*
                                        </label>
                                      </div>
                                    </div>
                                  ) : watch3("KYCIDType") === "VoterId" ? (
                                    <div className="col-md-6 mb-3">
                                      <div className="did-floating-label-content">
                                        <input
                                          id="pan_number"
                                          type="text"
                                          className="did-floating-input"
                                          name="customerVoterId"
                                          {...register3("customerKYCIDNumber", {
                                            required: "Field is required",
                                            // pattern: "[A-Z]{5}[0-9]{4}[A-Z]{1}",
                                          })}
                                          placeholder=""
                                          // maxLength={10}
                                        />
                                        <label
                                          htmlFor="idxx4"
                                          className="did-floating-label"
                                        >
                                          Voter Id Number*
                                        </label>
                                      </div>
                                    </div>
                                  ) : watch3("KYCIDType") === "AadharNumber" ? (
                                    <div className="col-md-6 mb-3">
                                      <div className="did-floating-label-content">
                                        <input
                                          id="pan_number"
                                          type="text"
                                          className="did-floating-input"
                                          name="customerVoterId"
                                          {...register3("customerKYCIDNumber", {
                                            required: "Field is required",
                                            // pattern: "[A-Z]{5}[0-9]{4}[A-Z]{1}",
                                          })}
                                          placeholder=""
                                          // maxLength={10}
                                        />
                                        <label
                                          htmlFor="idxx4"
                                          className="did-floating-label"
                                        >
                                          Aadhar Number*
                                        </label>
                                      </div>
                                    </div>
                                  ) : watch3("KYCIDType") ===
                                    "DrivingLicense" ? (
                                    <div className="col-md-6 mb-3">
                                      <div className="did-floating-label-content">
                                        <input
                                          id="pan_number"
                                          type="text"
                                          className="did-floating-input"
                                          name="customeDrivingLicense"
                                          {...register3("customerKYCIDNumber", {
                                            required: "Field is required",
                                            // pattern: "[A-Z]{5}[0-9]{4}[A-Z]{1}",
                                          })}
                                          placeholder=""
                                          // maxLength={10}
                                        />
                                        <label
                                          htmlFor="idxx4"
                                          className="did-floating-label"
                                        >
                                          Driving License Number*
                                        </label>
                                      </div>
                                    </div>
                                  ) : watch3("KYCIDType") === "Passport" ? (
                                    <div className="col-md-6 mb-3">
                                      <div className="did-floating-label-content">
                                        <input
                                          id="pan_number"
                                          type="text"
                                          className="did-floating-input"
                                          name="customePassport"
                                          {...register3("customerKYCIDNumber", {
                                            required: "Field is required",
                                            // pattern: "[A-Z]{5}[0-9]{4}[A-Z]{1}",
                                          })}
                                          placeholder=""
                                          // maxLength={10}
                                        />
                                        <label
                                          htmlFor="idxx4"
                                          className="did-floating-label"
                                        >
                                          Passport Number*
                                        </label>
                                      </div>
                                    </div>
                                  ) : watch3("KYCIDType") === "CkycNmber" ? (
                                    <div className="col-md-6 mb-3">
                                      <div className="did-floating-label-content">
                                        <input
                                          id="pan_number"
                                          type="text"
                                          className="did-floating-input"
                                          name="customeCkycNmber"
                                          {...register3("customerKYCIDNumber", {
                                            required: "Field is required",
                                            // pattern: "[A-Z]{5}[0-9]{4}[A-Z]{1}",
                                          })}
                                          placeholder=""
                                          // maxLength={10}
                                        />
                                        <label
                                          htmlFor="idxx4"
                                          className="did-floating-label"
                                        >
                                          Ckyc Number*
                                        </label>
                                      </div>
                                    </div>
                                  ) : watch3("KYCIDType") ===
                                    "ManualKycReferenceNumber" ? (
                                    <div className="col-md-6 mb-3">
                                      <div className="did-floating-label-content">
                                        <input
                                          id="pan_number"
                                          type="text"
                                          className="did-floating-input"
                                          name="customeManualKycReferenceNumber"
                                          {...register3("customerKYCIDNumber", {
                                            required: "Field is required",
                                            // pattern: "[A-Z]{5}[0-9]{4}[A-Z]{1}",
                                          })}
                                          placeholder=""
                                          // maxLength={10}
                                        />
                                        <label
                                          htmlFor="idxx4"
                                          className="did-floating-label"
                                        >
                                          Manual Kyc Reference Number*
                                        </label>
                                      </div>
                                    </div>
                                  ) : (
                                    <div className="col-md-6 mb-3">
                                      <div className="did-floating-label-content">
                                        <input
                                          id="pan_number"
                                          type="text"
                                          className="did-floating-input"
                                          name=""
                                          placeholder=""
                                          disabled
                                        />
                                        <label
                                          htmlFor="idxx4"
                                          className="did-floating-label"
                                        >
                                          Id Number*
                                        </label>
                                      </div>
                                    </div>
                                  )}
                                </>
                              )}
                              {healthIsnurer === "Care" && (
                                <>
                                  <div className="col-md-6 mb-3">
                                    <select
                                      name="KYCIDType"
                                      className="form-select"
                                      placeholder="KYC Id Type"
                                      {...register3("KYCIDType", {
                                        required: "Field is required",
                                      })}
                                    >
                                      <option>KYC Id Type</option>
                                      <option value="Pancard">Pan Card</option>
                                      {/* <option value="VoterId">Voter Id</option>
                                      <option value="DrivingLicense">
                                        Driving License
                                      </option>
                                      <option value="Passport">Passport</option>
                                      <option value="AadharNumber">
                                        Aadhar Number
                                      </option>
                                      <option value="CkycNmber">
                                        CKYC Number
                                      </option>
                                      <option value="ManualKycReferenceNumber">
                                        Manual Kyc Reference Number
                                      </option> */}
                                    </select>
                                  </div>
                                  {watch3("KYCIDType") === "Pancard" ? (
                                    <div className="col-md-6 mb-3">
                                      <div className="did-floating-label-content">
                                        <input
                                          id="pan_number"
                                          type="text"
                                          className="did-floating-input text-uppercase"
                                          name="customerPanNumber"
                                          {...register3("customerKYCIDNumber", {
                                            required: "Field is required",
                                            pattern: "[A-Z]{5}[0-9]{4}[A-Z]{1}",
                                          })}
                                          placeholder=""
                                          maxLength={10}
                                        />
                                        <label
                                          htmlFor="idxx4"
                                          className="did-floating-label"
                                        >
                                          PAN Number*
                                        </label>
                                      </div>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </>
                              )}
                              <div className="col-md-6 mb-3">
                                <div className="did-floating-label-content">
                                  <input
                                    // type="date"

                                    // placeholder="Date Of Birth"
                                    className="did-floating-input"
                                    name="customerDOB"
                                    value={
                                      healthRequestQQ?.CustomerDetails
                                        ?.customerDOB
                                    }
                                    // max={moment(minDate).format("YYYY-MM-DD")}
                                    // {...register3("customerDOB", {
                                    //   required: "Field is required",
                                    // })}
                                    disabled
                                  />
                                  <label
                                    htmlFor="idxx4"
                                    className="did-floating-label"
                                  >
                                    Date Of Birth
                                  </label>
                                </div>
                              </div>
                              {/* {dobError && (
                                <p
                                  className="text-danger"
                                  style={{ backgroundColor: "transparent" }}
                                >
                                  Date of Birth does not match
                                </p>
                              )} */}
                            </>
                          )}
                        </div>
                        <div className="next-btn-btn mt-3">
                          {showIssuePolicy === false && (
                            <p className="text-success" id="kyc-message">
                              Your KYC Details has been successfully verified
                            </p>
                          )}
                          {kycSpinner === true ? (
                            <button type="submit" className="proceedBtn1">
                              <div
                                class="spinner-border text-light"
                                role="status"
                              >
                                <span class="sr-only"></span>
                              </div>
                            </button>
                          ) : (
                            showIssuePolicy && (
                              <>
                                <button type="submit" className="proceedBtn1">
                                  Verify
                                </button>
                                <button
                                  className="proceedBtn1"
                                  onClick={() => handlePreviousStep()}
                                >
                                  Previous
                                </button>
                              </>
                            )
                          )}
                        </div>
                      </div>
                    </form>
                    {showIssuePolicy === false && (
                      <form onSubmit={handleSubmit4(handleIssuePolicy)}>
                        <div className="kyc-details">
                          <h4 className="mb-5">Nominee Details</h4>
                          <div className="kyc-info row">
                            <div className="col-md-6 mb-3">
                              <div className="did-floating-label-content">
                                <input
                                  type="text"
                                  name="name"
                                  className="did-floating-input"
                                  placeholder=""
                                  {...register4("NomineeName")}
                                />
                                <label
                                  htmlFor="idxx4"
                                  className="did-floating-label"
                                >
                                  Name
                                </label>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="did-floating-label-content">
                                <select
                                  type="text"
                                  className="form-select"
                                  name="Nominee-relationship"
                                  maxlength="50"
                                  id="relationship"
                                  {...register4("NomineeRelation")}
                                >
                                  <option value="">
                                    Relationship to proposer
                                  </option>
                                  <option value="Self">Self</option>
                                  <option value="Spouse">Spouse</option>
                                  <option value="Son">Son</option>
                                  <option value="Daughter">Daughter</option>
                                  <option value="Brother">Brother</option>
                                  <option value="Sister">Sister</option>
                                  <option value="Father">Father</option>
                                  <option value="Mother">Mother</option>
                                  <option value="Son In Law">Son In Law</option>
                                  <option value="Daughter In Law">
                                    Daughter In Law
                                  </option>
                                  <option value="Brother In Law">
                                    Brother In Law
                                  </option>
                                  <option value="Sister In Law">
                                    Sister In Law
                                  </option>
                                  <option value="Father In Law">
                                    Father In Law
                                  </option>
                                  <option value="Mother In Law">
                                    Mother In Law
                                  </option>
                                  <option value="Grand Father">
                                    Grand Father
                                  </option>
                                  <option value="Grand Mother">
                                    Grand Mother
                                  </option>
                                  <option value="Grand Son">Grand Son</option>
                                  <option value="Grand Daughter">
                                    Grand Daughter
                                  </option>
                                  <option value="Nephew">Nephew</option>
                                  <option value="Niece">Niece</option>
                                  <option value="Other">Other</option>
                                  <option value="Legal Heir">Legal Heir</option>
                                </select>
                                <label
                                  htmlFor="idxx4"
                                  className="did-floating-label"
                                >
                                  Nominee-relationship
                                </label>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="did-floating-label-content">
                                <select
                                  name="NomineeGender"
                                  className="form-select"
                                  {...register4("NomineeGender", {
                                    required: "Field is required",
                                  })}
                                  // className="form-select form-control-inner"
                                  id="idxx2"
                                >
                                  <option value="">Gender</option>
                                  <option value="Male">Male</option>
                                  <option value="Female">Female</option>
                                  <option value="Other">Other</option>
                                </select>
                                <label
                                  htmlFor="idxx4"
                                  className="did-floating-label"
                                >
                                  Nominee Gender
                                </label>
                              </div>
                            </div>
                            <div className="col-md-6 mb-3">
                              <div className="did-floating-label-content">
                                <input
                                  type="date"
                                  className="did-floating-input"
                                  placeholder=""
                                  max={moment(minDate).format("YYYY-MM-DD")}
                                  {...register4("NomineeDOB")}
                                />
                                <label
                                  htmlFor="idxx4"
                                  className="did-floating-label"
                                >
                                  Date Of Birth
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="next-btn-btn">
                          <button
                            className="proceedBtn1"
                            onClick={() => handlePreviousStep()}
                          >
                            Previous
                          </button>

                          <button className="proceedBtn1" type="submit">
                            {proceedPayment ? (
                              <div
                                className="spinner-border text-success"
                                role="status"
                              >
                                {/* <span class="sr-only">Loading...</span> */}
                              </div>
                            ) : (
                              "Proceed"
                            )}
                          </button>
                        </div>
                      </form>
                    )}
                  </div>
                </div>
              )}
            </div>

            <div className="plan-summary  col-4">
              <div className="plan-details">
                <div className="summary-heading">
                  <h3>Your Plan Summary</h3>
                </div>
                <div className="summary-details">
                  <div className="summary-img">
                    <img src={selectedPlan?.logo} alt="" />
                  </div>
                  <div className="plan-type">
                    <div className="idv">
                      <h3>Plan Type</h3>
                      <h3>{selectedPlan?.planType}</h3>
                    </div>
                    {/* <div className="idv">
                      <h3>Monthly Premium</h3>
                      <h5>₹{selectedPlan?.PremiumAmount?.premiumPerMonth}</h5>
                    </div> */}
                    <div className="idv">
                      <h3> Premium</h3>
                      <h5>₹{selectedPlan?.PremiumAmount?.premiumPerYear}</h5>
                    </div>
                  </div>
                </div>
              </div>
              {policyOverview === false ? (
                <div>
                  <div className="summary-card">
                    <img src="/assets/images/bussiness.jpg" alt="" />
                    <img
                      className="mt-5"
                      src="/assets/images/health.jpg"
                      alt=""
                    />
                  </div>
                </div>
              ) : (
                <>
                  <div>
                    <div class="accordion" id="accordionExample">
                      <div class="accordion-item">
                        <h2 class="accordion-header" id="headingOne">
                          <button
                            class="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseOne"
                            aria-expanded="true"
                            aria-controls="collapseOne"
                          >
                            Policy Details
                          </button>
                        </h2>
                        <div
                          id="collapseOne"
                          class="accordion-collapse collapse show"
                          aria-labelledby="headingOne"
                          data-bs-parent="#accordionExample"
                        >
                          <div class="accordion-body">
                            <div className="d-flex justify-content-between">
                              <p className="">
                                <strong>Insurer</strong>
                              </p>
                              <p>{selectedPlan?.Company}</p>
                            </div>
                            <div className="d-flex justify-content-between">
                              <p className="">
                                <strong>Plan Type</strong>
                              </p>
                              <p>{selectedPlan?.planType}</p>
                            </div>
                            <div className="d-flex justify-content-between">
                              <p className="">
                                <strong>Plan Tenure</strong>
                              </p>
                              <p>{selectedPlan?.planTenure}</p>
                            </div>
                            <div className="d-flex justify-content-between">
                              <p className="">
                                <strong>Sum Insured Value</strong>
                              </p>
                              <p>{selectedPlan?.sumInsuredValue}</p>
                            </div>
                            <div className="d-flex justify-content-between">
                              <p className="">
                                <strong>Monthly Premium</strong>
                              </p>
                              <p>
                                ₹{selectedPlan?.PremiumAmount?.premiumPerMonth}
                              </p>
                            </div>
                            <div className="d-flex justify-content-between">
                              <p className="">
                                <strong>Annual Premium</strong>
                              </p>
                              <p>
                                ₹{selectedPlan?.PremiumAmount?.premiumPerYear}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="accordion-item">
                        <h2 class="accordion-header" id="headingTwo">
                          <button
                            class="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseTwo"
                            aria-expanded="false"
                            aria-controls="collapseTwo"
                          >
                            Policy Holder Details
                          </button>
                        </h2>
                        <div
                          id="collapseTwo"
                          class="accordion-collapse collapse"
                          aria-labelledby="headingTwo"
                          data-bs-parent="#accordionExample"
                        >
                          <div class="accordion-body">
                            <div className="d-flex justify-content-between">
                              <p className="">
                                <strong>Name</strong>
                              </p>
                              <p>
                                {
                                  healthRequestQQ?.CustomerDetails
                                    ?.customerFirstName
                                }{" "}
                                {
                                  healthRequestQQ?.CustomerDetails
                                    ?.customerMiddleName
                                }{" "}
                                {
                                  healthRequestQQ?.CustomerDetails
                                    ?.customerLastName
                                }
                              </p>
                            </div>
                            <div className="d-flex justify-content-between">
                              <p className="">
                                <strong>Age</strong>
                              </p>
                              <p>
                                {healthRequestQQ?.CustomerDetails?.customerAge}
                              </p>
                            </div>
                            <div className="d-flex justify-content-between">
                              <p className="">
                                <strong>Mobile Number</strong>
                              </p>
                              <p>
                                {
                                  healthRequestQQ?.CustomerDetails
                                    ?.customerMobileNumber
                                }
                              </p>
                            </div>
                            <div className="d-flex justify-content-between">
                              <p className="">
                                <strong>Email</strong>
                              </p>
                              <p>
                                {
                                  healthRequestQQ?.CustomerDetails
                                    ?.customerEmailAddress
                                }
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="accordion-item">
                        <h2 class="accordion-header" id="headingThree">
                          <button
                            class="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseThree"
                            aria-expanded="false"
                            aria-controls="collapseThree"
                          >
                            Nominee Details
                          </button>
                        </h2>
                        <div
                          id="collapseThree"
                          class="accordion-collapse collapse"
                          aria-labelledby="headingThree"
                          data-bs-parent="#accordionExample"
                        >
                          <div class="accordion-body">
                            <div className="d-flex justify-content-between">
                              <p className="">
                                <strong>Nominee Name</strong>
                              </p>
                              <p>
                                {healthRequestQQ?.NomineeDetails?.NomineeName}
                              </p>
                            </div>
                            <div className="d-flex justify-content-between">
                              <p className="">
                                <strong>Nominee Relation</strong>
                              </p>
                              <p>
                                {
                                  healthRequestQQ?.NomineeDetails
                                    ?.NomineeRelation
                                }
                              </p>
                            </div>
                            <div className="d-flex justify-content-between">
                              <p className="">
                                <strong>Nominee Gender</strong>
                              </p>
                              <p>
                                {healthRequestQQ?.NomineeDetails?.NomineeGender}
                              </p>
                            </div>
                            <div className="d-flex justify-content-between">
                              <p className="">
                                <strong>Nominee Date of Birth</strong>
                              </p>
                              <p>
                                {healthRequestQQ?.NomineeDetails?.NomineeDOB}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="accordion-item">
                        <h2 class="accordion-header" id="headingFour">
                          <button
                            class="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseFour"
                            aria-expanded="false"
                            aria-controls="collapseFour"
                          >
                            KYC Details
                          </button>
                        </h2>
                        <div
                          id="collapseFour"
                          class="accordion-collapse collapse"
                          aria-labelledby="headingFour"
                          data-bs-parent="#accordionExample"
                        >
                          <div class="accordion-body">
                            <div className="d-flex justify-content-between">
                              <p className="">
                                <strong>Name</strong>
                              </p>
                              <p>
                                {kycResponse?.name
                                  ? kycResponse?.name
                                  : kycResponse?.firstName +
                                    " " +
                                    kycResponse?.middleName +
                                    " " +
                                    kycResponse?.lastName}
                              </p>
                            </div>
                            <div className="d-flex justify-content-between">
                              <p className="">
                                <strong>Date of Birth</strong>
                              </p>
                              <p>{kycResponse?.dob}</p>
                            </div>
                            {/* <div className="d-flex justify-content-between">
                              <p className="">
                                <strong>Pan Number</strong>
                              </p>
                              <p>{kycResponse?.pan}</p>
                            </div> */}
                            {/* <div className="d-flex justify-content-between">
                              <p className="">
                                <strong>CKYC Number</strong>
                              </p>
                              <p>{kycResponse?.ckycNo}</p>
                            </div> */}
                            {/* <div className="d-flex justify-content-between">
                              <p className="">
                                <strong>CKYC Date</strong>
                              </p>
                              <p>{kycResponse?.ckycDate}</p>
                            </div> */}
                            <div className="d-flex justify-content-between gap-3">
                              <p className="">
                                <strong>Address</strong>
                              </p>
                              <p className="ms-5">
                                {kycResponse?.address
                                  ? kycResponse?.address
                                  : kycResponse?.address1 +
                                    " " +
                                    kycResponse?.address2}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <button
                    className="paymentBtn"
                    onClick={() => handlePayment()}
                  >
                    Proceed to Payment
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal isOpen={modal} size="lg">
        <ModalHeader>Payment Gateway</ModalHeader>
        <ModalBody>
          <Link to={paymentPage}></Link>
        </ModalBody>
      </Modal>
      {/* <Modal isOpen={modalKyc} size="md">
        <ModalHeader>Your KYC is successfully verified</ModalHeader>
        <ModalBody>
          <div>
            <h4 className="text-center my-2">KYC Verification Details</h4>

            <div className="d-flex flex-column">
              <div className="d-flex justify-content-between  mt-2">
                <span className="text-start fw-bold">Name:</span>
                <span className="">
                  {kycResponse?.firstName} {kycResponse?.middleName}{" "}
                  {kycResponse?.lastName}
                </span>
              </div>
              <div className="d-flex justify-content-between  mt-2">
                <span className="text-start fw-bold">Pan No.:</span>
                <span className="">{kycResponse?.pan}</span>
              </div>
              <div className="d-flex justify-content-between  mt-2">
                <span className="text-start fw-bold">Date Of Birth:</span>
                <span className="">{kycResponse?.dob}</span>
              </div>
              <div className="d-flex justify-content-between  mt-2">
                <span className="text-start fw-bold">CKYC No.:</span>
                <span className="">{kycResponse?.ckycNo}</span>
              </div>
              <div className="d-flex justify-content-between  mt-2">
                <span className="text-start fw-bold">CKYC Date:</span>
                <span className="">{kycResponse?.ckycDate}</span>
              </div>
              <div className="d-flex justify-content-between  mt-2">
                <span className="text-start fw-bold">Address:</span>
                <span className="w-50 text-end">
                  {kycResponse?.corresAddress1} {kycResponse?.corresAddress2}
                </span>
              </div>
            </div>
            <div className="d-flex justify-content-center my-2">
              <button
                className="px-4 py-2 rounded kycBtn"
                onClick={() => {
                  setModalKyc(false);
                  setShowIssuePolicy(false);
                }}
              >
                Okay
              </button>
            </div>
          </div>
        </ModalBody>
      </Modal> */}
      <Modal isOpen={modalDisease} size="md">
        <ModalBody>
          <div className="d-flex flex-column justify-content-center align-items-center">
            <img className="my-4" src="/assets/images/cancel (1).png" />
            {kycResponse ? (
              <>
                <div className="d-flex w-100 justify-content-between">
                  <p className="fw-bold">Name(As per KYC): </p>
                  <p>
                    {kycResponse?.name
                      ? kycResponse?.name
                      : kycResponse?.firstName}
                  </p>
                </div>
                <div className="d-flex w-100 justify-content-between">
                  <p className="fw-bold">Name(Entered): </p>
                  <p>{healthRequestQQ?.CustomerDetails?.customerFirstName}</p>
                </div>
                <p className="text-danger fs-5 text-center">
                  You First Name doesn't match with your KYC First Name, So make
                  a Fresh Request with the Same Name as per your KYC Records{" "}
                </p>
              </>
            ) : (
              <p className="text-danger fs-5 text-center">
                You cannot proceed further with the selected disease/habits
              </p>
            )}
            <Link to="/">
              <button className="homeBtn">Go to Home</button>
            </Link>
          </div>
        </ModalBody>
      </Modal>
      <Modal isOpen={modalKYCError} size="md">
        <ModalBody>
          <div className="d-flex flex-column justify-content-center align-items-center">
            <img className="my-4" src="/assets/images/cancel (1).png" />

            <p className="text-danger fs-5 text-center fw-bold">KYC FAILED!</p>
            <p className="text-danger fs-5 text-center">
              Please Change KYC ID Type and try again
            </p>

            <button className="homeBtn" onClick={() => setModalKYCError(false)}>
              Try Again
            </button>
          </div>
        </ModalBody>
      </Modal>
      <Modal isOpen={updatedPremium} centered size="md">
        <ModalHeader>Premium Updated</ModalHeader>
        <ModalBody>
          <div className="d-flex flex-column justify-content-center align-items-center">
            {/* <img className="my-4" src="/assets/images/cancel (1).png" /> */}

            {/* <p className="fs-5 text-center fw-bold">Premium Updated</p> */}
            <p className=" text-center">
              The premium has been revised based on the difference in age
              compared to the provided date of birth.
            </p>
            <div className="w-100">
              <div className="d-flex justify-content-between">
                <p>Previous Premium: </p>
                <p>₹{previousPremium?.premiumPerYear}/year</p>
              </div>
              <div className="d-flex justify-content-between">
                <p>Updated Premium: </p>
                <p>₹{selectedPlan?.PremiumAmount?.premiumPerYear}/year</p>
              </div>
            </div>

            <button
              className="homeBtn"
              onClick={() => {
                setUpdatedPremium(false);
                setCurrentStep(2);
                setActiveStep2(true);
              }}
            >
              Proceed
            </button>
          </div>
        </ModalBody>
      </Modal>
    </Layout>
  );
};

export default HealthProposal;
